import BaseSurveyAssessmentEngine, {
  getSurveyEngineForVersion,
} from './SurveyAssessment';
import NullAssessment from './NullAssessment';
import LiveAssessment from './LiveAssessment';

// Factory to get the appropriate engine
// TODO: use semantic versioning to determine which engine to use
export function getEngine(assessment) {
  if (!assessment || !assessment.id) {
    return NullAssessment;
  }

  switch (assessment.type) {
    case 'live':
    case 'live-assessment':
      return LiveAssessment;

    default:
      return BaseSurveyAssessmentEngine;
  }
}

// TODO: use semantic versioning to determine which engine to use
export function getEngineForList(assessments) {
  const type = assessments.length > 0 ? assessments[0].type : 'null';
  switch (type) {
    case 'live':
    case 'live-assessment':
      return LiveAssessment;

    case undefined:
    case null:
    case 'null':
      return NullAssessment;

    default:
      return BaseSurveyAssessmentEngine;
  }
}

// TODO: use semantic versioning to determine which engine to use
export function getEngineStrategies(assessment) {
  switch (assessment.type) {
    case 'live':
    case 'live-assessment':
      return LiveAssessment.strategies;

    case undefined:
    case null:
      return NullAssessment.strategies;

    default:
      return BaseSurveyAssessmentEngine.strategies;
  }
}

// TODO: use semantic versioning to determine which engine to use
export function getEngineForTemplate(template) {
  if (!template) {
    return NullAssessment;
  }

  switch (template.type) {
    case 'live':
    case 'live-assessment':
      return LiveAssessment;

    case 'survey':
      return getSurveyEngineForVersion(template?.configuration?.version);

    default:
      return NullAssessment;
  }
}
