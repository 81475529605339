import React from 'react';
import AssessmentDocTemplateSummaryCard from './AssessmentDocTemplateSummaryCard';

function ActiveTemplateSummaryList({ templateSummaries, onArchiveTemplate }) {
  return templateSummaries.map((summary) => (
    <AssessmentDocTemplateSummaryCard
      key={summary.id}
      summary={summary}
      onArchive={onArchiveTemplate}
    />
  ));
}

export default ActiveTemplateSummaryList;
