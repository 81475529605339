import { useMemo } from 'react';
import styles from './CollaboratorList.module.scss';
import Collaborator from 'js/components/CollaboratorList/Collaborator';

const styleDefaults = {
  padding: '3px 5px',
  borderRadius: '5px',
};

export default function CollaboratorFocusContainer({
  collaborator,
  children,
  style,
}) {
  const displayClassName = useMemo(() => {
    const classes = [styles.focusContainer, 'focus-container'];
    if (collaborator) {
      classes.push(styles.otherEditing);
      classes.push(`palette-cat-${collaborator.colorIndex + 1}-border`);
    }
    return classes.join(' ');
  }, [collaborator]);

  const collaboratorStyle = useMemo(
    () => ({ ...styleDefaults, ...style }),
    [style]
  );

  return (
    <div className={displayClassName} style={collaboratorStyle}>
      {children}
      {collaborator && (
        <div className={styles.collaborator}>
          <Collaborator collaborator={collaborator} small />
        </div>
      )}
    </div>
  );
}
