import React from 'react';
import { Table } from 'reactstrap';
import ArchivedTemplate from './ArchivedTemplate';

import styles from './AssessmentDashboard.module.scss';

const ArchivedTemplateSummaryList = ({
  summaries,
  canModifyTemplates,
  canRestoreTemplates,
  onRestoreTemplate = () => {},
}) => {
  return (
    <div>
      <Table responsive className={styles['archived-template-list']}>
        <tbody>
          {summaries.map((summary) => (
            <ArchivedTemplate
              key={summary.id}
              summary={summary}
              canModifyTemplates={canModifyTemplates}
              canRestoreTemplates={canRestoreTemplates}
              onRestoreTemplate={onRestoreTemplate}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ArchivedTemplateSummaryList;
