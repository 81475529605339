export const validAnswerSetTypes = {
  likert: ['likert'],
  'unscored-likert': ['likert'],
  'multi-select': ['value-list'],
  'single-select': ['value-list'],
};

export function getDefaultAnswerSetTypeForQuestionType(questionType) {
  return getValidAnswerSetTypesForQuestionType(questionType)[0];
}

export function getValidAnswerSetTypesForQuestionType(questionType) {
  return validAnswerSetTypes[questionType] || [];
}

export function isValidAnswerSetTypeForQuestion(questionType, answerSetType) {
  return getValidAnswerSetTypesForQuestionType(questionType).includes(
    answerSetType
  );
}
