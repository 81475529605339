import React, { useState, useMemo } from 'react';
import { pickBy, filter } from 'lodash';

import { Nav, NavItem, NavLink } from 'reactstrap';

import { EditorTool } from '../EditorTool';
import { AnswerSet } from './AnswerSet';

import standardAnswerSets from './standard-answer-sets';
import { useDoc } from 'js/ydoc';
import {
  cloneAnswerSet,
  createAnswerSetChoice,
  deleteAnswerSetChoice,
  updateAnswerSet,
  deleteAnswerSet,
  updateAnswerSetChoice,
} from '../../Binding/answer-set-actions';
import useAnswerSetStats from '../../useAnswerSetStats';

import styles from './AnswerSet.module.scss';

const collator = new Intl.Collator(undefined, { sensitivity: 'base' });
const sorter = (a, b) => collator.compare(a.name, b.name);

function standardFilter(standardSets, assessmentSets) {
  const setMap = {
    ...Object.fromEntries(
      standardSets.filter((set) => set.isPublished).map((set) => [set.id, set])
    ),
    ...pickBy(assessmentSets, (set) => set.isStandard),
  };
  return Object.values(setMap).sort(sorter);
}

function customFilter(_standardSets, assessmentSets) {
  return filter(
    assessmentSets,
    (set) => !set.isStandard && set.isPublished
  ).sort(sorter);
}

function allFilter(standardSets, assessmentSets) {
  return Object.values({
    ...Object.fromEntries(
      standardSets.filter((set) => set.isPublished).map((set) => [set.id, set])
    ),
    ...pickBy(assessmentSets, (set) => set.isPublished),
  }).sort(sorter);
}

const Filters = {
  STANDARD: standardFilter,
  CUSTOM: customFilter,
  ALL: allFilter,
};

export function AnswerSetTool({ assessment }) {
  const doc = useDoc();

  const handleDuplicate = (answerSet) => {
    cloneAnswerSet(doc, answerSet);
  };

  const [filterName, setFilterName] = useState('ALL');
  const filter = Filters[filterName];
  const assessmentAnswerSets = assessment?.answerSets;
  const answerSets = useMemo(
    () => filter(standardAnswerSets, assessmentAnswerSets ?? {}),
    [filter, assessmentAnswerSets]
  );

  const answerSetStats = useAnswerSetStats(assessment);

  const handleUpdate = (answerSet, updates) => {
    updateAnswerSet(doc, answerSet.id, updates);
  };
  const handleDelete = (answerSetId) => {
    deleteAnswerSet(doc, answerSetId);
  };

  const handleChoiceUpdate = (answerSet, choice, updates) => {
    updateAnswerSetChoice(doc, answerSet.id, choice.id, updates);
  };

  const handleChoiceCreate = (answerSetId, props) => {
    createAnswerSetChoice(doc, answerSetId, props);
  };

  const handleChoiceDelete = (answerSetId, choiceId) => {
    deleteAnswerSetChoice(doc, answerSetId, choiceId);
  };

  return (
    <EditorTool title="Answer Sets">
      <Nav className="tab-nav nav-underline">
        <NavItem active={filter === Filters.ALL}>
          <NavLink role="button" onClick={() => setFilterName('ALL')}>
            All
          </NavLink>
        </NavItem>
        <NavItem active={filter === Filters.STANDARD}>
          <NavLink role="button" onClick={() => setFilterName('STANDARD')}>
            Standard
          </NavLink>
        </NavItem>
        <NavItem active={filter === Filters.CUSTOM}>
          <NavLink role="button" onClick={() => setFilterName('CUSTOM')}>
            Custom
          </NavLink>
        </NavItem>
      </Nav>
      <div className={styles['answer-sets']}>
        {answerSets.map((answerSet) => (
          <AnswerSet
            key={answerSet.id}
            usedByCount={answerSetStats[answerSet.id]?.usedBy.length ?? 0}
            answerSet={answerSet}
            onDuplicate={handleDuplicate}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            onChoiceCreate={handleChoiceCreate}
            onChoiceUpdate={handleChoiceUpdate}
            onChoiceDelete={handleChoiceDelete}
          />
        ))}
      </div>
    </EditorTool>
  );
}

export default AnswerSetTool;
