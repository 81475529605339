import { isNil } from 'lodash';
import { objectHasChanged, initializeActionTarget } from './utils';
import { getLookups } from './utils/lookups';

export class SingletonEntityReducer {
  constructor(schemaItem, path) {
    this.schemaItem = schemaItem;
    this.key = schemaItem.key;
    this.path = path;

    this.lookups = getLookups(schemaItem);
  }

  reduce(state, entities, action) {
    if (
      isNil(state) &&
      (action.type === initializeActionTarget || isNil(action.patch))
    ) {
      return state;
    }

    let newState = { ...state };
    switch (action.type) {
      case 'patch':
      default:
        newState = this.patch(newState, entities, action);
        break;
    }

    return objectHasChanged(state, newState) ? newState : state;
  }

  patch(state, entities, action) {
    let newState = { ...state };
    const patch = action.patch;

    for (const [key, lookup] of this.lookups) {
      newState[key] = lookup(entities, state, key);
    }

    const invalidPatchKeys = Object.keys(patch ?? {}).filter((key) =>
      this.lookups.has(key)
    );

    if (invalidPatchKeys > 0) {
      console.warn(
        `Attempting to patch a schema item with data for a child entity. The following patch keys will be disregarded: [${invalidPatchKeys.join(
          ', '
        )}]`
      );

      invalidPatchKeys.forEach((key) => {
        delete patch[key];
      });
    }

    if (action.target === this.schemaItem.key) {
      newState = { ...newState, ...patch };
    }

    return newState;
  }
}
