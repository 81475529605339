import React from 'react';

import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';

import styles from '../AnswerSet.module.scss';
import { Icon } from 'js/components';

export function LikertNaChoice({ text, isStandard }) {
  return (
    <tr className={`${styles['answer']} ${styles['score-na']}`}>
      {!isStandard && <td className={styles['drag-column']}></td>}
      <td className={styles['score-cell']}>
        <span className={styles['score-bar']}></span>
        <span className={styles['input-padding-score']}>
          <Icon icon={faEmptySet} />
        </span>
      </td>
      <td>
        <span className={styles['input-padding']}>{text ?? 'N/A'}</span>
      </td>
      {!isStandard && <td className={styles['answer-actions']}></td>}
    </tr>
  );
}

export default LikertNaChoice;
