import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, find } from 'lodash';

import { PanelHeader } from 'js/components';
import QuestionSet from './QuestionSet';
import ProgressSlider from './ProgressSlider';
import RunNavigation from './RunNavigation';

const AssessmentRun = ({
  participant,
  assessment,
  id,
  onRespond = noop,
  onUnrespond = noop,
  match,
}) => {
  const handleResponse = (_questionSet, question, answer) => {
    onRespond(id, question, answer);
  };

  const handleUnresponse = useCallback(
    (_questionSet, question) => onUnrespond(id, question),
    [id, onUnrespond]
  );

  const participantId = participant.id;

  const {
    params: { questionSetId },
  } = match;

  let questionSetIndex = 0;
  const questionSet =
    find(assessment.question_sets, (qs, qsi) => {
      const isMatch = '' + qs.id === '' + questionSetId;
      if (isMatch) {
        questionSetIndex = qsi;
      }
      return isMatch;
    }) || assessment.question_sets[0];

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <div className="assessment survey-assessment-run">
          {assessment.question_sets.length > 1 ? (
            <ProgressSlider
              assessment={assessment}
              participantId={participantId}
              questionSetIndex={questionSetIndex}
            />
          ) : null}
          <QuestionSet
            questionSet={questionSet}
            participantId={participantId}
            onRespond={handleResponse}
            onUnrespond={handleUnresponse}
          />
          <RunNavigation
            assessment={assessment}
            questionSetIndex={questionSetIndex}
          />
        </div>
      </div>
    </div>
  );
};

AssessmentRun.propTypes = {
  assessment: PropTypes.object,
  onRespond: PropTypes.func,
  id: PropTypes.any.isRequired,
};

export default AssessmentRun;
