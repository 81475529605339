import { SchemaItem } from './SchemaItem';

export class SchemaSingletonEntity extends SchemaItem {
  constructor(key, definition = {}, options = {}) {
    super();

    this.key = key;
    this.definition = definition;
    this.idAttribute = options.idAttribute ?? 'id';
  }

  getPositionKey(_state) {
    return this.key;
  }

  getId() {
    return this.key;
  }
}
