import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useHasValue } from '../useHasValue';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';

import { Icon } from 'js/components';
import EditorInput from '../EditorInput/EditorInput';
import { UncontrolledTooltip } from 'reactstrap';

// Drag and drop
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import DropIndicator from './DropIndicator';

import { useCollaboratorForField } from 'js/components/Assessment/Editor/collaborator-hooks';

import QuestionSettings from './QuestionSettings';
import SingleSelectQuestionDetail from './SingleSelectQuestionDetail';
import MultiSelectQuestionDetail from './MultiSelectQuestionDetail';
import QuestionActions from './QuestionActions';
import QuestionType from './QuestionType';
import LikertQuestionDetail from './LikertQuestionDetail';
import LongTextQuestionDetail from './LongTextQuestionDetail';
import ErrorMessage from '../EditorErrors/ErrorMessage';

import styles from '../Editor.module.scss';

const QUESTION_SAMPLES = [
  'If your code could talk, what would it say about you behind your back?',
  'How many rubber ducks do you own?',
  'What is the meaning of life?',
  "What's the weirdest thing you've ever used as a bookmark?",
  "What's the longest you've ever pretended to know what someone was talking about before admitting you were lost?",
  'In the event of a zombie apocalypse, which item from your current location would be your weapon of choice?',
  "What's the most ridiculous thing you've ever said with complete confidence while half-asleep?",
  'If your leftovers in the office fridge could talk, what would they say about being forgotten?',
  "What's your go-to fake name when ordering coffee or making reservations?",
  "What's the most creative excuse you've used to avoid a social obligation that was actually true but sounded fake?",
];

const getRandomQuestionSample = () => {
  return QUESTION_SAMPLES[Math.floor(Math.random() * QUESTION_SAMPLES.length)];
};

const QuestionDetailTypes = {
  likert: LikertQuestionDetail,
  'unscored-likert': LikertQuestionDetail,
  'long-text': LongTextQuestionDetail,
  'single-select': SingleSelectQuestionDetail,
  'multi-select': MultiSelectQuestionDetail,
};

export function Question({
  question,
  onFieldChange,
  onFieldFocus,
  onDelete,
  index,
  onCopy,
  keyError,
  onAnswerSetChange = () => {},
  onAnswerSetFieldChange = () => {},
  onAnswerSetChoiceUpdate = () => {},
  onAnswerSetChoiceAdd = () => {},
  onAnswerSetChoiceDelete = () => {},
  onCreateCustomAnswerSet = () => {},
}) {
  const ref = useRef(null);
  const dragHandleRef = useRef(null);
  const previewRef = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [closestEdge, setClosestEdge] = useState(null);
  const [showSettings, setShowSettings] = useState(false);

  const questionPlaceholderText = useMemo(() => {
    return `Question text: e.g., ${getRandomQuestionSample()}`;
  }, []);

  const collaboratorForText = useCollaboratorForField(
    'question',
    question.guid,
    'text'
  );
  const collaboratorForKey = useCollaboratorForField(
    'question',
    question.guid,
    'key'
  );

  const handleFieldChange = useCallback(
    (e) => {
      onFieldChange(question.guid, e.target.name, e.target.value);
    },
    [question.guid, onFieldChange]
  );

  const handleFieldFocus = useCallback(
    (e) => {
      onFieldFocus('question', question.guid, e.target.name, true);
    },
    [question.guid, onFieldFocus]
  );

  const handleFieldBlur = useCallback(
    (e) => {
      onFieldFocus('question', question.guid, e.target.name, false);
    },
    [question.guid, onFieldFocus]
  );

  const handleDelete = useCallback(() => {
    onDelete(question);
  }, [question, onDelete]);

  const Detail = useMemo(() => {
    return QuestionDetailTypes[question.type];
  }, [question.type]);

  useEffect(() => {
    const el = ref.current;
    const dragHandle = dragHandleRef.current;

    if (!el) throw new Error('No element found');
    if (!dragHandle) throw new Error('No element found');

    return draggable({
      element: el,
      dragHandle,
      getInitialData: () => ({ question, index }),
      onDragStart: () => {
        setDragging(true);
      },
      onDrop: () => {
        setDragging(false);
      },
      onGenerateDragPreview: ({ nativeSetDragImage }) => {
        nativeSetDragImage(previewRef.current, 0, 0);
      },
    });
  }, [question, index]);

  useEffect(() => {
    const el = ref.current;
    if (!el) throw new Error('No element found');

    return dropTargetForElements({
      element: el,
      canDrop: ({ source }) =>
        source.data.question && source.data.question.guid !== question.guid,
      getData: (args) => {
        return attachClosestEdge(
          { question, index },
          {
            input: args.input,
            element: args.element,
            allowedEdges: ['top', 'bottom'],
          }
        );
      },
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => {
        setClosestEdge(null);
        setIsDraggedOver(false);
      },
      onDrag({ self, source }) {
        const isSource = source.element === el;
        if (isSource || source.data.questionSet) {
          setClosestEdge(null);
          return;
        }

        const closestEdge = extractClosestEdge(self.data);

        const sourceIndex = source.data.index;

        const isItemBeforeSource = index === sourceIndex - 1;
        const isItemAfterSource = index === sourceIndex + 1;

        const isDropIndicatorHidden =
          (isItemBeforeSource && closestEdge === 'bottom') ||
          (isItemAfterSource && closestEdge === 'top');

        if (isDropIndicatorHidden) {
          setClosestEdge(null);
          return;
        }

        setClosestEdge(closestEdge);
      },
      onDrop: () => {
        setIsDraggedOver(false);
        setClosestEdge(null);
      },
      getIsSticky: (args) => {
        const elementUnderCursor = document.elementFromPoint(
          args.input.pageX,
          args.input.pageY
        );
        return (
          elementUnderCursor &&
          elementUnderCursor.closest('.question-set-droppable-area')
        );
      },
    });
  }, [question, index]);

  const handleAnswerSetChange = (answerSet) => {
    return onAnswerSetChange(question.guid, answerSet);
  };

  const handleAnswerSetFieldChange = (...args) =>
    onAnswerSetFieldChange(...args, question.guid);
  const handleAnswerSetChoiceUpdate = (...args) =>
    onAnswerSetChoiceUpdate(...args, question.guid);
  const handleAnswerSetChoiceAdd = (...args) =>
    onAnswerSetChoiceAdd(...args, question.guid);
  const handleAnswerSetChoiceDelete = (...args) =>
    onAnswerSetChoiceDelete(...args, question.guid);

  const answerSetEvents = {
    onAnswerSetChange: handleAnswerSetChange,
    onFieldChange: handleAnswerSetFieldChange,
    onChoiceUpdate: handleAnswerSetChoiceUpdate,
    onChoiceAdd: handleAnswerSetChoiceAdd,
    onChoiceDelete: handleAnswerSetChoiceDelete,
  };

  const hasKey = useHasValue(question.key);
  const hasText = useHasValue(question.text);

  return (
    <div
      className={classNames(styles.question, {
        [styles.dragging]: dragging,
        [`draggedOver-${closestEdge}`]: isDraggedOver,
        'hide-settings': true && !showSettings,
      })}
      ref={ref}
    >
      {isDraggedOver && closestEdge === 'top' && <DropIndicator top />}
      <div className={styles.questionHeader}>
        <button className="drag-handle" ref={dragHandleRef}>
          <Icon icon={faGripVertical} className="icon" />
        </button>
        <div className={styles.key}>
          <EditorInput
            name="key"
            value={question.key}
            onChange={handleFieldChange}
            onFocus={handleFieldFocus}
            onBlur={handleFieldBlur}
            collaborator={collaboratorForKey}
            placeholder="question-key"
          />
        </div>
        {!hasKey && (
          <div className={styles.keyError} id={`q-${question.guid}-key`}>
            <Icon icon={faExclamationTriangle} className="icon" />
            <UncontrolledTooltip
              color="danger"
              placement="top"
              target={`#q-${question.guid}-key`}
              className={styles.tooltip}
              trigger="click hover"
              autohide={false}
            >
              <ErrorMessage>A unique key is required</ErrorMessage>
            </UncontrolledTooltip>
          </div>
        )}
      </div>
      <div className={styles.body} ref={previewRef}>
        <EditorInput
          multiLine
          placeholder={questionPlaceholderText}
          name="text"
          value={question.text}
          onChange={handleFieldChange}
          onFocus={handleFieldFocus}
          onBlur={handleFieldBlur}
          className="form-group"
          error={
            hasText ? null : (
              <ErrorMessage>Question text is required</ErrorMessage>
            )
          }
          collaborator={collaboratorForText}
        />
      </div>
      <div className={styles.questionDetail}>
        {Detail !== undefined ? (
          <Detail
            question={question}
            showSettings={showSettings}
            {...answerSetEvents}
          />
        ) : (
          <div style={{ fontSize: '.8em' }}>
            Unknown question type: {question.type}
          </div>
        )}
      </div>
      <div className={styles['settings-wrapper']}>
        <div className={styles['settings-drawer']}>
          <QuestionSettings
            question={question}
            onAnswerSetChange={handleAnswerSetChange}
            onAnswerSetFieldChange={onAnswerSetFieldChange}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.type}>
          <QuestionType
            type={question.type}
            onChange={(t) => onFieldChange(question.guid, 'type', t)}
          />
        </div>
        <div className={styles.actions}>
          <QuestionActions
            onDelete={handleDelete}
            onCopy={() => onCopy(question)}
            onSettings={() => setShowSettings(!showSettings)}
            settingsActive={showSettings}
          />
        </div>
      </div>
      {isDraggedOver && closestEdge === 'bottom' && <DropIndicator bottom />}
    </div>
  );
}

export default Question;
