import React from 'react';

import styles from '../AnswerSet.module.scss';

export function LikertChoiceStandard({ choice, color }) {
  return (
    <tr key={choice.id} className={styles['choice']}>
      <td className={styles['score-cell']}>
        <span
          className={styles['score-bar']}
          style={{ backgroundColor: color(+choice.value) }}
        ></span>
        <span className={styles['input-padding-score']}>{choice.value}</span>
      </td>
      <td>
        <span className={styles['input-padding']}>{choice.text}</span>
      </td>
    </tr>
  );
}

export default LikertChoiceStandard;
