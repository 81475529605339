import React from 'react';

import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HeroBadge, Icon, IconStack } from 'js/components';

const MissingResponsesMessage = () => (
  <div className="missing-responses">
    <Card>
      <CardBody>
        <HeroBadge
          renderIcon={() => (
            <IconStack className="incomplete-icon">
              <Icon icon={['fas', 'circle']} />
              <Icon
                icon={['fal', 'clipboard-list-check']}
                inverse
                transform="shrink-6"
              />
            </IconStack>
          )}
          title="You missed a spot&hellip;"
        >
          <p>
            Hold on there &mdash; you seem to have skipped a few questions.
            Maybe that's intentional, maybe it's not. Here's your chance to
            remedy the situation.
          </p>
          <div>
            <Link to="." className="btn btn-primary btn-hero">
              <Icon className="btn-icon-left" icon={['far', 'elephant']} />
              Wait, I forgot something...
            </Link>
            <Link
              to="complete"
              className="btn btn-secondary btn-hero"
              data-test="finish-partial-link"
            >
              <Icon className="btn-icon-left" icon={['fas', 'badger-honey']} />I
              meant it, I'm done
            </Link>
          </div>
        </HeroBadge>
      </CardBody>
    </Card>
  </div>
);

export default MissingResponsesMessage;
