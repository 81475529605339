import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import {
  faList,
  faListCheck,
  faListRadio,
  faMessageLines,
} from '@fortawesome/pro-regular-svg-icons';
import { faList as falList } from '@fortawesome/pro-light-svg-icons';
import { Icon } from 'js/components';

const typeDetails = {
  likert: {
    name: 'Scored Likert',
    icon: faList,
  },
  'unscored-likert': {
    name: 'Informational Likert',
    icon: falList,
  },
  'multi-select': {
    name: 'Multi-Select',
    icon: faListCheck,
  },
  'single-select': {
    name: 'Single-Select',
    icon: faListRadio,
  },
  'long-text': {
    name: 'Comment',
    icon: faMessageLines,
  },
};

const types = [
  'long-text',
  'single-select',
  'multi-select',
  'likert',
  'unscored-likert',
].map((id) => [id, typeDetails[id]]);

export function QuestionType({ type, onChange = () => {} }) {
  const [isOpen, setIsOpen] = useState(false);
  const td = typeDetails[type] ?? typeDetails['likert'];
  const { name: selectedName, icon: selectedIcon } = td;

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className="action-selector"
    >
      <DropdownToggle caret>
        <div>
          <Icon icon={selectedIcon} className="btn-icon-left" /> {selectedName}
        </div>
      </DropdownToggle>
      <DropdownMenu>
        {types.map(([key, { name, icon }]) => (
          <DropdownItem key={key} onClick={() => onChange(key)}>
            <Icon icon={icon} className="btn-icon-left" /> {name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default QuestionType;
