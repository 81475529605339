import React, { useState } from 'react';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Icon } from 'js/components';
import { faFaceThinking } from '@fortawesome/pro-regular-svg-icons';
import Checkbox from 'js/components/Forms/Checkbox';
// import AsyncButton from '../Forms/AsyncButton';

export const ModalTypes = {
  STANDARD_SINGLE_QUESTION: 'STANDARD_SINGLE_QUESTION',
  STANDARD_MULTI_QUESTION: 'STANDARD_MULTI_QUESTION',
  CUSTOM_MULTI_QUESTION: 'CUSTOM_MULTI_QUESTION',
};

// export const AutoApplyTo = {
//   ONE: 'ONE',
//   ALL: 'ALL',
// };

// // TODO: this is wrong
// function getAutoApplyParams(modalType, dontBugMeChecked, applyToAll) {
//   if (!dontBugMeChecked) {
//     return [false, null];
//   } else if (modalType === ModalTypes.STANDARD_SINGLE_QUESTION) {
//     return [true, null];
//   } else if (modalType === ModalTypes.STANDARD_MULTI_QUESTION) {
//     return [true, applyToAll ? AutoApplyTo.ALL : AutoApplyTo.ONE];
//   } else if (modalType === ModalTypes.CUSTOM_MULTI_QUESTION) {
//     return [false, applyToAll ? AutoApplyTo.ALL : AutoApplyTo.ONE];
//   }

//   return [false, false];
// }

export function AnswerSetModal({
  isOpen,
  type = ModalTypes.STANDARD_SINGLE_QUESTION,
  onConfirm = (_applyToAll, _dontBugMe) => {},
  onCancel = () => {},
}) {
  const [dontBugMe, setDontBugMe] = useState(false);
  // const [dontBugMe, setDontBugMe] = useState(false);

  const handleDontBugMeChange = (e) => {
    setDontBugMe(e.target.checked);
    console.log(e.target.checked);
  };

  const handleConfirm = (applyToAll) => {
    onConfirm(applyToAll, dontBugMe);
  };

  const handleConfirmOne = () => handleConfirm(false);
  const handleConfirmAll = () => handleConfirm(true);

  if (type === ModalTypes.STANDARD_SINGLE_QUESTION) {
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <div className="primary">
            <Icon fixedWidth icon={faFaceThinking} className="mr-2" />
            Hold up a sec&hellip;
          </div>
        </ModalHeader>
        <ModalBody>
          <p>
            This is a standard answer set so we'll make a copy that you can
            change as much as you want.
          </p>
          <FormGroup>
            <Checkbox onChange={handleDontBugMeChange}>
              Don't bug me anymore (Just make the copies)
            </Checkbox>
          </FormGroup>
          <hr />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            Cancel my changes
          </Button>
          <Button color="primary" onClick={handleConfirmOne}>
            OK!
          </Button>
        </ModalFooter>
      </Modal>
    );
  } else if (type === ModalTypes.STANDARD_MULTI_QUESTION) {
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <div className="primary">
            <Icon fixedWidth icon={faFaceThinking} className="mr-2" />
            Hold up a sec&hellip;
          </div>
        </ModalHeader>
        <ModalBody>
          <p>
            This is a standard answer set so we'll make a copy that you can
            change as much as you want.
          </p>
          <p>
            7 questions use these answers. Do you want to change all of them or
            just this one?
          </p>
          <FormGroup>
            <Checkbox onChange={handleDontBugMeChange}>
              Don't bug me anymore (Use this choice every time)
            </Checkbox>
          </FormGroup>
          <hr />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            Cancel my changes
          </Button>
          <Button color="primary" onClick={handleConfirmOne}>
            Just this one
          </Button>
          <Button color="primary" onClick={handleConfirmAll}>
            Change them all!
          </Button>
        </ModalFooter>
      </Modal>
    );
  } else if (type === ModalTypes.CUSTOM_MULTI_QUESTION) {
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <div className="primary">
            <Icon fixedWidth icon={faFaceThinking} className="mr-2" />
            Hold up a sec&hellip;
          </div>
        </ModalHeader>
        <ModalBody>
          <p>
            7 questions use these answers. Do you want to change all of them or
            just this one?
          </p>
          <FormGroup>
            <Checkbox onChange={handleDontBugMeChange}>
              Don't bug me anymore (Use this choice every time)
            </Checkbox>
          </FormGroup>
          <hr />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            Cancel my changes
          </Button>
          <Button color="primary" onClick={handleConfirmOne}>
            Just this one
          </Button>
          <Button color="primary" onClick={handleConfirmAll}>
            Change them all!
          </Button>
          {/* <AsyncButton
          color="primary"
          onClick={onConfirm}
          data-test="confirm-btn"
        >
          {confirmButtonLabel ?? 'Submit'}
        </AsyncButton> */}
        </ModalFooter>
      </Modal>
    );
  }

  return null;
}

export default AnswerSetModal;
