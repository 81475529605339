import React, { useState } from 'react';
import { FIELD_DELIMITER } from 'js/utils/string';
import CheckboxAnswer from './CheckboxAnswer';
import { sortBy } from 'lodash';

function deserializeResponseSet(responseValue) {
  return new Set(
    responseValue == null ? [] : responseValue.split(FIELD_DELIMITER)
  );
}

function serializeResponseSet(selectedAnswerSet) {
  return selectedAnswerSet.size === 0
    ? null
    : Array.from(selectedAnswerSet).join(FIELD_DELIMITER);
}

export const MultiChoiceQuestion = ({
  question: { id, text, answers },
  response,
  onRespond,
}) => {
  // Use a mutable structure to the chosen answers, but preserve it in memory across rerenders
  const selectedAnswerSet = useState(
    deserializeResponseSet(response?.value)
  )[0];
  const [answerIncrement, setAnswerIncrement] = useState(0); // used to force a re-render

  // only support a single response right now
  const handleAnswerSelect = (a, selected) => {
    if (!selected) {
      selectedAnswerSet.delete(a.value);
    } else {
      selectedAnswerSet.add(a.value);
    }

    setAnswerIncrement(answerIncrement + 1);

    onRespond(id, null, serializeResponseSet(selectedAnswerSet));
  };

  return (
    <div className="question">
      <div className="question-text">{text}</div>
      <div className="question-answers">
        <ul className="row list-unstyled">
          {sortBy(answers, ['ordinal', 'id']).map((a) => (
            <li key={a.id} className="col-12">
              <CheckboxAnswer
                namespace={id}
                text={a.text}
                selected={selectedAnswerSet.has(a.value)}
                onSelect={(selected) => handleAnswerSelect(a, selected)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default MultiChoiceQuestion;
