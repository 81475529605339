import { connect } from 'js/middleware/channels/socket';
import { YjsChannelProvider } from 'js/utils/yjs-provider/y-phoenix-channel';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';

export const DocumentContext = React.createContext({
  doc: null,
  providers: null,
});

export const DocumentProvider = ({
  children,
  doc = new Y.Doc(),
  channelProviderTopic,
  channelProviderToken,
}) => {
  const ydoc = React.useRef(doc);
  const [channelProvider, setChannelProvider] = useState(null);
  const [indexedDbProvider, setIndexedDbProvider] = useState(null);
  const me = useSelector((state) => state.identity.me);

  React.useEffect(() => {
    let cProvider = null;
    if (channelProviderTopic && channelProviderToken) {
      const socket = connect(channelProviderToken);
      cProvider = new YjsChannelProvider(
        socket,
        channelProviderTopic,
        ydoc.current
      );
      cProvider.connect();
      cProvider.awareness.setLocalState({
        username: me.username,
        display_name: me.display_name,
      });

      setChannelProvider(cProvider);
    }

    return () => {
      if (cProvider) {
        cProvider.awareness.setLocalState(null);
        cProvider.destroy();
      }
    };
  }, [me, channelProviderTopic, channelProviderToken]);

  React.useEffect(() => {
    let idbProvider = null;
    if (channelProviderTopic) {
      idbProvider = new IndexeddbPersistence(
        channelProviderTopic,
        ydoc.current
      );
      setIndexedDbProvider(idbProvider);
    }

    return () => {
      if (idbProvider) {
        idbProvider.destroy();
      }
    };
  }, [channelProviderTopic]);

  return (
    <DocumentContext.Provider
      value={{
        doc: ydoc.current,
        providers: {
          channelProvider,
          indexedDbProvider,
        },
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
