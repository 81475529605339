import React, { useMemo } from 'react';
import ListChoiceEditor from './ListChoiceEditor';
import { standardColor } from 'js/utils/color';
import ValueChoice from './ValueChoice';

export function SingleSelectChoiceEditor({
  onAddChoice,
  choiceTagProps = null,
  ...props
}) {
  const handleAddChoice = (choiceLength) => {
    const nextNumber = choiceLength + 1;
    const text = `Choice ${nextNumber}`;
    const newChoice = {
      value: text,
      text,
    };
    onAddChoice(newChoice);
  };

  const memoizedChoiceTagProps = useMemo(() => {
    return {
      type: 'radio',
      ...choiceTagProps,
    };
  }, [choiceTagProps]);

  return (
    <ListChoiceEditor
      color={standardColor}
      {...props}
      choiceTag={ValueChoice}
      choiceTagProps={memoizedChoiceTagProps}
      onAddChoice={handleAddChoice}
    />
  );
}

export default SingleSelectChoiceEditor;
