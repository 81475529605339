import {
  SchemaSingletonEntity,
  SchemaExclusiveArray,
  SchemaEntity,
  SchemaSet,
} from '../../entities';

export const initializeActionTarget = Symbol();

export function initialize(schema, initialState) {
  return initializeSchemaItem(schema.rootEntity, {}, initialState);
}

export function initializeSchemaItem(
  schemaItem,
  rootState,
  initialState,
  path = []
) {
  switch (true) {
    case schemaItem instanceof SchemaSingletonEntity:
      return initializeSingletonEntity(
        schemaItem,
        rootState,
        initialState,
        path
      );

    case schemaItem instanceof SchemaExclusiveArray:
      return initializeExclusiveArray(
        schemaItem,
        rootState,
        initialState,
        path
      );

    case schemaItem instanceof SchemaEntity:
      return initializeSchemaEntity(schemaItem, rootState, initialState, path);

    case schemaItem instanceof SchemaSet:
      return initializeSchemaSet(schemaItem, rootState, initialState, path);

    default:
    // do nothing
  }

  return rootState;
}

export function initializeSingletonEntity(
  singletonEntity,
  rootState,
  initialState,
  path
) {
  if (initialState === null || initialState === undefined) {
    return {
      ...rootState,
      [singletonEntity.key]: null,
    };
  }

  let newState = rootState;
  const initializedKeys = new Map();

  Object.entries(singletonEntity.definition).forEach(([key, value]) => {
    newState = initializeSchemaItem(value, newState, initialState[key], [
      ...path,
      singletonEntity,
    ]);

    initializedKeys.set(key, true);
  });

  const entityState = Object.fromEntries(
    Object.entries(initialState)
      .map(([key, value]) => (initializedKeys.has(key) ? null : [key, value]))
      .filter((x) => x)
  );

  return {
    ...newState,
    [singletonEntity.key]: entityState,
  };
}

export function initializeExclusiveArray(
  exclusiveArrayEntity,
  rootState,
  initialState,
  path
) {
  const newState = initializeSchemaEntityEntities(
    exclusiveArrayEntity.entity,
    rootState
  );

  return (initialState ?? []).reduce(
    (acc, entityState) =>
      initializeSchemaEntity(exclusiveArrayEntity.entity, acc, entityState, [
        ...path,
        exclusiveArrayEntity,
      ]),
    newState
  );
}

export function initializeSchemaEntityEntities(schemaEntity, rootState) {
  const entityFamilyKey = schemaEntity.key;

  return rootState[entityFamilyKey] !== undefined
    ? rootState
    : {
        ...rootState,
        [entityFamilyKey]: rootState[entityFamilyKey] ?? {},
      };
}

export function initializeSchemaEntity(
  schemaEntity,
  rootState,
  initialState,
  path
) {
  const id = schemaEntity.getId(initialState);
  const entityFamilyKey = schemaEntity.key;

  let newState = rootState;
  const initializedKeys = new Map();

  Object.entries(schemaEntity.definition).forEach(([key, value]) => {
    newState = initializeSchemaItem(value, newState, initialState[key], [
      ...path,
      schemaEntity,
    ]);

    initializedKeys.set(key, true);
  });

  const entityState = Object.fromEntries(
    Object.entries(initialState)
      .map(([key, value]) => (initializedKeys.has(key) ? null : [key, value]))
      .filter((x) => x)
  );

  const entityFamily = newState[entityFamilyKey] ?? {};

  return {
    ...newState,
    [entityFamilyKey]: {
      ...entityFamily,
      [id]: entityState,
    },
  };
}

export function initializeSchemaSet(schemaSet, rootState, initialState, path) {
  const entitySchema = schemaSet.entity;
  let newState = initializeSchemaEntityEntities(schemaSet.entity, rootState);
  const setState = (newState[schemaSet.key] = newState[schemaSet.key] ?? {
    members: [],
    renderedState: {},
  });

  Object.values(initialState ?? {}).forEach((entityState) => {
    newState = initializeSchemaEntity(entitySchema, newState, entityState, [
      ...path,
      schemaSet,
    ]);

    const id = entitySchema.getId(entityState);
    setState.members[id] = true;
    setState.renderedState[id] = newState[entitySchema.key][id];
  });

  return newState;
}
