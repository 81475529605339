import React from 'react';
import { standardDateTimeFormat } from 'js/utils/string';

import styles from './MetadataAndHistory.module.scss';

export function Revision({ revision }) {
  return (
    <div className={styles.revision}>
      <div className={styles['published-by']}>
        <div className={styles['published-by-identifier']}>&nbsp;</div>
      </div>
      <div className={styles['revision-version']}>{revision.version_id}</div>
      <div className={styles['revision-published-at']}>
        {standardDateTimeFormat.format(revision.published_at)}
      </div>
    </div>
  );
}

export default Revision;
