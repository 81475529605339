export const getAnswerDomain = (answers, propName = 'value', numPoints = 2) => {
  if (answers.length === 0) {
    return [0, 0];
  }

  const initialBound = +answers[0][propName];

  const [min, max] = answers.reduce(
    (accumulator, answer) => {
      if (answer?.is_na) {
        return accumulator;
      }
      const answerValue = +answer[propName];

      return [
        Math.min(accumulator[0], answerValue),
        Math.max(accumulator[1], answerValue),
      ];
    },
    [initialBound, initialBound]
  );

  return Array.from(
    { length: numPoints },
    (_, i) => min + ((max - min) * i) / (numPoints - 1)
  );
};
