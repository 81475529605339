import { SchemaItem } from './SchemaItem';

export class SchemaSet extends SchemaItem {
  constructor(entity) {
    super();

    this.key = entity.key;
    this.entity = entity;
  }

  getPositionKey(state) {
    throw new Error('Not implemented');
  }
}
