import Ajv from 'ajv';
import { assessmentSchema, customKeywords } from './schema';
import jsonpointer from 'jsonpointer';
import ajvErrors from 'ajv-errors';

const ELEMENT_PATH = /^(\/pages\/\d+\/elements\/\d+)(?:\/[^/]+)?$/;
const PAGE_PATH = /^(\/pages\/\d+)(?:\/[^/]+)?$/;
const META_PATH = /^\/meta/;

function getObjectGroup({ instancePath, data }, assessment) {
  switch (true) {
    case PAGE_PATH.test(instancePath):
      return {
        group: 'page',
        groupElement: jsonpointer.get(
          assessment,
          PAGE_PATH.exec(instancePath)[1]
        ),
      };
    case ELEMENT_PATH.test(instancePath):
      return {
        group: 'element',
        groupElement: jsonpointer.get(
          assessment,
          ELEMENT_PATH.exec(instancePath)[1]
        ),
      };
    case META_PATH.test(instancePath):
      return {
        group: 'meta',
        groupElement: assessment.meta,
      };
    default:
      return {
        group: 'unknown',
        groupElement: data,
      };
  }
}

export class AssessmentValidator {
  constructor() {
    this.ajv = ajvErrors(
      new Ajv({
        allErrors: true,
        verbose: true,
        discriminator: true,
        strict: false,
      })
    );

    Object.values(customKeywords).forEach((keyword) => {
      this.ajv.addKeyword(keyword);
    });

    this.validate = this.ajv.compile(assessmentSchema);
  }

  validateAssessment(assessment) {
    if (!assessment) {
      return ['Assessment is required'];
    }

    const isValid = this.validate(assessment);

    if (!isValid) {
      return this.validate.errors
        .map((error) => {
          const objectGroup = getObjectGroup(error, assessment);

          switch (error.keyword) {
            case 'if':
              return null;
            default:
              return { ...objectGroup, ...error };
          }
        })
        .filter((x) => x !== null);
    }

    return [];
  }
}
