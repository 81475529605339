import React from 'react';
import BaseChoice from '../BaseChoice';
import ValueListChoiceCustom from './ValueListChoiceCustom';
import ValueListChoiceStandard from './ValueListChoiceStandard';

function ValueListChoice({ ...props }) {
  return (
    <BaseChoice
      {...props}
      customChoiceComponent={ValueListChoiceCustom}
      standardChoiceComponent={ValueListChoiceStandard}
    />
  );
}

export default ValueListChoice;
