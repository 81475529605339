import React from 'react';

import styles from '../AnswerSet.module.scss';

export function ValueListNaChoice({ text, isStandard }) {
  return (
    <tr className={`${styles['answer']} ${styles['score-na']}`}>
      {!isStandard && <td className={styles['drag-column']}></td>}
      <td className={styles['score-cell'] + ' ' + styles['score-bar-only']}>
        <span className={styles['score-bar']}></span>
      </td>
      <td>
        <span className={styles['input-padding']}>{text ?? 'N/A'}</span>
      </td>
      {!isStandard && <td className={styles['answer-actions']}></td>}
    </tr>
  );
}

export default ValueListNaChoice;
