import React from 'react';

import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HeroBadge, Icon, IconStack } from 'js/components';

const AllAnsweredMessage = () => (
  <div className="all-answered">
    <Card>
      <CardBody>
        <HeroBadge
          renderIcon={() => (
            <IconStack className="complete-icon">
              <Icon icon={['fas', 'circle']} />
              <Icon icon={['fal', 'map-signs']} inverse transform="shrink-6" />
            </IconStack>
          )}
          title="Almost there&hellip;"
        >
          <p>
            If everything looks good, submit your answers and return to your
            regularly scheduled program.
          </p>
          <div className="action-items">
            <Link
              to="complete"
              className="btn btn-primary btn-hero"
              data-test="finish-link"
            >
              <Icon className="btn-icon-left" icon={['far', 'narwhal']} />
              I'm done
            </Link>
            <Link to=".">Wait, I forgot something...</Link>
          </div>
        </HeroBadge>
      </CardBody>
    </Card>
  </div>
);

export default AllAnsweredMessage;
