/* eslint jsx-a11y/anchor-is-valid: "off" */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFlags } from 'js/hooks/useFlags';
import { Entitlements, allows } from 'js/utils/entitlement-utils';
import { ListNavLink, Icon } from '../';
import { Nav, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Button } from 'js/components';
import { monogram } from 'js/utils/string';
import { canChangeWorkspaceSettings } from 'js/utils/authorization-utils';
import { allowOrgs, allowWorkspaceInvites } from 'js/utils/entitlement-utils';
import { faClipboardListCheck } from '@fortawesome/pro-solid-svg-icons';

function toggleSidebar() {
  document.body.classList.toggle('sidebar-mini');
}

function Sidebar({ me, activeWorkspace }) {
  const flags = useFlags();
  const [avatarOpen, setAvatarOpen] = useState(false);

  const renderWorkspaces = () => {
    const { workspaces } = me;
    const sortedWorkspaces = [...workspaces].sort((a, b) =>
      a.name?.toUpperCase()?.localeCompare(b.name?.toUpperCase())
    );

    const renderSingleWorkspace = () => {
      return (
        <a data-toggle="collapse">
          <span
            data-test="current-workspace-name"
            className="current-workspace-name"
          >
            {activeWorkspace.name}
          </span>
        </a>
      );
    };

    const renderMultipleWorkspaces = () => {
      return (
        <>
          <a
            data-toggle="collapse"
            aria-expanded={avatarOpen}
            onClick={() => setAvatarOpen(!avatarOpen)}
          >
            <span data-test="current-workspace-name">
              <span className="current-workspace-name">
                {activeWorkspace.name}
              </span>
              <b className="caret" />
            </span>
          </a>
          <Collapse isOpen={avatarOpen}>
            <ul className="nav">
              {sortedWorkspaces.map((workspace) => (
                <li key={workspace.id}>
                  <Link
                    className="btn-link"
                    to={`/w/${workspace.id}/dashboard`}
                    onClick={() => setAvatarOpen(false)}
                  >
                    <span className="sidebar-mini-icon">
                      {monogram(workspace.name, 2, 2).toUpperCase()}
                    </span>
                    <span className="sidebar-normal workspace-name">
                      {workspace.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </Collapse>
        </>
      );
    };

    return (
      <div className="user">
        <div className="moniker">Workspace</div>
        <div className="photo">
          <div className="mongram-container">
            <div className="monogram">
              {monogram(activeWorkspace.name, 2, 2).toUpperCase()}
            </div>
          </div>
        </div>
        <div className="info">
          {workspaces.length > 1
            ? renderMultipleWorkspaces()
            : renderSingleWorkspace()}
        </div>
      </div>
    );
  };

  const displayWorkspaceSettings = canChangeWorkspaceSettings(
    me,
    activeWorkspace.id
  );

  return (
    <div>
      <div className="sidebar" data-color="green">
        <div className="logo">
          <Link to="/dashboard" className="simple-text logo-mini">
            <Icon icon={['fas', 'map-marker-alt']} />
          </Link>
          <Link to="/dashboard" className="simple-text logo-normal">
            Waypointr
          </Link>
          <div className="navbar-minimize">
            <Button
              simple
              neutral
              icon
              round
              color="transparent"
              id="minimizeSidebar"
              onClick={toggleSidebar}
            >
              <i className="visible-on-sidebar-regular">
                <Icon icon="align-center" />
              </i>
              <i className="visible-on-sidebar-mini">
                <Icon icon="list-ul" />
              </i>
            </Button>
          </div>
        </div>

        <div className="sidebar-wrapper">
          {renderWorkspaces()}

          <Nav>
            <ListNavLink
              to={`/w/${activeWorkspace.id}/dashboard`}
              exact
              className="nav-link"
              activeClassName="active"
            >
              <i>
                <Icon icon="tachometer-alt" fixedWidth />
              </i>
              <p>Dashboard</p>
            </ListNavLink>
            <ListNavLink
              to={`/w/${activeWorkspace.id}/teams`}
              exact
              className="nav-link"
              activeClassName="active"
            >
              <i>
                <Icon icon="users" fixedWidth />
              </i>
              <p>Teams</p>
            </ListNavLink>
            {flags.stripe || allowOrgs(activeWorkspace.account) ? (
              <ListNavLink
                data-test="nav-orgs"
                to={`/w/${activeWorkspace.id}/orgs`}
                exact
                className="nav-link"
                activeClassName="active"
              >
                <i>
                  <Icon icon="sitemap" fixedWidth />
                </i>
                <p>Organizations</p>
              </ListNavLink>
            ) : null}
            {allows(
              activeWorkspace.account,
              Entitlements.ALLOW_ASSESSMENT_ADMIN
            ) && (
              <ListNavLink
                to={`/w/${activeWorkspace.id}/assessments`}
                exact
                className="nav-link"
                activeClassName="active"
              >
                <i>
                  <Icon icon={faClipboardListCheck} fixedWidth />
                </i>
                <p>Assessments</p>
              </ListNavLink>
            )}
            {flags.stripe || allowWorkspaceInvites(activeWorkspace.account) ? (
              <ListNavLink
                data-test="nav-users"
                to={`/w/${activeWorkspace.id}/users`}
                exact
                className="nav-link"
                activeClassName="active"
              >
                <i>
                  <Icon icon="user-friends" fixedWidth />
                </i>
                <p>Users</p>
              </ListNavLink>
            ) : null}
            {displayWorkspaceSettings && (
              <ListNavLink
                to={`/w/${activeWorkspace.id}/settings`}
                exact
                className="nav-link"
                activeClassName="active"
              >
                <i>
                  <Icon icon="cog" fixedWidth />
                </i>
                <p>Settings</p>
              </ListNavLink>
            )}
          </Nav>
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  me: PropTypes.object.isRequired,
  activeWorkspace: PropTypes.object.isRequired,
  changeWorkspace: PropTypes.func,
};

Sidebar.defaultProps = {
  changeWorkspace: () => {},
};

export default Sidebar;
