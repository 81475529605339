import keymirror from 'js/utils/keymirror';
import { request } from 'js/api/axios';
import { Doc as YDoc, encodeStateAsUpdate } from 'yjs';

import { SCHEMAS } from '../api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';

import {
  addQuestionSet,
  addQuestionToSet,
} from 'js/components/Assessment/Editor/Binding/doc-utils';
import { initializeYDoc } from 'js/components/Assessment/Editor/Binding';
import { callApi } from '../api';

export const types = keymirror({
  ...spreadApiKeys('LIST_ASSESSMENT_TEMPLATES'),
  ...spreadApiKeys('CREATE_ASSESSMENT_TEMPLATE'),
  ...spreadApiKeys('UPDATE_ASSESSMENT_TEMPLATE'),
  ...spreadApiKeys('CREATE_ASSESSMENT_TEMPLATE_REVISION'),
  ...spreadApiKeys('LIST_ASSESSMENT_TEMPLATE_REVISIONS'),
  ...spreadApiKeys('LOAD_ASSESSMENT_TEMPLATE_SUBSCRIPTION'),
  ...spreadApiKeys('LIST_ASSESSMENT_TEMPLATE_SUBSCRIPTIONS'),
});

export function list(queryId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LIST_ASSESSMENT_TEMPLATES, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/assessment-templates`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta: !!queryId ? { queryId } : {},
        schema: SCHEMAS.ASSESSMENT_TEMPLATE_ARRAY,
      },
      dispatch
    );
  };
}

export function listDocs(queryId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LIST_ASSESSMENT_TEMPLATES, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta: !!queryId ? { queryId } : {},
        schema: SCHEMAS.ASSESSMENT_DOC_TEMPLATE_ARRAY,
      },
      dispatch
    );
  };
}

// export function create(team) {
//   return (dispatch) => {
//     return callApi(
//       {
//         types: extractApiKeys(types.CREATE_TEAM, types),
//         request: (data, token, currentWorkspaceId) =>
//           request.post(`/api/workspaces/${currentWorkspaceId}/teams`, data, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }),
//         data: { team },
//         schema: SCHEMAS.TEAM,
//       },
//       dispatch
//     );
//   };
// }

export function listRevisions(assessmentTemplateId, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LIST_ASSESSMENT_TEMPLATE_REVISIONS, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates/${assessmentTemplateId}/revisions`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.ASSESSMENT_DOC_TEMPLATE_REVISION_ARRAY,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function publishRevision(assessmentTemplateId, revision) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_ASSESSMENT_TEMPLATE_REVISION, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates/${assessmentTemplateId}/revisions`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { revision },
        schema: SCHEMAS.ASSESSMENT_DOC_TEMPLATE_REVISION,
      },
      dispatch
    );
  };
}

export function create(props, initialState) {
  const doc = new YDoc();

  const initialStateWithId = {
    ...initialState,
    meta: { ...initialState?.meta, key: `assessment-${doc.guid}` },
  };

  initializeYDoc(doc, initialStateWithId, this);
  const qsId = addQuestionSet(doc, 'New question set');
  addQuestionToSet(doc, qsId, 'likert', 'Enter the question text');

  const update = encodeStateAsUpdate(doc);

  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_ASSESSMENT_TEMPLATE, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: {
          assessment_doc_template: {
            ...props,
            initial_update: [...update.values()],
          },
        },
        schema: SCHEMAS.ASSESSMENT_DOC_TEMPLATE,
      },
      dispatch
    );
  };

  // const res = await request.post(
  //   `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates`,
  //   {
  //     assessment_doc_template: {
  //       initial_update: [...update.values()],
  //     },
  //   },
  //   {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   }
  // );
}

export function update(assessmentTemplateId, updates) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_ASSESSMENT_TEMPLATE, types),
        request: (data, token, currentWorkspaceId) =>
          request.patch(
            `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates/${assessmentTemplateId}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { assessment_doc_template: updates },
        schema: SCHEMAS.ASSESSMENT_DOC_TEMPLATE,
      },
      dispatch
    );
  };
}

export function listSubscriptions(queryId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(
          types.LIST_ASSESSMENT_TEMPLATE_SUBSCRIPTIONS,
          types
        ),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/assessment-doc-template-subscriptions`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          ),
        schema: SCHEMAS.ASSESSMENT_DOC_TEMPLATE_SUBSCRIPTION_ARRAY,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function loadSubscription(subscriptionId, queryId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(
          types.LOAD_ASSESSMENT_TEMPLATE_SUBSCRIPTION,
          types
        ),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/assessment-doc-template-subscriptions/${subscriptionId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          ),
        schema: SCHEMAS.ASSESSMENT_DOC_TEMPLATE_SUBSCRIPTION,
        meta: { queryId },
      },
      dispatch
    );
  };
}
