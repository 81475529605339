import { useEffect, useMemo, useRef, useState } from 'react';
import { useProviders } from 'js/ydoc';
import { nextColorIndex } from 'js/components/Assessment/Editor/Binding/utils';
import { useSelector } from 'react-redux';

export function useCollaborators() {
  const providers = useProviders();
  const [collaborators, setCollaborators] = useState([]);
  const collaboratorColorMap = useRef({});
  const me = useSelector((state) => state.identity.me);

  const awareness = useMemo(() => {
    return providers.channelProvider?.awareness;
  }, [providers]);

  useEffect(() => {
    if (awareness) {
      const updateCollaborators = () => {
        setCollaborators(
          [...awareness.getStates().entries()].map(([key, val]) => {
            let colorIndex = collaboratorColorMap.current[val.username];
            if (colorIndex === undefined) {
              colorIndex = nextColorIndex(
                Object.values(collaboratorColorMap.current)
              );
              collaboratorColorMap.current[val.username] = colorIndex;
            }
            return {
              key,
              colorIndex,
              me: val.username === me.username,
              ...val,
            };
          })
        );
      };
      updateCollaborators();
      awareness.on('change', updateCollaborators);
      return () => {
        awareness.off('change', updateCollaborators);
      };
    }
  }, [awareness, me.username]);

  return collaborators;
}

export function useCollaboratorForField(parentType, parentId, field) {
  const collaborators = useCollaborators();
  return useMemo(() => {
    return collaborators.find(
      (c) =>
        c.focusedField?.parentType === parentType &&
        c.focusedField?.parentId === parentId &&
        c.focusedField?.field === field
    );
  }, [collaborators, parentType, parentId, field]);
}

export function useMyCollaborator() {
  const collaborators = useCollaborators();
  return useMemo(() => {
    return collaborators.find((c) => c.me);
  }, [collaborators]);
}
