import React, { useMemo } from 'react';
import { default as BaseConfigureSurveyAssessment } from '../ConfigureSurveyAssessment';

function transformTemplate(template) {
  return {
    ...template,
    assessment: {
      ...template.assessment,
      document: template.assessment,
      question_sets: template.assessment.pages
        .filter((p) => p.pageType === 'question-set')
        .map((qs) => ({
          ...qs,
          questions: qs.elements
            .filter((e) => e.elementType === 'question')
            .map((q) => ({
              ...q,
              answers: q.answers?.map((a) => {
                const newAnswer = { ...a, guid: a.id };
                delete newAnswer.id;
                return newAnswer;
              }),
            })),
        })),
    },
  };
}

export default function ConfigureSurveyAssessment({ template, ...props }) {
  const transformedTemplate = useMemo(
    () => transformTemplate(template),
    [template]
  );

  return (
    <BaseConfigureSurveyAssessment template={transformedTemplate} {...props} />
  );
}
