import React from 'react';

import { standardColor } from 'js/utils/color';

const scoreDotRadius = 3;
const scoreDotSpacing = 2;
const scoreDotIncrement = scoreDotRadius * 2 + scoreDotSpacing;
const fillColor = standardColor();

export function ValueListSparkline({ choices }) {
  return (
    <svg
      height={16}
      width={choices.length * scoreDotIncrement - scoreDotSpacing}
      viewBox={`0 0 ${choices.length * scoreDotIncrement - scoreDotSpacing} 16`}
    >
      {choices.map((_choice, i) => (
        <rect
          key={i}
          x={i * scoreDotIncrement}
          y={8 - scoreDotRadius}
          width={scoreDotRadius * 2}
          height={scoreDotRadius * 2}
          rx={1}
          fill={fillColor}
        />
      ))}
    </svg>
  );
}

export default ValueListSparkline;
