export function positionReducerForKey(key) {
  return (state, action) => {
    positionReducer(state[key], action, {
      getPositionPatch: (x) => x?.patch?.[key],
    });
  };
}

export function positionReducer(
  state,
  action,
  { getPositionPatch = (x) => x.patch }
) {
  const positionPatch = getPositionPatch(action.patch);

  if (positionPatch === undefined) {
    return state;
  }

  const newState = Object.entries(positionPatch).reduce(
    (acc, [key, patchAction]) => {
      if (patchAction.action === 'delete') {
        const newAcc = {
          ...acc,
        };
        delete newAcc[key];

        return newAcc;
      }

      return {
        ...acc,
        [key]: patchAction,
      };
    },
    state
  );

  return newState;
}
