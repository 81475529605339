// TODO: [ ] Don't show unavailable options for standard answer sets
//            - editable name
//            - editable choices
//            - editable NA option
//
import React, { useState, useMemo } from 'react';

import { faStar as standardIcon } from '@fortawesome/pro-solid-svg-icons';
import {
  faCirclePlus,
  faCircleInfo,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';

import { Input, FormGroup, Button } from 'reactstrap';

import { Icon } from 'js/components';
import { Checkbox } from 'js/components/Forms/Checkbox';

import { standardColor } from 'js/utils/color';
import { useStandardCollator } from 'js/utils/string';
import useEditorInput from '../../useEditorInput';

import styles from './AnswerSet.module.scss';

// const scoreDotRadius = 3;
// const scoreDotSpacing = 2;
// const scoreDotIncrement = scoreDotRadius * 2 + scoreDotSpacing;

const getTypeName = (answerSetType) => {
  switch (answerSetType) {
    case 'likert':
      return 'Scored';

    case 'value-list':
      return 'Values';

    default:
      return 'Unknown';
  }
};

export function BaseAnswerSet({
  answerSet,
  usedByCount = 10,
  choiceListComponent: ChoiceListComponent,
  sparklineComponent: SparklineComponent,
  allowNaOption = true,
  getColor = () => standardColor,
  onDuplicate = () => {},
  onUpdate = () => {},
  onDelete = () => {},
  onChoiceUpdate = () => {},
  onChoiceCreate = () => {},
  onChoiceDelete = () => {},
}) {
  const isStandard = answerSet.isStandard ?? false;
  // const isStandard = true;

  const [isOpen, setIsOpen] = useState(false);
  const collator = useStandardCollator();

  const answerSetChoices = answerSet.choices;
  const choiceArray = useMemo(() => {
    return Object.values(answerSetChoices).sort((a, b) =>
      collator.compare(a.p, b.p)
    );
  }, [answerSetChoices, collator]);

  const color = getColor(choiceArray);

  // const color = scaleColorTraffic
  //   .copy()
  //   .domain(getAnswerDomain(choiceArray, 'value', 3));

  const handleNameChange = (name) => {
    onUpdate(answerSet, { name: name });
  };

  const handleIncludeNAChange = (e) => {
    const includeNA = e.target.checked;
    onUpdate(answerSet, { includeNA: includeNA });
  };

  const handleNATextChange = (naText) => {
    onUpdate(answerSet, { naText: naText });
  };

  const {
    reset: resetName,
    ref: nameRef,
    ...nameInputProps
  } = useEditorInput({
    value: answerSet.name,
    onCommit: handleNameChange,
  });
  const {
    reset: resetNaText,
    ref: naTextRef,
    ...naTextInputProps
  } = useEditorInput({
    value: answerSet.naText,
    onCommit: handleNATextChange,
    placeholder: 'N/A',
  });

  const handleAddChoice = () => {
    onChoiceCreate(answerSet.id, {});
  };

  const handleChoiceUpdate = (choice, updates) => {
    onChoiceUpdate(answerSet, choice, updates);
  };

  const handleChoiceDelete = (choiceId) =>
    onChoiceDelete(answerSet.id, choiceId);

  const typeName = getTypeName(answerSet.type);

  return (
    <React.Fragment>
      <div className={styles['answer-set-usage']}>
        {usedByCount > 0 && (
          <span className={styles['answer-set-badge']}>{usedByCount}</span>
        )}
      </div>
      <div className={styles['answer-set-info']}>
        <div className={styles['answer-set-card-header']}>
          <div className={styles['answer-set-name']}>{answerSet.name}</div>
          <div
            className={`${styles['answer-set-actions']} ${
              isOpen ? styles['is-open'] : null
            }`}
          >
            <button
              className="btn-inline-action btn-primary"
              aria-pressed={isOpen}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icon icon={faChevronRight} rotation={isOpen ? 90 : 0} />
            </button>
          </div>
          <div className={styles['answer-set-spark']}>
            <SparklineComponent choices={choiceArray} color={color} />
          </div>
          <div className={`${styles['answer-set-type']} ${styles.scored}`}>
            {isStandard && <Icon icon={standardIcon} className="icon-left" />}
            {typeName}
          </div>
        </div>
        {/* <div className={styles['answer-set-card-body']}></div> */}
      </div>
      {isOpen && (
        <>
          {isStandard && (
            <>
              <div className={styles['answer-set-standard-message-pad']}></div>
              <div className={styles['answer-set-standard-message']}>
                <Icon icon={faCircleInfo} className="icon-left" />
                This is a standard answer set. Copy it to make any changes you
                need. We'll keep this one safe so you can use it in the future.
              </div>
            </>
          )}

          <div className={styles['answer-set-usage-pad']}></div>
          <div className={styles['answer-set-settings']}>
            {!isStandard && (
              <FormGroup>
                <label htmlFor={`${answerSet.id}-name`}>Name</label>
                <Input
                  type="text"
                  id={`${answerSet.id}-name`}
                  innerRef={nameRef}
                  {...nameInputProps}
                />
              </FormGroup>
            )}
            <FormGroup>
              <div className={styles['answer-set-choices']}>
                <div className={styles['choices-controls']}>
                  <div>
                    <label>Choices</label>
                  </div>
                  <div>
                    {!isStandard && (
                      <button
                        className="btn-inline-action btn-primary"
                        onClick={handleAddChoice}
                      >
                        <Icon icon={faCirclePlus} />
                      </button>
                    )}
                  </div>
                </div>

                <ChoiceListComponent
                  answerSetId={answerSet.id}
                  isStandard={isStandard}
                  choices={choiceArray}
                  color={color}
                  includeNA={answerSet.includeNA}
                  naText={answerSet.naText}
                  onChoiceUpdate={handleChoiceUpdate}
                  onChoiceDelete={handleChoiceDelete}
                />
                {/* <table>
                  <thead>
                    <tr>
                      {!isStandard && (
                        <td className={styles['drag-column']}></td>
                      )}
                      <th
                        className={`${styles.shifted} ${styles['value-column']}`}
                      >
                        Score
                      </th>
                      <th className={styles.shifted}>Text</th>
                      {!isStandard && <td></td>}
                    </tr>
                  </thead>
                  <tbody>
                    {choiceArray.map((choice, i) => (
                      <ChoiceComponent
                        key={choice.id}
                        choice={choice}
                        color={color}
                        isStandard={isStandard}
                        className={styles['answer']}
                        onUpdate={handleChoiceUpdate}
                        onDelete={handleChoiceDelete}
                        index={i}
                        answerSetId={answerSet.id}
                      />
                    ))}
                    {answerSet.includeNA && (
                      <NaChoice
                        text={answerSet.naText}
                        isStandard={isStandard}
                      />
                    )}
                  </tbody>
                </table> */}
              </div>
            </FormGroup>
            {!isStandard && allowNaOption && (
              <FormGroup>
                <Checkbox
                  onChange={handleIncludeNAChange}
                  checked={answerSet.includeNA}
                >
                  Enable a "Not Applicable" option
                </Checkbox>
                {answerSet.includeNA && (
                  <>
                    <label htmlFor={`${answerSet.id}-na-text`}>
                      Rename the "Not Applicable" option
                    </label>
                    <Input
                      type="text"
                      id={`${answerSet.id}-na-text`}
                      innerRef={naTextRef}
                      {...naTextInputProps}
                    />
                  </>
                )}
              </FormGroup>
            )}
            <div className={styles['answer-set-bottom-actions']}>
              <Button
                color="secondary"
                onClick={() => onDuplicate(answerSet)}
                size="sm"
              >
                <Icon icon={'copy'} className="icon-left" />
                Copy
              </Button>
              {!isStandard && (
                <Button
                  color="danger"
                  onClick={() => onDelete(answerSet.id)}
                  size="sm"
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default BaseAnswerSet;
