import React from 'react';
// import * as Y from 'yjs';
import { PanelHeader } from 'js/components';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import CreateAssessmentForm from './CreateAssessmentForm';
// import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
// import { request } from 'js/api/axios';
// import { useSelector } from 'react-redux';
// import {
//   addQuestionSet,
//   addQuestionToSet,
// } from 'js/components/Assessment/Editor/Binding/doc-utils';
// import {
//   CURRENT_DOC_FORMAT_VERSION,
//   initializeYDoc,
// } from 'js/components/Assessment/Editor/Binding';
// import { useHistory } from 'react-router-dom';

export function AssessmentCreatorComponent({ onCreate }) {
  // const token = useSelector((state) => state.identity.token);
  // const username = useSelector((state) => state.identity.me.username);
  // const currentWorkspaceId = useSelector((state) => state.currentWorkspaceId);
  // const history = useHistory();

  // function buildInitialState(doc, author) {
  //   return {
  //     meta: {
  //       key: 'assessment-' + doc.guid,
  //       type: 'survey',
  //       configurable: true,
  //       name: 'New Assessment',
  //       description:
  //         'Evaluate key skills and competencies to identify strengths and areas for growth in your team.',
  //       author,
  //       isPublic: false,
  //       doc_format_version: CURRENT_DOC_FORMAT_VERSION,
  //     },
  //     assessment: {},
  //     questionSets: [],
  //     answerSets: {},
  //     config: {
  //       version: 0.1,
  //       categories: {},
  //     },
  //     views: {},
  //   };
  // }

  // async function create(e) {
  //   e.preventDefault();
  //   const doc = new Y.Doc();

  //   initializeYDoc(doc, buildInitialState(doc, username), this);
  //   const qsId = addQuestionSet(doc, 'New question set');
  //   addQuestionToSet(doc, qsId, 'likert', 'Enter the question text');

  //   const update = Y.encodeStateAsUpdate(doc);

  //   try {
  //     const res = await request.post(
  //       `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates`,
  //       {
  //         assessment_doc_template: {
  //           initial_update: [...update.values()],
  //         },
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     console.log('res:', res);
  //     history.push(
  //       `/w/${currentWorkspaceId}/assessments/${res.data.data.id}/edit`
  //     );
  //   } catch (e) {
  //     console.error(e);
  //     alert('Failed to create assessment template');
  //   }
  // }

  return (
    <div>
      <PanelHeader size="flex">
        {/* <div className="panel-content">
          <h2>Create an Assessment Template</h2>
        </div> */}
      </PanelHeader>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Create a new assessment template</CardTitle>
          </CardHeader>
          <CardBody>
            <CreateAssessmentForm onSubmit={onCreate} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AssessmentCreatorComponent;
