// NOTES:
// For standard answer sets
// - You must always create a copy
// - Whether you ony assign the current answer set or all answer sets depends on the modals response (applyToAll)
// - If autoCreateCopy is true, then the modal will not show again

import { useCallback, useRef, useState } from 'react';
import { AnswerSetModal, ModalTypes } from './AnswerSetModal';

import {
  moveAnswerSetChoiceAfter,
  moveAnswerSetChoiceBefore,
  createAnswerSetChoice,
  updateAnswerSetChoice,
  deleteAnswerSetChoice,
  cloneAnswerSet,
} from './Binding/answer-set-actions';

export const AutoApplyTo = {
  ONE: 'ONE',
  ALL: 'ALL',
};

function executeOperation(
  yDoc,
  questionId,
  answerSet,
  stats,
  shouldCloneAnswerSet,
  applyToAll,
  fn
) {
  let targetAnswerSet = answerSet;

  yDoc.transact(() => {
    if (shouldCloneAnswerSet) {
      targetAnswerSet = cloneAnswerSet(yDoc, answerSet, {
        name: answerSet.name + ' (custom)',
        preserveChoiceIds: true,
      });
    }

    if (applyToAll) {
      stats.usedBy.forEach((qId) => {
        yDoc
          .getMap('elements')
          ?.get(qId)
          ?.set('answerSetId', targetAnswerSet.id);
      });
    } else {
      yDoc
        .getMap('elements')
        ?.get(questionId)
        ?.set('answerSetId', targetAnswerSet.id);
    }

    // TODO: proceed with the operation on the new copy
    fn(targetAnswerSet.id);
  });

  return targetAnswerSet.id;
}

// function getModalType(guardType, autoCreateCopy, autoApplyTo) {
//   if (guardType === GuardTypes.STANDARD) {
//     return ModalTypes.STANDARD_SINGLE_QUESTION;
//   } else if (guardType === GuardTypes.STANDARD_USED_BY_MULTIPLE) {
//     return ModalTypes.STANDARD_MULTI_QUESTION;
//   } else if (guardType === GuardTypes.USED_BY_MULTIPLE) {
//     return ModalTypes.CUSTOM_MULTI_QUESTION;
//   }

//   return null;
// }

// function isUnguarded(guardType) {
//   return (
//     guardType === GuardTypes.BYPASSED || guardType === GuardTypes.SINGLE_USE
//   );
// }

// function setModal(modalType, onConfirm, onCancel, setModal) {
//   setModal({
//     type: modalType,
//     onConfirm,
//     onCancel,
//   });
// }

// standard vs custom
// usedByMultiple vs single
// autoCreateCopy vs !autoCreateCopy
// autoApplyTo.All vs autoApplyTo.One vs null (not specified)
// 2 * 2 * 2 * 3 = 24 possible combinations

// CASES:
// 1. x3 Standard answer set used by one question && !autoCreateCopy - modal type = STANDARD_SINGLE_QUESTION
// 2. x3 Standard answer set used by one question && autoCreateCopy - Automatically create the copy and apply changes to all
// 3. Standard answer set used by multiple questions && !autoCreateCopy && autoApplyTo === null - modal type = STANDARD_MULTI_QUESTION
// 4. x2 Standard answer set used by multiple questions && !autoCreateCopy && autoApplyTo !== null - modal type = STANDARD_SINGLE_QUESTION
// 4. Standard answer set used by multiple questions && autoCreateCopy && autoApplyTo === null - modal type = CUSTOM_MULTI_QUESTION
// 5. x2 Standard answer set used by multiple questions && autoCreateCopy && autoApplyTo !== null - Automatically create the copy and apply changes based on autoApplyTo variable
// 6. x6 Custom answer set used by one question - Just apply the function
// 7. x2 Custom answer set used by multiple questions && autoApplyTo === null - modal type = CUSTOM_MULTI_QUESTION
// 8. x4 Custom answer set used by multiple questions && autoApplyTo !== null - Automatically apply changes based on autoApplyTo variable

export function useAnswerSetGuard({ yDoc, answerSets, answerSetStats }) {
  const approvalsRef = useRef(null);

  if (approvalsRef.current === null) {
    approvalsRef.current = new Map();
  }

  const approvals = approvalsRef.current;

  const [modalArgs, setModalArgs] = useState(null);
  const [autoCreateCopy, setAutoCreateCopy] = useState(false);
  const [autoApplyTo, setAutoApplyTo] = useState(null);

  const snoozeApprovals = useCallback(
    (answerSetId) => {
      approvals.set(answerSetId, Date.now() + 1000 * 60 * 5);
    },
    [approvals]
  );

  const guardCall = useCallback(
    (questionId, answerSetId, fn) => {
      const answerSet = answerSets[answerSetId];

      if (!answerSet) {
        console.warn('Answer set not found', answerSetId);
        return;
      }

      const isSnoozed = approvals.get(answerSetId) > Date.now();
      const usedByCount = answerSetStats[answerSetId].usedBy.length;
      const isStandard = answerSet.isStandard;
      const stats = answerSetStats[answerSetId];
      const onCancel = () => {
        setModalArgs(null);
      };

      if (!isStandard && isSnoozed) {
        fn(answerSetId);

        setModalArgs(null);
        snoozeApprovals(answerSetId);
      } else if (isStandard && !autoCreateCopy && usedByCount === 1) {
        // modal type = STANDARD_SINGLE_QUESTION
        setModalArgs({
          type: ModalTypes.STANDARD_SINGLE_QUESTION,
          onConfirm: (_applyToAll, dontBugMe) => {
            if (dontBugMe) {
              setAutoCreateCopy(true);
            }

            const targetAnswerSetId = executeOperation(
              yDoc,
              questionId,
              answerSet,
              stats,
              true,
              true,
              fn
            );

            setModalArgs(null);
            snoozeApprovals(targetAnswerSetId);
          },
          onCancel,
        });
      } else if (isStandard && autoCreateCopy && usedByCount === 1) {
        // Automatically create the copy and apply changes to all
        const targetAnswerSetId = executeOperation(
          yDoc,
          questionId,
          answerSet,
          stats,
          true,
          true,
          fn
        );
        setModalArgs(null);
        snoozeApprovals(targetAnswerSetId);
      } else if (
        isStandard &&
        usedByCount > 1 &&
        !autoCreateCopy &&
        autoApplyTo === null
      ) {
        // modal type = STANDARD_MULTI_QUESTION
        setModalArgs({
          type: ModalTypes.STANDARD_MULTI_QUESTION,
          onConfirm: (applyToAll, dontBugMe) => {
            if (dontBugMe) {
              setAutoCreateCopy(true);
              setAutoApplyTo(applyToAll ? AutoApplyTo.ALL : AutoApplyTo.ONE);
            }

            const targetAnswerSetId = executeOperation(
              yDoc,
              questionId,
              answerSet,
              stats,
              true,
              applyToAll,
              fn
            );

            setModalArgs(null);
            snoozeApprovals(targetAnswerSetId);
          },
          onCancel,
        });
      } else if (
        isStandard &&
        usedByCount > 1 &&
        !autoCreateCopy &&
        autoApplyTo !== null
      ) {
        // modal type = STANDARD_SINGLE_QUESTION
        setModalArgs({
          type: ModalTypes.STANDARD_SINGLE_QUESTION,
          onConfirm: (_applyToAll, dontBugMe) => {
            if (dontBugMe) {
              setAutoCreateCopy(true);
            }

            const targetAnswerSetId = executeOperation(
              yDoc,
              questionId,
              answerSet,
              stats,
              true,
              autoApplyTo === AutoApplyTo.ALL,
              fn
            );

            setModalArgs(null);
            snoozeApprovals(targetAnswerSetId);
          },
          onCancel,
        });
      } else if (
        isStandard &&
        usedByCount > 1 &&
        autoCreateCopy &&
        autoApplyTo === null
      ) {
        // modal type = CUSTOM_MULTI_QUESTION
        setModalArgs({
          type: ModalTypes.CUSTOM_MULTI_QUESTION,
          onConfirm: (applyToAll, dontBugMe) => {
            if (dontBugMe) {
              setAutoApplyTo(applyToAll ? AutoApplyTo.ALL : AutoApplyTo.ONE);
            }

            const targetAnswerSetId = executeOperation(
              yDoc,
              questionId,
              answerSet,
              stats,
              true,
              applyToAll,
              fn
            );

            setModalArgs(null);
            snoozeApprovals(targetAnswerSetId);
          },
          onCancel,
        });
      } else if (
        isStandard &&
        usedByCount > 1 &&
        autoCreateCopy &&
        autoApplyTo !== null
      ) {
        // Automatically create the copy and apply changes based on autoApplyTo variable
        executeOperation(
          yDoc,
          questionId,
          answerSet,
          stats,
          true,
          autoApplyTo === AutoApplyTo.ALL,
          fn
        );

        setModalArgs(null);
      } else if (!isStandard && usedByCount === 1) {
        // Just apply the function
        fn(answerSetId);

        setModalArgs(null);
      } else if (!isStandard && usedByCount > 1 && autoApplyTo === null) {
        // modal type = CUSTOM_MULTI_QUESTION
        setModalArgs({
          type: ModalTypes.CUSTOM_MULTI_QUESTION,
          onConfirm: (applyToAll, dontBugMe) => {
            if (dontBugMe) {
              setAutoApplyTo(applyToAll ? AutoApplyTo.ALL : AutoApplyTo.ONE);
            }

            const targetAnswerSetId = executeOperation(
              yDoc,
              questionId,
              answerSet,
              stats,
              !applyToAll,
              applyToAll,
              fn
            );

            setModalArgs(null);
            snoozeApprovals(targetAnswerSetId);
          },
          onCancel,
        });
      } else if (!isStandard && usedByCount > 1 && autoApplyTo !== null) {
        // Automatically apply changes based on autoApplyTo variable
        executeOperation(
          yDoc,
          questionId,
          answerSet,
          stats,
          autoApplyTo === AutoApplyTo.ONE,
          autoApplyTo === AutoApplyTo.ALL,
          fn
        );

        setModalArgs(null);
      }
    },
    [
      answerSets,
      yDoc,
      answerSetStats,
      approvals,
      snoozeApprovals,
      autoCreateCopy,
      autoApplyTo,
    ]
  );

  const _createAnswerSetChoice = useCallback(
    (ydoc, answerSetId, choiceProps, questionId) => {
      guardCall(questionId, answerSetId, (resolvedAnswerSetId) => {
        createAnswerSetChoice(
          ydoc,
          resolvedAnswerSetId,
          choiceProps,
          questionId
        );
      });
    },
    [guardCall]
  );

  const _updateAnswerSetChoice = (
    ydoc,
    answerSetId,
    choiceId,
    updates,
    questionId
  ) => {
    guardCall(questionId, answerSetId, (resolvedAnswerSetId) => {
      updateAnswerSetChoice(ydoc, resolvedAnswerSetId, choiceId, updates);
    });
  };
  const _deleteAnswerSetChoice = (ydoc, answerSetId, choiceId, questionId) => {
    guardCall(questionId, answerSetId, (resolvedAnswerSetId) => {
      deleteAnswerSetChoice(ydoc, resolvedAnswerSetId, choiceId);
    });
  };
  const _moveAnswerSetChoiceBefore = (
    ydoc,
    answerSetId,
    sourceChoiceId,
    dropTargetChoiceId,
    questionId
  ) => {
    if (questionId == null) {
      moveAnswerSetChoiceBefore(
        ydoc,
        answerSetId,
        sourceChoiceId,
        dropTargetChoiceId
      );
    } else {
      guardCall(questionId, answerSetId, (resolvedAnswerSetId) => {
        moveAnswerSetChoiceBefore(
          ydoc,
          resolvedAnswerSetId,
          sourceChoiceId,
          dropTargetChoiceId
        );
      });
    }
  };
  const _moveAnswerSetChoiceAfter = (
    ydoc,
    answerSetId,
    sourceChoiceId,
    dropTargetChoiceId,
    questionId
  ) => {
    if (questionId == null) {
      moveAnswerSetChoiceAfter(
        ydoc,
        answerSetId,
        sourceChoiceId,
        dropTargetChoiceId
      );
    } else {
      guardCall(questionId, answerSetId, (resolvedAnswerSetId) => {
        moveAnswerSetChoiceAfter(
          ydoc,
          resolvedAnswerSetId,
          sourceChoiceId,
          dropTargetChoiceId
        );
      });
    }
  };

  return {
    createAnswerSetChoice: _createAnswerSetChoice,
    updateAnswerSetChoice: _updateAnswerSetChoice,
    deleteAnswerSetChoice: _deleteAnswerSetChoice,
    moveAnswerSetChoiceBefore: _moveAnswerSetChoiceBefore,
    moveAnswerSetChoiceAfter: _moveAnswerSetChoiceAfter,
    Modal: modalArgs
      ? () => <AnswerSetModal isOpen {...modalArgs} />
      : () => null,
    answerSets,
  };
}
