import { isNil } from 'lodash';
import { initializeActionTarget, objectHasChanged } from './utils';

const initialState = {
  members: {},
};

export class SetReducer {
  constructor(schemaItem, path) {
    this.schemaItem = schemaItem;
    this.key = schemaItem.key;
    this.path = path;
  }

  reduce(state, entities, action) {
    if (isNil(state) && action.type === initializeActionTarget) {
      return initialState;
    }

    let newState = { ...state };

    const actionTarget = action.target;

    if (action.type !== initializeActionTarget && actionTarget === this.key) {
      switch (action.type) {
        case 'add':
        case 'patch':
        case 'addOrPatch':
          newState = this.addOrPatch(newState, entities, action);
          break;

        case 'delete':
          newState = this.delete(newState, entities, action);
          break;

        default:
        // do nothing
      }
    }

    return newState;
  }

  addOrPatch(state, entities, action) {
    const { members = {}, renderedState = {} } = state ?? initialState;
    const { entityIds } = action;
    const newMembers = { ...members };

    let membersHaveChanged = false;
    (entityIds ?? []).forEach((id) => {
      if (!members[id]) {
        newMembers[id] = true;
        membersHaveChanged = true;
      }
    });

    const entitiesOfType = entities[this.schemaItem.entity.key] ?? {};
    const newRenderedState = Object.keys(newMembers).reduce(
      (acc, id) => {
        const entity = entitiesOfType[id];
        if (entity) {
          acc[id] = entity;
        }
        return acc;
      },
      { ...renderedState }
    );

    const renderedStateHasChanged = objectHasChanged(
      renderedState,
      newRenderedState
    );

    return membersHaveChanged || renderedStateHasChanged
      ? {
          members: membersHaveChanged ? newMembers : members,
          renderedState: renderedStateHasChanged
            ? newRenderedState
            : renderedState,
        }
      : state;
  }

  delete(state, entities, action) {
    const { members = {}, renderedState = {} } = state ?? initialState;
    const { entityIds } = action;
    const newMembers = { ...members };

    let membersHaveChanged = false;
    (entityIds ?? []).forEach((id) => {
      if (members[id]) {
        delete newMembers[id];
        membersHaveChanged = true;
      }
    });

    const entitiesOfType = entities[this.schemaItem.entity.key] ?? {};
    const newRenderedState = Object.keys(newMembers).reduce((acc, id) => {
      const entity = entitiesOfType[id];
      if (entity) {
        acc[id] = entity;
      }
      return acc;
    }, {});

    const renderedStateHasChanged = objectHasChanged(
      renderedState,
      newRenderedState
    );

    return membersHaveChanged || renderedStateHasChanged
      ? {
          members: membersHaveChanged ? newMembers : members,
          renderedState: renderedStateHasChanged
            ? newRenderedState
            : renderedState,
        }
      : state;
  }
}
