import { getEngine } from 'js/engines';
import { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './EditorPreview.module.scss';

import { standardCollator } from 'js/utils/string';

export default function EditorPreview({ assessment, assessmentType }) {
  const match = useRouteMatch();
  const RunComponent = getEngine({ id: 999, type: assessmentType }).Run;
  const [responses, setResponses] = useState({});

  const handleResponse = useCallback(
    (_, questionId, response) => {
      setResponses({
        ...responses,
        [questionId]: response,
      });
    },
    [responses]
  );

  const handleUnrespond = useCallback(
    (_, questionId) => {
      setResponses({
        ...responses,
        [questionId]: null,
      });
    },
    [responses]
  );

  const handleClear = useCallback(() => {
    setResponses({});
  }, []);

  const normalizedAssessment = useMemo(() => {
    return standardizeAssessment(assessment);
  }, [assessment]);

  const assessmentWithResponses = useMemo(() => {
    return {
      ...normalizedAssessment,
      question_sets: normalizedAssessment.question_sets.map((qs) => ({
        ...qs,
        questions: qs.questions.map((q) => ({
          ...q,
          responses: responses[q.id] ? [responses[q.id]] : [],
        })),
      })),
    };
  }, [normalizedAssessment, responses]);

  return (
    <div className={styles.mainPanel}>
      <RunComponent
        match={match}
        assessment={assessmentWithResponses}
        participant={{ id: 999 }}
        isPreview
        previewClearParticipationSession={handleClear}
        previewRespondToAssessment={handleResponse}
        previewUnrespondToAssessment={handleUnrespond}
      />
    </div>
  );
}

// HACK: May be better to create a new engine for the new assessments or to make the new assessment match the old one better
function standardizeAssessment(assessment) {
  return {
    ...assessment,
    id: assessment.meta.key,
    question_sets: assessment.pages
      .filter((p) => p.pageType === 'question-set')
      .map((qs) => ({
        ...qs,
        id: qs.guid,
        questions: qs.elements
          .filter((e) => e.elementType === 'question')
          .map((q) => ({
            ...q,
            id: q.guid,
            type: q.type,
            answers: Object.values(q.answerSet?.choices ?? {})
              .sort((a, b) => standardCollator.compare(a.p, b.p))
              .map(({ value, text, id }, i) => ({
                id,
                value,
                text,
                ordinal: i,
              })),
          })),
      })),
  };
}
