import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Icon } from 'js/components';
import { faXmark } from 'js/components/Icons/imports/regular';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import EditorInput from 'js/components/Assessment/Editor/EditorInput/EditorInput';
import { Button } from 'reactstrap';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import {
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import DropIndicator from '../Questions/DropIndicator';
import { sortedCategories } from '../Binding/utils';
import { EditorTool } from './EditorTool';

import styles from '../Editor.module.scss';

export function CategoryTool({
  categories,
  onCategoryChange,
  onAddCategory,
  onDeleteCategory,
}) {
  const [autoFocusCategoryName, setAutoFocusCategoryName] = useState(null);

  const categoryList = useMemo(() => {
    return sortedCategories(categories);
  }, [categories]);

  const handleCategoryNameChange = useCallback(
    (guid, name) => {
      onCategoryChange(guid, { ...categories[guid], display_name: name });
    },
    [onCategoryChange, categories]
  );

  const handleAddCategory = useCallback(() => {
    const lastNum = categoryList
      .map((c) => c.display_name.match(/new category(\d*)/))
      .filter((match) => match)
      .map((match) => parseInt(match[1] || 0))
      .sort((a, b) => b - a)[0];

    const name = `new category${lastNum !== undefined ? lastNum + 1 : ''}`;
    setAutoFocusCategoryName(name);
    onAddCategory(name);
  }, [categoryList, onAddCategory]);

  return (
    <EditorTool title="Categories">
      <div>
        {categoryList.map((category) => (
          <Category
            key={category.guid}
            category={category}
            onNameChange={handleCategoryNameChange}
            initiallyEditing={category.display_name === autoFocusCategoryName}
            onDelete={() => onDeleteCategory(category.guid)}
          />
        ))}
      </div>
      <div>
        <Button outline color="primary" onClick={handleAddCategory}>
          <Icon icon="plus" className="btn-icon-left" />
          Add Category
        </Button>
      </div>
    </EditorTool>
  );
}

function Category({ category, onNameChange, onDelete, initiallyEditing }) {
  const dragHandleRef = useRef(null);
  const ref = useRef(null);
  const [closestEdge, setClosestEdge] = useState(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    const dragHandle = dragHandleRef.current;

    if (!el) throw new Error('No element found');
    if (!dragHandle) throw new Error('No element found');

    return draggable({
      element: el,
      dragHandle,
      getInitialData: () => ({ category }),
    });
  }, [category]);

  useEffect(() => {
    const el = ref.current;
    if (!el) throw new Error('No element found');

    return dropTargetForElements({
      element: el,
      onDragEnter: () => {
        setIsDraggedOver(true);
      },
      canDrop: ({ source }) => {
        return (
          source.data.category && source.data.category.guid !== category.guid
        );
      },
      getData: (args) => {
        return attachClosestEdge(
          { category },
          {
            input: args.input,
            element: args.element,
            allowedEdges: ['top', 'bottom'],
          }
        );
      },
      onDragLeave: () => {
        setClosestEdge(null);
        setIsDraggedOver(false);
      },
      onDrag({ self, source }) {
        const isSource = source.element === el;
        if (isSource) {
          setClosestEdge(null);
          return;
        }

        const closestEdge = extractClosestEdge(self.data);

        const sourceIndex = source.data.category.ordinal;

        const isItemBeforeSource = category.ordinal === sourceIndex - 1;
        const isItemAfterSource = category.ordinal === sourceIndex + 1;

        const isDropIndicatorHidden =
          (isItemBeforeSource && closestEdge === 'bottom') ||
          (isItemAfterSource && closestEdge === 'top');

        if (isDropIndicatorHidden) {
          setClosestEdge(null);
          return;
        }

        setClosestEdge(closestEdge);
      },
      onDrop: () => {
        setIsDraggedOver(false);
      },
    });
  }, [category]);

  return (
    <div className={styles.CategoryListing} ref={ref}>
      {isDraggedOver && closestEdge === 'top' && <DropIndicator top />}
      <button className="drag-handle" ref={dragHandleRef}>
        <Icon icon={faGripVertical} className="icon" />
      </button>
      <div
        className={`${styles.Marker} palette-cat-${category.colorIndex + 1}`}
      ></div>
      <div>
        <EditorInput
          name="text"
          value={category.display_name}
          onChange={(e) => onNameChange(category.guid, e.target.value)}
          initiallyEditing={initiallyEditing}
        />
      </div>
      <button className={styles.iconButton} onClick={onDelete}>
        <Icon icon={faXmark} className="icon" />
      </button>
      {isDraggedOver && closestEdge === 'bottom' && <DropIndicator bottom />}
    </div>
  );
}

export default CategoryTool;
