import React from 'react';

import styles from '../AnswerSet.module.scss';
import { standardColor } from 'js/utils/color';
const color = standardColor();

export function ValueListChoiceStandard({ choice }) {
  return (
    <tr key={choice.id} className={styles['choice']}>
      <td className={styles['score-cell'] + ' ' + styles['score-bar-only']}>
        <span
          className={styles['score-bar']}
          style={{ backgroundColor: color }}
        ></span>
        <span className={styles['input-padding-score']}>{choice.value}</span>
      </td>
      <td>
        <span className={styles['input-padding']}>{choice.text}</span>
      </td>
    </tr>
  );
}

export default ValueListChoiceStandard;
