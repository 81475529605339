export { localeIndexOf } from './locale-indexof';

export const standardCollator = new Intl.Collator(undefined, {
  sensitivity: 'base',
});

export function useStandardCollator() {
  // HACK: This returns a global...it should create a new instance to use by the caller
  return standardCollator;
}

export const standardDateTimeFormat = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
});

export function useStandardDateTimeFormat() {
  return standardDateTimeFormat;
}

export function titleCase(s) {
  if (s === undefined || s === null || s === '') {
    return s;
  }

  return s.replace(/\b\w+/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
}

export function monogram(s, minLength = 0, maxLength = 3) {
  if (minLength > maxLength) {
    throw new Error(
      `Minimum length > maximum length (${minLength} > ${maxLength})`
    );
  }

  const matches = s.match(/\b\w+/g);
  const matchCount = matches.length;

  if (matchCount < minLength) {
    let diff = minLength - matchCount;

    return matches
      .map((x) => {
        if (diff > 0) {
          const result = x.substr(0, diff + 1);
          diff -= result.length - 1;

          return result;
        }

        return x.charAt(0);
      })
      .join('');
  } else {
    return matches
      .map((x) => x.charAt(0))
      .slice(0, Math.min(matchCount, maxLength))
      .join('');
  }
}

const BASE32_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';

// Converts a byte array into a base32 string.
function toBase32(buffer) {
  let base32 = '';
  let bits = 0;
  let value = 0;

  for (let i = 0; i < buffer.length; i++) {
    value = (value << 8) | buffer[i];
    bits += 8;

    while (bits >= 5) {
      base32 += BASE32_ALPHABET[(value >>> (bits - 5)) & 31];
      bits -= 5;
    }
  }

  if (bits > 0) {
    base32 += BASE32_ALPHABET[(value << (5 - bits)) & 31];
  }

  return base32;
}

// Generates a random base32 encoded string of the specified length. This is good for IDs that are more readable than UUIDs.
export function randomBase32String(length) {
  const buf = new Uint8Array(Math.ceil((length * 5) / 8)); // Base32 encodes 5 bits per character
  window.crypto.getRandomValues(buf);
  return toBase32(buf).substring(0, length);
}

export const FIELD_DELIMITER = String.fromCharCode(0x1f); // ASCII Unit Separator

export function isNullOrWhitespace(s) {
  return s == null || s.trim().length === 0;
}
