import {
  SchemaEntity,
  SchemaExclusiveArray,
  SchemaSet,
  SchemaSingletonEntity,
} from '../entities';
import { SetReducer } from './PickedSetReducer';
import { EntityReducer } from './EntityReducer';
import { ExclusiveArrayReducer } from './ExclusiveArrayReducer';
import { SingletonEntityReducer } from './SingletonEntityReducer';
import { initialize, objectHasChanged, initializeActionTarget } from './utils';

export class SchemaReducer {
  constructor(schema, initialState = {}) {
    this._schema = schema;

    this._reducers = schema.updateSequence.map(({ entity, children, path }) =>
      getReducer(entity, path)
    );

    this._state = initialize(schema, initialState);
    this.dispatch({ type: initializeActionTarget });
  }

  dispatch(action) {
    const dispatchSingle = this.dispatchSingle.bind(this);

    if (action.type === initializeActionTarget) {
      dispatchSingle(action);
    } else {
      const actions = decomposeAction(this._schema, action);

      // TODO: optimize this. The decomposition should progress from deep to shallow,
      // so we can dispatch to only the necessary reducers

      actions.forEach(dispatchSingle);
    }
  }

  dispatchSingle(action) {
    // update entities
    const newState = this._reducers.reduce(
      (acc, reducer) => {
        const key = reducer.key;
        acc[key] = reducer.reduce(acc[key], acc, action);
        return acc;
      },
      { ...this._state }
    );

    if (objectHasChanged(this._state, newState)) {
      this._state = newState;
    }

    // let newState = this._state;
    // this._schema.updateSequence.forEach((schemaNode) => {
    //   newState = reduce(newState, schemaNode, action);
    // });

    // // update state, passing in updated entities
    // this._state = newState;
  }

  getState() {
    return this._state[this._schema.rootEntity.key];
  }
}

// function arraysEqual(a, b) {
//   if (a === b) return true;
//   if (a == null || b == null) return false;
//   if (a.length !== b.length) return false;

//   // If you don't care about the order of the elements inside
//   // the array, you should sort both arrays here.
//   // Please note that calling sort on an array will modify that array.
//   // you might want to clone your array first.

//   for (var i = 0; i < a.length; ++i) {
//     if (a[i] !== b[i]) return false;
//   }
//   return true;
// }

// function exclusiveArrayReducer(state, action, getEntities) {
//   const newState = getEntities().sort((x) => x.position);

//   if (newState.length !== state.length || arraysEqual(a, b)) {
//   }
// }

function getReducer(schemaItem, path) {
  switch (true) {
    case schemaItem instanceof SchemaEntity:
      return new EntityReducer(schemaItem, path);

    case schemaItem instanceof SchemaSingletonEntity:
      return new SingletonEntityReducer(schemaItem, path);

    case schemaItem instanceof SchemaExclusiveArray:
      return new ExclusiveArrayReducer(schemaItem, path);

    case schemaItem instanceof SchemaSet:
      return new SetReducer(schemaItem, path);

    default:
      return undefined;
  }
}

function decomposeAction(schema, action) {
  if (!['add', 'patch', 'addOrPatch'].includes(action.type)) {
    return [action];
  }

  // find the right schema item based on the action target
  const schemaItem = schema.findItemByKey(action.target);
  const actionBody = getActionBody(action);
  const actions = decomposeActionForSchemaItem(
    schemaItem,
    actionBody,
    action.type,
    [],
    action
  );

  return actions;
}

function decomposeActionForSchemaItem(
  schemaItem,
  actionBody,
  actionType,
  actionSet,
  actionProps = {}
) {
  if (!actionBody) {
    return actionSet;
  }

  switch (true) {
    case schemaItem instanceof SchemaEntity:
      return decomposeActionForEntity(
        schemaItem,
        actionBody,
        actionType,
        actionSet,
        actionProps
      );

    case schemaItem instanceof SchemaSingletonEntity:
      return decomposeActionForSingletonEntity(
        schemaItem,
        actionBody,
        actionType,
        actionSet,
        actionProps
      );

    case schemaItem instanceof SchemaExclusiveArray:
      return decomposeActionForExclusiveArray(
        schemaItem,
        actionBody,
        actionType,
        actionSet,
        actionProps
      );

    case schemaItem instanceof SchemaSet:
      return decomposeActionForSet(
        schemaItem,
        actionBody,
        actionType,
        actionSet,
        actionProps
      );

    default:
      return undefined;
  }
}

function decomposeActionForEntity(
  entity,
  actionBody,
  actionType,
  actionSet,
  actionProps = {}
) {
  const newActionBody = { ...actionBody };
  // return actionSet.filter((action) => action.target === entity.key);

  if (typeof entity?.definition === 'object') {
    actionSet = Object.entries(entity.definition).reduce(
      (acc, [key, schemaItem]) => {
        if (newActionBody[key]) {
          acc = decomposeActionForSchemaItem(
            schemaItem,
            newActionBody[key],
            'addOrPatch',
            acc
          );
          delete newActionBody[key];
        }
        return acc;
      },
      actionSet
    );
  }

  return [
    ...actionSet,
    composeAction(newActionBody, actionType, entity, actionProps),
  ];
}

function decomposeActionForSingletonEntity(
  singletonEntity,
  actionBody,
  actionType,
  actionSet,
  actionProps = {}
) {
  const newActionBody = { ...actionBody };

  if (typeof singletonEntity?.definition === 'object') {
    // TODO: convert this to a mutli-update
    actionSet = Object.entries(singletonEntity.definition).reduce(
      (acc, [key, schemaItem]) => {
        if (newActionBody[key]) {
          acc = decomposeActionForSchemaItem(
            schemaItem,
            newActionBody[key],
            'addOrPatch',
            acc
          );
          delete newActionBody[key];
        }
        return acc;
      },
      actionSet
    );
  }

  return [
    ...actionSet,
    composeAction(newActionBody, actionType, singletonEntity, actionProps),
  ];
}

function decomposeActionForExclusiveArray(
  exclusiveArray,
  actionBody,
  _actionType,
  actionSet
) {
  const schemaEntity = exclusiveArray.entity;

  actionSet = actionBody.reduce(
    (acc, item) =>
      decomposeActionForEntity(schemaEntity, item, 'addOrPatch', acc),
    actionSet
  );

  return actionSet;
}

function decomposeActionForSet(set, actionBody, actionType, actionSet) {
  // TODO: implement
  const entity = set.entity;

  if (typeof actionBody !== 'object') {
    return [
      ...actionSet,
      {
        type: 'add',
        entityIds: actionBody,
      },
    ];
  }

  const { ids, actionSet: reducedActionSet } = Object.entries(
    actionBody
  ).reduce(
    (acc, [key, item]) => {
      return {
        ids: [...acc.ids, key],
        actionSet: decomposeActionForEntity(
          entity,
          item,
          'addOrPatch',
          acc.actionSet,
          { id: key }
        ),
      };
    },
    { ids: [], actionSet }
  );

  // const entityIds = Object.entries(actionBody).map(([key, _item]) => key);
  return [
    ...reducedActionSet,
    {
      type: actionType,
      target: set.key,
      entityIds: ids,
    },
  ];
}

function getActionBody(action) {
  let body = undefined;

  switch (true) {
    case action.type === 'patch':
    case action.type === 'addOrPatch':
      body = action.patch;
      break;

    case action.type === 'add':
      body = action.entity;
      break;
  }

  return body;
}

function setActionBody(action, body) {
  switch (true) {
    case action.type === 'patch':
    case action.type === 'addOrPatch':
      return {
        ...action,
        patch: body,
      };

    case action.type === 'add':
      return {
        ...action,
        entity: body,
      };
  }

  return action;
}

function composeAction(body, type, schemaItem, actionProps = {}) {
  switch (true) {
    case type === 'add':
      return composeAddAction(body, actionProps);

    case type === 'patch':
      return composePatchTypeAction(body, schemaItem, actionProps, 'patch');

    case type === 'addOrPatch':
      return composePatchTypeAction(
        body,
        schemaItem,
        actionProps,
        'addOrPatch'
      );
  }

  return undefined;
}

function composeAddAction(body, actionProps = {}) {
  return {
    ...actionProps,
    type: 'add',
    entity: body,
  };
}

function composePatchTypeAction(
  body,
  schemaItem,
  actionProps = {},
  type = 'patch'
) {
  const id = actionProps?.id ?? schemaItem.getId(body);

  return {
    type,
    target: schemaItem.key,
    id,
    patch: body,
  };
}
