import * as React from 'react';
import PropTypes from 'prop-types';
import Answer from './Answer';
import _ from 'lodash';

const Question = ({ question: { id, text, answers }, response, onRespond }) => {
  // only support a single response right now
  const handleAnswerSelect = (a) => {
    onRespond(id, a.id, a.value);
  };

  return (
    <div className="question">
      <div className="question-text">{text}</div>
      <div className="question-answers">
        <ul className="row list-unstyled">
          {_.sortBy(answers, ['ordinal', 'id']).map((a) => (
            <li key={a.id} className="col-12">
              <Answer
                namespace={id}
                text={a.text}
                selected={!!response && response.answer_id === a.id}
                onSelect={() => handleAnswerSelect(a)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

Question.propTypes = {
  responses: PropTypes.arrayOf(PropTypes.object),
  onRespond: PropTypes.func,
};

Question.defaultProps = {
  responses: [],
  onRespond: () => {},
};

export default Question;
