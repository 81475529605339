import React from 'react';
import { connect } from 'react-redux';
import { create as createAssessmentTemplate } from 'js/actions/assessment-template-actions';
import { useSelector } from 'react-redux';
import { CURRENT_DOC_FORMAT_VERSION } from 'js/components/Assessment/Editor/Binding';
import { useHistory } from 'react-router-dom';
import { merge } from 'lodash';
import AssessmentCreatorComponent from '../components/AssessmentCreatorComponent';

const CreateAssessment = ({ createAssessmentTemplate }) => {
  const currentWorkspaceId = useSelector((state) => state.currentWorkspaceId);
  const history = useHistory();

  function buildInitialState(overrides = null) {
    return merge(
      {
        meta: {
          type: 'survey',
          configurable: true,
          name: 'New Assessment',
          description:
            'Evaluate key skills and competencies to identify strengths and areas for growth in your team.',
          isPublic: false,
          doc_format_version: CURRENT_DOC_FORMAT_VERSION,
        },
        assessment: {},
        pages: [],
        answerSets: {},
        config: {
          version: '1.0',
          categories: {},
        },
        views: {},
      },
      overrides
    );
  }

  const handleSubmit = async (values) => {
    return createAssessmentTemplate(values, buildInitialState({ meta: values }))
      .then((res) => {
        console.log('res:', res);
        history.push(`/w/${currentWorkspaceId}/assessments/${res.id}/edit`);
      })
      .catch((e) => {
        console.error('Failed to create assessment template:', e);
      });
  };

  return <AssessmentCreatorComponent onCreate={handleSubmit} />;
};

export default connect(null, {
  createAssessmentTemplate,
})(CreateAssessment);
