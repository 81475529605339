import React, { useMemo } from 'react';
import styles from '../../../Editor.module.scss';
import toolStyles from '../AnswerSet.module.scss';

export default function DropIndicator({ left, right }) {
  const classNames = useMemo(() => {
    const classNames = [styles.dropInd, toolStyles['drop-indicator']];
    if (left) {
      classNames.push(toolStyles['drop-indicator-left']);
    }
    if (right) {
      classNames.push(toolStyles['drop-indicator-right']);
    }

    return classNames;
  }, [left, right]);

  return <div className={classNames.join(' ')}></div>;
}
