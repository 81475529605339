import React, { useMemo, useState, useLayoutEffect } from 'react';
import classNames from 'classnames';

import { isNullOrWhitespace } from 'js/utils/string';
import useEditorInput from '../../useEditorInput';

import Markdown from 'react-markdown';
import TextareaAutosize from 'react-textarea-autosize';

function MarkdownInput({
  value,
  placeholder,
  minRows = 3,
  maxRows = 12,
  onCommit,
  className,
  invalid = false,
  feedback = null,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const handleCommit = (val) => {
    setIsEditing(false);
    onCommit(val);
  };

  const stopEditing = () => {
    setIsEditing(false);
  };

  const {
    reset,
    ref: textareaRef,
    ...inputProps
  } = useEditorInput({
    value: value ?? '',
    multiline: true,
    onCommit: handleCommit,
    onBlur: stopEditing,
    onCancel: stopEditing,
  });

  useLayoutEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isEditing, textareaRef]);

  const usePlaceholder = useMemo(() => isNullOrWhitespace(value), [value]);

  return (
    <div
      className={classNames(className, 'markdown-input', {
        'is-invalid': invalid,
      })}
    >
      {isEditing ? (
        <TextareaAutosize
          className={classNames('form-control autosize', {
            'is-invalid': invalid,
          })}
          minRows={minRows}
          maxRows={maxRows}
          {...inputProps}
          ref={(tag) => {
            textareaRef.current = tag;
          }}
        />
      ) : (
        <div className="markdown-input-container">
          {usePlaceholder ? (
            <div className="markdown-input-placeholder text-muted">
              {placeholder}
            </div>
          ) : (
            <div className="markdown-input-markdown">
              <Markdown>{value}</Markdown>
            </div>
          )}
          <div
            onClick={() => setIsEditing(true)}
            className="markdown-overlay-button"
            role="button"
          ></div>
        </div>
      )}
      {feedback}
    </div>
  );
}

export default MarkdownInput;
