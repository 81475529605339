import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import EditorInput from '../../../EditorInput/EditorInput';
import { Icon } from 'js/components';

import { faX } from '@fortawesome/pro-regular-svg-icons';

import styles from '../AnswerSet.module.scss';
import DraggableChoice from './DraggableChoice';

function ValueChoice({
  choice,
  index,
  answerSetId,
  questionId,
  onUpdate = () => {},
  onDelete = () => {},
  type = 'checkbox',
}) {
  const handleUpdateChoiceText = useCallback(
    (e) => {
      const newValue = e.target.value.trim();
      if (choice.text === newValue && choice.value === newValue) return;

      onUpdate(choice.id, { text: newValue, value: newValue });
    },
    [choice.id, choice.text, choice.value, onUpdate]
  );

  const Actions = useMemo(
    () => () => {
      return (
        <button
          className="btn-inline-action btn-danger"
          onClick={() => onDelete(choice.id)}
        >
          <Icon icon={faX} size="sm" />
        </button>
      );
    },
    [onDelete, choice]
  );

  return (
    <DraggableChoice
      choice={choice}
      index={index}
      answerSetId={answerSetId}
      questionId={questionId}
      actions={Actions}
    >
      <div
        className={classNames(styles['choice-value-marker'], {
          [styles[type]]: type != null,
        })}
      ></div>
      <div className={styles['choice-text']}>
        <EditorInput
          autoselect
          forceCommitOnEnter
          multiLine
          rows={2}
          value={choice.text}
          onBlur={handleUpdateChoiceText}
        />
      </div>
    </DraggableChoice>
  );
}

export default ValueChoice;
