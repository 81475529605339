import React, { useMemo } from 'react';

import { LikertAnswerSet } from './LikertAnswerSet/LikertAnswerSet';
import { ValueListAnswerSet } from './ValueListAnswerSet/ValueListAnswerSet';

export function AnswerSet({ answerSet, ...props }) {
  const AnswerSetComponent = useMemo(() => {
    switch (answerSet.type) {
      case 'value-list':
        return ValueListAnswerSet;
      case 'likert':
      default:
        return LikertAnswerSet;
    }
  }, [answerSet.type]);

  return <AnswerSetComponent answerSet={answerSet} {...props} />;
}
