import {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';

export function useEditorInput({
  value,
  name,
  placeholder,
  list,
  multiline = false,
  forceCommitOnEnter = false,

  commitOnChange = false,
  holdChangeTimeout = 50,

  onCommit = () => {},
  onChange: handleChange = () => {},
  onBlur: handleBlur = () => {},
  onFocus: handleFocus = () => {},
  onCancel = () => {},
  onEnter = () => {},
  collaborator,
  convertToNumber = false,
  defaultNumber = 0,
  ref,
}) {
  const [val, setVal] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const cursorRef = useRef(null);
  const [cursor, setCursor] = useState(null);

  useEffect(() => {
    if (!isEditing) {
      setVal(value);
    }
  }, [value, isEditing]);

  useEffect(() => {
    if (!isEditing && val !== value) {
      setVal(value);
    }
  }, [isEditing, val, value]);

  if (ref && ref.current !== cursorRef.current) {
    ref.current = cursorRef.current;
  }

  const onFocus = (e) => {
    setIsEditing(true);
    handleFocus(e);
  };

  const onBlur = useCallback(
    (e) => {
      let commitValue = val;

      if (convertToNumber) {
        let number = parseFloat(e.target.value);
        if (isNaN(number)) {
          number = defaultNumber;
        }
        commitValue = '' + number;
        setVal(commitValue);
        e.target.value = commitValue;
      }

      if (handleBlur) {
        handleBlur(e);
      }
      if (commitValue !== value) {
        onCommit(commitValue);
      }
      setTimeout(() => {
        setIsEditing(false);
      }, holdChangeTimeout);
    },
    [
      handleBlur,
      onCommit,
      val,
      value,
      convertToNumber,
      defaultNumber,
      holdChangeTimeout,
    ]
  );

  const onChange = useCallback(
    (e) => {
      setVal(e.target.value);
      setCursor(e.target.selectionStart);
      if (handleChange) {
        handleChange(e);
      }
      if (commitOnChange && value !== e.target.value) {
        onCommit(e.target.value);
      }
    },
    [setVal, setCursor, handleChange, commitOnChange, value, onCommit]
  );

  const onKeyDown = (e) => {
    if (
      e.key === 'Enter' &&
      (forceCommitOnEnter || !multiline || e.metaKey || e.ctrlKey)
    ) {
      onBlur(e);
      onEnter(e.target.value);
    } else if (e.key === 'Escape') {
      setVal(value);
      onCancel();
    }
  };

  useLayoutEffect(() => {
    const input = cursorRef.current;
    if (input && input.setSelectionRange) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [cursorRef, cursor, val]);

  return {
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
    value: val,
    placeholder,
    name,
    ref: cursorRef,
    reset: () => setVal(value),
  };
}

export default useEditorInput;
