import React, { useRef } from 'react';
import classNames from 'classnames';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { withFormik } from 'formik';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import ReactMarkdown from 'react-markdown';

import styles from './AssessmentEditorComponent.module.scss';

const placeholderOptions = [
  {
    name: 'e.g. Team Skills Assessment',
    description:
      'e.g. Measure and track key competencies across your organization',
  },
  {
    name: 'e.g. Leadership Development Survey',
    description:
      'e.g. Evaluate management capabilities and identify growth opportunities',
  },
  {
    name: 'e.g. Project Readiness Check',
    description:
      'e.g. Assess team preparedness and capability gaps for upcoming initiatives',
  },
  {
    name: 'e.g. Professional Growth Tracker',
    description:
      'e.g. Monitor progress on core skills and professional development goals',
  },
];

const CreateAssessmentForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
}) => {
  const placeholderRef = useRef(null);
  if (placeholderRef.current === null) {
    placeholderRef.current =
      placeholderOptions[Math.floor(Math.random() * placeholderOptions.length)];
  }
  const placeholders = placeholderRef.current;

  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: errors[fieldName] && touched[fieldName],
    placeholder: placeholders[fieldName],
  });

  const formikFeedback = (fieldName) => {
    return errors[fieldName] && touched[fieldName] ? (
      <FormFeedback>{errors[fieldName]}</FormFeedback>
    ) : null;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm={4}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input type="text" {...formikAttributes('name')} autoFocus />
            {formikFeedback('name')}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <FormGroup>
            <Label for="description">
              Description (supports{' '}
              <a
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
                rel="noreferrer"
              >
                markdown
              </a>
              )
            </Label>
            <ReactTextareaAutosize
              style={{ maxHeight: 'unset' }}
              className={classNames('form-control', {
                'is-invalid': errors['description'] && touched['description'],
              })}
              minRows={4}
              {...formikAttributes('description')}
            />
            {formikFeedback('description')}
          </FormGroup>
        </Col>
        <Col sm={12} lg={6}>
          <Label for="description">Description Preview</Label>
          <ReactMarkdown className={styles.preview}>
            {values.description}
          </ReactMarkdown>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button color="primary" type="submit" className="float-right">
            Create Assessment
          </Button>
          <Button
            color="secondary"
            className="float-right mr-2"
            onClick={() => window.history.back()}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const FormikCreateAssessmentForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    description: '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Give your assessment a good name so people can identify it.')
      .max(
        250,
        'Your name is too long. Short and sweet is best (under 250 characters).'
      ),
    description: Yup.string()
      .trim()
      .required(
        'You need to provide a description so people can understand what this assessment is about.'
      ),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    const { onSubmit = () => {} } = props;

    setSubmitting(true);
    onSubmit(values).finally(() => {
      setSubmitting(false);
    });
  },
  displayName: 'CreateAssessmentForm',
})(CreateAssessmentForm);

export default FormikCreateAssessmentForm;
