import React from 'react';
import BaseChoice from '../BaseChoice';
import LikertChoiceCustom from './LikertChoiceCustom';
import LikertChoiceStandard from './LikertChoiceStandard';

function LikertChoice({ ...props }) {
  return (
    <BaseChoice
      {...props}
      customChoiceComponent={LikertChoiceCustom}
      standardChoiceComponent={LikertChoiceStandard}
    />
  );
}

export default LikertChoice;
