import React from 'react';

import DraggableChoice from '../DraggableChoice';

import useEditorInput from '../../../useEditorInput';

import styles from '../AnswerSet.module.scss';
import { standardColor } from 'js/utils/color';
const scoreColor = standardColor();

export function ValueListChoiceCustom({
  choice,
  color,
  onDelete = () => {},
  onUpdate = () => {},
  index,
  answerSetId,
}) {
  const handleTextChange = (newText) => {
    const trimmedText = newText.trim();

    onUpdate(choice, { text: trimmedText, value: trimmedText });
  };

  const { reset: resetText, ...choiceTextInputProps } = useEditorInput({
    value: choice.text,
    onCommit: handleTextChange,
  });

  return (
    <DraggableChoice
      choice={choice}
      index={index}
      answerSetId={answerSetId}
      onDelete={onDelete}
    >
      <td className={styles['score-cell'] + ' ' + styles['score-bar-only']}>
        <span
          className={styles['score-bar']}
          style={{ backgroundColor: scoreColor }}
        ></span>
      </td>
      <td>
        <input
          type="text"
          className={styles['answer-text']}
          {...choiceTextInputProps}
        />
      </td>
    </DraggableChoice>
  );
}

export default ValueListChoiceCustom;
