import React, { useState } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { update as updateTemplate } from 'js/actions/assessment-template-actions';
import { update } from '../../Binding/meta-actions';

import { Nav, NavItem, NavLink } from 'reactstrap';

import { useDoc } from 'js/ydoc';

import EditorTool from '../EditorTool';

import toolStyles from '../EditorTool.module.scss';
import Metadata from './Metadata';
import Revisions from './Revisions';

const Submodules = {
  METADATA: 'METADATA',
  HISTORY: 'HISTORY',
};

export function MetadataAndHistoryTool({
  docTemplateId,
  assessment,
  revisions,
  revisionsQueryIsLoading,
}) {
  const doc = useDoc();
  const dispatch = useDispatch();
  const store = useStore();

  const [activeSubmodule, setActiveSubmodule] = useState(Submodules.METADATA);
  const handleChange = (updates) => {
    updateTemplate(docTemplateId, updates)(dispatch, store);
    update(doc, updates);
  };

  return (
    <EditorTool title="General" collapsable defaultOpen>
      <Nav className="tab-nav nav-underline">
        <NavItem active={activeSubmodule === Submodules.METADATA}>
          <NavLink
            role="button"
            onClick={() => setActiveSubmodule(Submodules.METADATA)}
          >
            Settings
          </NavLink>
        </NavItem>
        <NavItem active={activeSubmodule === Submodules.HISTORY}>
          <NavLink
            role="button"
            onClick={() => setActiveSubmodule(Submodules.HISTORY)}
          >
            Revisions
          </NavLink>
        </NavItem>
      </Nav>
      <div className={toolStyles['tool-body']}>
        {activeSubmodule === Submodules.HISTORY ? (
          <Revisions
            revisions={revisions}
            isLoading={revisionsQueryIsLoading}
          />
        ) : (
          <Metadata assessment={assessment} onChange={handleChange} />
        )}
      </div>
    </EditorTool>
  );
}

export default MetadataAndHistoryTool;
