import React from 'react';
import { Icon } from 'js/components';
import styles from './MinimalLayout.module.scss';
import ProfileDropdown from 'js/components/Header/ProfileDropdown';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function MinimalLayout({
  component: Component,
  children,
  ...componentProps
}) {
  const me = useSelector((state) => state.identity.me);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Link
            to="/dashboard"
            className={`simple-text logo-mini ${styles.icon}`}
          >
            <Icon icon={['fas', 'map-marker-alt']} />
          </Link>{' '}
          <Link to="/dashboard" className="simple-text logo-normal">
            <div className={styles.title}>Waypointr</div>
          </Link>
        </div>
        <ProfileDropdown
          profile={me}
          onLogout={() => {}}
          toggleClass={styles.profileDropdownToggle}
        />
      </div>
      <div className={styles.content}>
        {Component ? <Component {...componentProps} /> : null}
        {children}
      </div>
    </div>
  );
}
