import { getAttributeGetter } from './utils';
import { SchemaItem } from './SchemaItem';
import { isNil, mapValues, isEqual } from 'lodash';
import { titleCase } from 'js/utils/string';

export class SchemaEntity extends SchemaItem {
  constructor(key, definition = {}, options = {}) {
    super();

    this.key = key;
    this.definition = definition;
    this.idAttribute = options.idAttribute ?? 'id';
    this.positionAttribute = options.positionAttribute ?? 'position';
    this.idField =
      options.idField ?? key.replace(/s$/, '') + titleCase(this.idAttribute);

    this._getId =
      typeof this.idAttribute === 'function'
        ? this.idAttribute
        : getAttributeGetter(this.idAttribute);

    this._getPositionState = getAttributeGetter(this.positionAttribute);
  }

  getId(entityState) {
    return this._getId(entityState);
  }

  getPositionState(entityState) {
    return this._getPositionState(entityState);
  }

  getPositionKey(entityState) {
    return this.getId(entityState);
  }

  updatePosition(entityState, position) {
    if (isNil(position)) {
      if (!isNil(this.getPositionState(entityState))) {
        const newState = { ...entityState };
        delete newState[this.positionAttribute];

        return newState;
      }

      return entityState;
    }

    const currentPosition = entityState[this.positionAttribute];
    const patchedPositions = mapValues(position, (positionPatch, key) => ({
      ...currentPosition?.[key],
      ...positionPatch,
    }));
    const newPosition = { ...currentPosition, ...patchedPositions };

    return isEqual(currentPosition, newPosition)
      ? entityState
      : {
          ...entityState,
          [this.positionAttribute]: newPosition,
        };
  }
}
