import React from 'react';

export function BaseChoice({
  isStandard,
  standardChoiceComponent: StandardChoice,
  customChoiceComponent: CustomChoice,
  ...props
}) {
  return isStandard ? (
    <StandardChoice {...props} />
  ) : (
    <CustomChoice {...props} />
  );
}

export default BaseChoice;
