import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import CollaboratorFocusContainer from 'js/components/CollaboratorList/CollaboratorFocusContainer';
import { useMyCollaborator } from 'js/components/Assessment/Editor/collaborator-hooks';
import { FormFeedback } from 'reactstrap';
import styles from './EditorInput.module.scss';

export default function EditorInput({
  value,
  name,
  placeholder,
  onChange,
  list,
  multiLine = false,
  forceCommitOnEnter = false,
  onBlur,
  children,
  onFocus,
  collaborator,
  collaboratorStyle,
  buttonStyle,
  inputStyle,
  initiallyEditing,
  autoselect = false,
  convertToNumber = false,
  defaultNumber = 0,
  error = null,
  className,
  ...props
}) {
  const [val, setVal] = useState(value);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const myCollaborator = useMyCollaborator();

  const Tag = useMemo(() => {
    return multiLine ? 'textarea' : 'input';
  }, [multiLine]);

  const startEdit = useCallback(() => {
    setEditing(true);
    setTimeout(() => {
      inputRef.current.focus();
      const length = inputRef.current?.value?.length ?? 0;
      const selectionStart = autoselect ? 0 : length;
      inputRef.current.setSelectionRange(selectionStart, length);
    }, 1);
  }, [autoselect]);

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    if (editing && collaborator) {
      if (collaborator.key > myCollaborator.key) {
        setEditing(false);
      }
    }
  }, [editing, collaborator, myCollaborator]);

  useEffect(() => {
    if (collaborator && collaborator.key === myCollaborator.key && !editing) {
      startEdit();
    }
  }, [collaborator, myCollaborator, editing, startEdit]);

  const handleChange = useCallback(
    (e) => {
      setVal(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (initiallyEditing) {
      startEdit();
    }
  }, [initiallyEditing, startEdit]);

  const handleBlur = useCallback(
    (e) => {
      setEditing(false);
      if (convertToNumber) {
        let number = parseFloat(e.target.value);
        if (isNaN(number)) {
          number = defaultNumber;
        }
        const newVal = '' + number;
        setVal(newVal);
        e.target.value = newVal;
      }

      onBlur && onBlur(e);
    },
    [onBlur, convertToNumber, defaultNumber]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (
        e.key === 'Enter' &&
        (forceCommitOnEnter || (multiLine && (e.metaKey || e.ctrlKey)))
      ) {
        e.preventDefault();
        handleBlur(e);
      }
    },
    [multiLine, forceCommitOnEnter, handleBlur]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (
        ((!multiLine || forceCommitOnEnter) && e.key === 'Enter') ||
        e.key === 'Escape'
      ) {
        handleBlur(e);
      }
    },
    [multiLine, forceCommitOnEnter, handleBlur]
  );

  const wrapperClassName = useMemo(
    () =>
      classNames(className, 'editor-input', {
        'is-invalid': error !== null,
      }),
    [className, error]
  );

  const divClassName = useMemo(
    () =>
      classNames(styles.notEditing, 'editor-input-button-text', {
        [styles.empty]: val === '',
        [styles.multiLine]: multiLine,
      }),
    [val, multiLine]
  );

  const inputClassName = useMemo(
    () => classNames('form-control', { 'is-invalid': error !== null }),
    [error]
  );

  return (
    <>
      <div className={wrapperClassName}>
        {editing ? (
          <>
            <Tag
              {...props}
              className={inputClassName}
              ref={inputRef}
              name={name}
              type="text"
              placeholder={placeholder}
              value={val}
              list={list}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyUp={handleKeyPress}
              onKeyDown={handleKeyDown}
              onFocus={(e) => onFocus && onFocus(e)}
              style={inputStyle}
            />
            {error && <FormFeedback valid={false}>{error}</FormFeedback>}
          </>
        ) : (
          <>
            <CollaboratorFocusContainer
              collaborator={collaborator}
              style={collaboratorStyle}
            >
              <button
                onClick={startEdit}
                disabled={!!collaborator}
                style={buttonStyle}
              >
                {children || (
                  <div className={divClassName}>{val || placeholder}</div>
                )}
              </button>
            </CollaboratorFocusContainer>
            {error && <FormFeedback valid={false}>{error}</FormFeedback>}
          </>
        )}
      </div>
    </>
  );
}
