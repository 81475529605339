import React, { useState, useContext, useMemo } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { AssessmentContext } from 'js/components/Assessment/AssessmentContext';

// import { faList } from '@fortawesome/pro-regular-svg-icons';
import { useStandardCollator } from 'js/utils/string';
// TODO: move this somewhere common
import standardAnswerSets from '../../EditorTools/AnswerSetTool/standard-answer-sets';
import { isValidAnswerSetTypeForQuestion } from '../../answer-set-utils';

// const typeDetails = {
//   likert: {
//     name: 'Custom',
//     icon: faList,
//   },
//   // 'unscored-likert': {
//   //   name: 'Unscored',
//   //   icon: falList,
//   // },
//   // 'long-text': {
//   //   name: 'Comment',
//   //   icon: faMessageLines,
//   // },
// };
// const types = Object.entries(typeDetails).sort(([a], [b]) =>
//   a.localeCompare(b)
// );

// TODO: make this a combobox
export function AnswerSetSelector({
  questionType,
  currentAnswerSet,
  onChange = () => {},
}) {
  const collator = useStandardCollator();
  const [isOpen, setIsOpen] = useState(false);
  // const td = typeDetails[type] ?? typeDetails['likert'];
  // const { name: selectedName, icon: selectedIcon } = td;

  const assessment = useContext(AssessmentContext);
  const { answerSetList } = useMemo(() => {
    const answerSetMap = {
      ...Object.fromEntries(standardAnswerSets.map((set) => [set.id, set])),
      ...assessment?.answerSets,
    };

    const answerSetList = Object.values(answerSetMap)
      .filter(
        (x) =>
          x.isPublished && isValidAnswerSetTypeForQuestion(questionType, x.type)
      )
      .sort((a, b) => collator.compare(a.name, b.name));

    return { answerSetMap, answerSetList };
  }, [assessment, questionType, collator]);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className="action-selector answer-set-selector"
    >
      <DropdownToggle caret>
        {currentAnswerSet?.isPublished ? (
          <div>{currentAnswerSet.name}</div>
        ) : (
          <div>[Custom]</div>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => onChange(null)}>[Custom]</DropdownItem>
        {answerSetList.map((answerSet) => (
          <DropdownItem key={answerSet.id} onClick={() => onChange(answerSet)}>
            {answerSet.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default AnswerSetSelector;
