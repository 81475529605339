import { UncontrolledTooltip } from 'reactstrap';
import React, { useMemo, useState } from 'react';
import styles from './CollaboratorList.module.scss';
import { uuidv4 } from 'lib0/random';

export default function Collaborator({ collaborator, small = false }) {
  const [key] = useState(uuidv4());

  const initials = useMemo(() => {
    if (collaborator.display_name) {
      const names = collaborator.display_name.split(' ');
      return `${names[0][0].toUpperCase()}${
        names.length > 1 ? names[1][0].toUpperCase() : ''
      }`;
    } else if (collaborator.username) {
      return collaborator.username[0].toUpperCase();
    }
  }, [collaborator]);

  const classNameForCollaborator = useMemo(() => {
    const classes = [
      styles.Initials,
      `palette-cat-${collaborator.colorIndex + 1}`,
    ];
    if (small) {
      classes.push(styles.small);
    }
    return classes.join(' ');
  }, [collaborator, small]);

  return (
    <React.Fragment>
      <div id={`collab-${key}`} className={classNameForCollaborator}>
        {initials}
      </div>
      <UncontrolledTooltip placement="bottom" target={`collab-${key}`}>
        <div className={styles.tip}>
          {collaborator.display_name && <div>{collaborator.display_name}</div>}
          <div>{collaborator.username}</div>
        </div>
      </UncontrolledTooltip>
    </React.Fragment>
  );
}
