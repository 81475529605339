import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from 'js/components';

import React from 'react';

function ErrorMessage({ children }) {
  return (
    <>
      <Icon
        size="sm"
        icon={faExclamationTriangle}
        className="icon icon-left text-danger"
      />
      {children}
    </>
  );
}

export default ErrorMessage;
