import React from 'react';

import { PanelHeader } from 'js/components';

import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HeroBadge, Icon, IconStack } from 'js/components';

const AssessmentComplete = ({ participant, onClearSession, isPreview }) => {
  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <div className="assessment survey-assessment-review">
          <div className="all-answered">
            <Card>
              <CardBody>
                <HeroBadge
                  renderIcon={() => (
                    <IconStack className="complete-icon">
                      <Icon icon={['fas', 'circle']} />
                      <Icon
                        icon={['fal', 'clipboard-check']}
                        inverse
                        transform="shrink-6"
                      />
                    </IconStack>
                  )}
                  title="Thanks!"
                >
                  <p>
                    Thanks for carving time out of your day to give us your
                    input. We now return you to your regularly scheduled
                    program. Bye!
                  </p>
                  {false && participant.user_id !== null ? null : (
                    <div className="action-items">
                      <Link
                        to={isPreview ? './' : '/'}
                        className="btn btn-primary"
                        onClick={onClearSession}
                      >
                        <Icon
                          className="btn-icon-left"
                          icon={['fas', 'flux-capacitor']}
                        />
                        {isPreview
                          ? 'Preview the survey again'
                          : 'Clear my session'}
                      </Link>
                    </div>
                  )}
                </HeroBadge>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

AssessmentComplete.defaultProps = {
  onClearSession: () => {},
};

export default AssessmentComplete;
