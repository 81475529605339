import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector, useStore } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { denormalize } from 'normalizr';
import { update as updateAssessmentTemplate } from 'js/actions/assessment-template-actions';
import { list as listAssessmentDocTemplateSummaries } from 'js/actions/assessment-doc-template-summary-actions';
import AssessmentsDashboardComponent from '../components/AssessmentDashboardComponent/AssessmentsDashboardComponent';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';
import { useNotify } from 'js/hooks/useNotify';
import { SCHEMAS } from 'js/api/schemas';

function templateSummariesQueryId(workspaceId) {
  return `${workspaceId}-assessment-doc-template-summaries`;
}

function AssessmentManagement({
  listAssessmentDocTemplateSummaries,
  updateAssessmentTemplate,
  workspace,
}) {
  const { workspaceId } = useParams();
  const notify = useNotify();

  const store = useStore();

  const summariesQueryId = templateSummariesQueryId(workspaceId);
  const summariesQuery = useSelector(
    (state) => state.queries[summariesQueryId]
  );

  const refreshSummaries = useCallback(
    () => listAssessmentDocTemplateSummaries(workspaceId, summariesQueryId),
    [listAssessmentDocTemplateSummaries, workspaceId, summariesQueryId]
  );

  const [summaries, setSummaries] = useState(null);
  useEffect(() => {
    if (summariesQuery?.isLoading === false) {
      setSummaries(
        denormalize(
          summariesQuery?.result || [],
          SCHEMAS.ASSESSMENT_DOC_TEMPLATE_SUMMARY_ARRAY,
          store.getState().entities
        )
      );
    }
  }, [
    store,
    summariesQuery,
    summariesQuery?.isLoading,
    summariesQuery?.result,
  ]);

  useEffect(() => {
    refreshSummaries();
  }, [refreshSummaries]);

  const handleArchiveTemplate = useCallback(
    (templateId) => {
      return updateAssessmentTemplate(templateId, {
        archived_state: 'archived',
        archived_at: new Date(),
      }).then((res) =>
        refreshSummaries().then(() => {
          notify({
            type: 'success',
            message: 'Template archived successfully',
          });

          return res;
        })
      );
    },
    [updateAssessmentTemplate, notify, refreshSummaries]
  );

  const handleRestoreTemplate = useCallback(
    (templateId) => {
      return updateAssessmentTemplate(templateId, {
        archived_state: null,
      }).then((res) =>
        refreshSummaries().then(() => {
          notify({
            type: 'success',
            message: 'Template restored successfully',
          });

          return res;
        })
      );
    },
    [updateAssessmentTemplate, notify, refreshSummaries]
  );

  return (
    <AssessmentsDashboardComponent
      summaries={summaries}
      summariesQuery={summariesQuery}
      workspace={workspace}
      onArchiveTemplate={handleArchiveTemplate}
      onRestoreTemplate={handleRestoreTemplate}
    />
  );
}

export default withRouter(
  connect(
    (state) => {
      const workspace = selectActiveWorkspace(state);

      return {
        workspace,
      };
    },
    { listAssessmentDocTemplateSummaries, updateAssessmentTemplate }
  )(AssessmentManagement)
);
