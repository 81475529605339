import React from 'react';

const scoreDotRadius = 3;
const scoreDotSpacing = 2;
const scoreDotIncrement = scoreDotRadius * 2 + scoreDotSpacing;

export function LikertSparkline({ choices, color }) {
  return (
    <svg
      height={16}
      width={choices.length * scoreDotIncrement - scoreDotSpacing}
      viewBox={`0 0 ${choices.length * scoreDotIncrement - scoreDotSpacing} 16`}
    >
      {choices.map((choice, i) => (
        <circle
          key={i}
          cx={scoreDotRadius + i * scoreDotIncrement}
          cy={8}
          r={scoreDotRadius}
          fill={color(+choice.value)}
        />
      ))}
    </svg>
  );
}

export default LikertSparkline;
