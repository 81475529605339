import { Editor } from 'js/components/Assessment/Editor/Editor';
import React, { useEffect, useState } from 'react';
import styles from './AssessmentEditorComponent.module.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { request } from 'js/api/axios';
import { AsyncDisplay, LoadingMessage } from 'js/components';

export function AssessmentEditorComponent() {
  const token = useSelector((state) => state.identity.token);
  const currentWorkspaceId = useSelector((state) => state.currentWorkspaceId);
  const [assessmentDocTemplate, setAssessmentDocTemplate] = useState(null);
  const { templateId } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (currentWorkspaceId) {
        try {
          const response = await request.get(
            `/api/workspaces/${currentWorkspaceId}/assessment-doc-templates/${templateId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setError(null);
          setAssessmentDocTemplate(response.data.data);
        } catch (e) {
          console.error(e);
          setError('We are sorry. There was a problem loading the assessment.');
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [token, currentWorkspaceId, templateId]);

  return (
    <div className={`clear-main-footer`}>
      <AsyncDisplay isLoading={loading} isError={!!error}>
        <AsyncDisplay.Loading>
          <LoadingMessage iconSize="2x">Loading data&hellip;</LoadingMessage>
        </AsyncDisplay.Loading>
        <AsyncDisplay.Error>
          <div className="danger">
            Uh oh, looks like there was a problem loading the data
          </div>
        </AsyncDisplay.Error>
        <AsyncDisplay.Resolved>
          <div className={styles.editor}>
            <Editor assessmentDocTemplate={assessmentDocTemplate} />
          </div>
        </AsyncDisplay.Resolved>
      </AsyncDisplay>
    </div>
  );
}

export default AssessmentEditorComponent;
