import React from 'react';
import BaseAnswerSet from '../BaseAnswerSet';
import LikertSparkline from './LikertSparkline';
import LikertChoiceList from './LikertChoiceList';

import { scaleColorTraffic } from 'js/utils/color';
import { getAnswerDomain } from 'js/utils/scoring';

function getColor(choices) {
  return scaleColorTraffic.copy().domain(getAnswerDomain(choices, 'value', 3));
}

export function LikertAnswerSet({ ...props }) {
  return (
    <BaseAnswerSet
      {...props}
      getColor={getColor}
      choiceListComponent={LikertChoiceList}
      sparklineComponent={LikertSparkline}
    />
  );
}

export default LikertAnswerSet;
