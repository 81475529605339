import { isValidAnswerSetTypeForQuestion } from '../answer-set-utils';

// Base schemas
// const basePageSchema = {
//   type: 'object',
//   required: ['guid', 'pageType'],
//   properties: {
//     guid: { type: 'string' },
//     pageType: { type: 'string' },
//   },
// };

// const baseElementSchema = {
//   type: 'object',
//   required: ['guid', 'elementType'],
//   properties: {
//     guid: { type: 'string' },
//     elementType: { type: 'string' },
//   },
// };

// Question schemas
const textQuestionSchema = {
  type: 'object',
  required: ['text', 'key', 'type'],
  properties: {
    text: {
      type: 'string',
      minLength: 1,
      errorMessage: 'Question text is required',
    },
    key: {
      type: 'string',
      minLength: 1,
      errorMessage: 'A unique key is required',
    },
    type: { enum: ['long-text'] },
  },
};

const multipleChoiceQuestionSchema = {
  type: 'object',
  required: ['text', 'key', 'type'],
  hasChoices: true,
  properties: {
    text: {
      type: 'string',
      minLength: 1,
      errorMessage: 'Question text is required',
    },
    key: {
      type: 'string',
      minLength: 1,
      errorMessage: 'A unique key is required',
    },
    type: {
      enum: ['likert', 'unscored-likert', 'multi-select', 'single-select'],
      errorMessage: 'Did not recognize question type ${0}', // eslint-disable-line no-template-curly-in-string
    },
    answerSetId: { type: 'string' },
    // NOTE: disabling for now because the "hasChoices" keyword covers basic answer set validation
    // answerSet: {
    //   type: 'object',
    //   required: ['type', 'choices'],
    //   properties: {
    //     type: { type: 'string' },
    //     choices: { type: 'object' },
    //   },
    // },
  },
  errorMessage: {
    hasChoices: 'At least one option is required',
  },
};

// Element schema with if/then conditions
const elementSchema = {
  type: 'object',
  required: ['guid', 'elementType'],
  properties: {
    guid: { type: 'string' },
    elementType: { type: 'string' },
  },
  allOf: [
    {
      if: { properties: { elementType: { const: 'text' } } },
      then: {
        required: ['content'],
        properties: {
          content: {
            type: 'string',
            minLength: 1,
            errorMessage: 'Content is required',
          },
        },
      },
    },
    {
      if: { properties: { elementType: { const: 'question' } } },
      then: {
        required: ['type'],
        properties: { type: { type: 'string' } },
        allOf: [
          {
            if: { properties: { type: { enum: ['long-text'] } } },
            then: textQuestionSchema,
          },
          {
            if: {
              properties: {
                type: {
                  enum: [
                    'likert',
                    'unscored-likert',
                    'multi-select',
                    'single-select',
                  ],
                },
              },
            },
            then: multipleChoiceQuestionSchema,
          },
        ],
      },
    },
  ],
};

// Page schema with if/then conditions
const pageSchema = {
  type: 'object',
  required: ['guid', 'pageType'],
  properties: {
    guid: { type: 'string' },
    pageType: { type: 'string' },
  },
  allOf: [
    {
      if: {
        properties: { pageType: { const: 'info' } },
      },
      then: {
        required: ['title'],
        properties: {
          title: {
            type: 'string',
            minLength: 1,
            errorMessage: 'Title is required',
          },
        },
      },
    },
    {
      if: { properties: { pageType: { const: 'question-set' } } },
      then: {
        required: ['name', 'key', 'elements'],
        properties: {
          name: {
            type: 'string',
            minLength: 1,
            errorMessage: 'Name is required',
          },
          key: {
            type: 'string',
            minLength: 1,
            errorMessage: 'A unique key is required',
          },
          elements: {
            type: 'array',
            items: elementSchema,
          },
        },
      },
    },
  ],
};

// Main assessment schema
export const assessmentSchema = {
  type: 'object',
  required: ['pages'],
  properties: {
    meta: {
      type: 'object',
      required: ['type', 'key', 'name', 'description'],
      properties: {
        type: {
          type: 'string',
          enum: ['survey'],
          errorMessage: 'Unrecognized assessment type ${0}', // eslint-disable-line no-template-curly-in-string
        },
        key: { type: 'string', minLength: 1, errorMessage: 'Key is required' },
        name: {
          type: 'string',
          minLength: 1,
          errorMessage: 'Name is required',
        },
        description: {
          type: 'string',
          minLength: 1,
          errorMessage: 'Description is required',
        },
      },
    },
    pages: {
      type: 'array',
      items: pageSchema,
    },
  },
};

// Custom keywords for Ajv
export const customKeywords = {
  hasChoices: {
    keyword: 'hasChoices',
    type: 'object',
    validate(schema, data) {
      // const { data, schema } = ctx;
      const { answerSet, type: questionType } = data;

      let choiceCount = 0;
      if (
        answerSet != null &&
        isValidAnswerSetTypeForQuestion(questionType, answerSet.type)
      ) {
        choiceCount = Object.keys(answerSet?.choices ?? {}).length;
      }

      return choiceCount > 0;

      // ctx.fail(_`${choiceCount} === 0`);

      // let choices = 0;
      // if (answerSet == null ||
      //   !isValidAnswerSetTypeForQuestion(questionType, answerSet.type)
      // ) {
      //   ctx.errors.push({ message: 'At least one option is required' });
      //   return false;
      // } else {
      //   const choices = answerSet.choices ?? {};
      //   if (Object.keys(choices).length === 0) {
      //     ctx.errors.push({ message: 'At least one option is required' });
      //     return false;
      //   }
      // }

      // return true;

      // if (ctx.data.answerSetId && ctx.data.answerSet) {
      //   const validTypes = validAnswerSetTypes[ctx.data.type] || [];
      //   const isValid = validTypes.includes(ctx.data.answerSet.type);
      //   if (!isValid) {
      //     ctx.errors.push({
      //       message: `Invalid answer set type for question type ${ctx.data.type}`,
      //     });
      //   }
      // }
    },
    metaSchema: {
      const: true,
    },
  },
  // validateAnswerSetType: {
  //   keyword: 'validateAnswerSetType',
  //   type: 'object',
  //   validate: function validate(schema, data) {
  //     if (data.answerSetId && data.answerSet && data.type) {
  //       const validTypes = validAnswerSetTypes[data.type] || [];
  //       const isValid = validTypes.includes(data.answerSet.type);

  //       if (!isValid) {
  //         validate.errors = [
  //           {
  //             keyword: 'validateAnswerSetType',
  //             message: `Invalid answer set type for question type ${data.type}`,
  //             params: {
  //               questionType: data.type,
  //               answerSetType: data.answerSet.type,
  //             },
  //           },
  //         ];
  //       }
  //       return isValid;
  //     }
  //     return true;
  //   },
  // },
};
