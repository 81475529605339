import { arrayHasChanged } from './utils';

export class ExclusiveArrayReducer {
  constructor(schemaItem, path) {
    this.schemaItem = schemaItem;
    this.key = schemaItem.key;
    this.path = path;
  }

  reduce(state, entities, _action) {
    const entitiesOfType = entities[this.schemaItem.entity.key] ?? {};
    const itemEntity = this.schemaItem.entity;
    const getPosition = (x) => {
      return x.position?.[this.key];
    };
    const currentState = state ?? {};

    let newState = Object.values(entitiesOfType).reduce((acc, item) => {
      // const itemId = itemEntity.getId(item);
      const targetId = getPosition(item)?.t;

      if (targetId !== undefined) {
        const set = (acc[targetId] = acc[targetId] ?? []);
        set.push(item);
      }

      return acc;
    }, {});

    let hasChanged = false;
    const newStateEntries = Object.entries(newState).map(([key, set]) => {
      set.sort((a, b) => {
        const posA = getPosition(a).p;
        const posB = getPosition(b).p;

        if (posA < posB) {
          return -1;
        } else if (posA > posB) {
          return 1;
        } else {
          const idA = itemEntity.getId(a);
          const idB = itemEntity.getId(b);
          if (idA < idB) {
            return -1;
          } else if (idA > idB) {
            return 1;
          }
        }

        return 0;
      });

      const setHasChanged = arrayHasChanged(currentState[key], set);
      hasChanged = hasChanged || setHasChanged;
      return [key, setHasChanged ? set : currentState[key]];
    });
    newState = Object.fromEntries(newStateEntries);

    hasChanged =
      hasChanged || Object.keys(currentState).length !== newStateEntries.length;

    return hasChanged ? newState : currentState;
  }
}
