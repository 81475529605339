import React, { useCallback } from 'react';
import { format } from 'date-fns';

import { Button } from 'reactstrap';
import AsyncButton from 'js/components/Forms/AsyncButton';
import { Icon } from 'js/components';

function ArchivedTemplate({
  summary,
  canModifyTemplates,
  canRestoreTemplates,
  onRestoreTemplate,
}) {
  canModifyTemplates = true;
  canRestoreTemplates = true;

  const handleRestoreTemplate = useCallback(
    () => onRestoreTemplate(summary.id),
    [onRestoreTemplate, summary.id]
  );

  return (
    <tr>
      <td>
        <Icon
          icon={['far', 'archive']}
          className="muted icon-left"
          fixedWidth
        />
        {summary.name}
      </td>
      <td valign="middle">
        <div className="badge palette-cat-3">Archived</div>
      </td>
      <td className="muted">{format(summary.archived_at, 'MMM d, yyyy')}</td>
      <td className="text-right">
        {canModifyTemplates && canRestoreTemplates ? (
          <AsyncButton className="btn-icon" onClick={handleRestoreTemplate}>
            <Icon icon="box-open" />
          </AsyncButton>
        ) : (
          <div title={'Only admin users can archive/restore templates'}>
            <Button className="btn-icon" disabled>
              <Icon icon="box-open" />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
}

export default ArchivedTemplate;
