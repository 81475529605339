import React from 'react';
import { getComponents } from './AnswerSet/Types';

function QuestionSettings({ question, className, ...props }) {
  const { Settings } = getComponents(question?.type);

  return (
    <div className={className}>
      <Settings question={question} {...props} />
    </div>
  );
}

export default QuestionSettings;
