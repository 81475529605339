// const answerSetDoc = {
//   id: 'AS-29FJ39LSX3',
//   isStandard: true,
//   name: 'Answer Set',
//   type: 'likert',
//   choices: {
//     id1: { id: 'id1', value: 1, text: 'Strongly Disagree', p: '' },
//     id2: { id: 'id2', value: 2, text: 'Disagree', p: '' },
//     id3: { id: 'id3', value: 3, text: 'Neutral', p: '' },
//     id4: { id: 'id4', value: 4, text: 'Agree', p: '' },
//     id5: { id: 'id5', value: 5, text: 'Strongly Agree', p: '' },
//   },
//   includeNA: false,
//   naText: 'N/A',
// };

// prettier-ignore
const agreementAnswerSet = {
  id: 'AS-agreement',
  isStandard: true,
  isPublished: true,
  name: 'Agreement',
  type: 'likert',
  choices: {
    'AC-agreement-C-1': { id: 'AC-agreement-C-1', value: '1', text: 'Strongly disagree', p: '0.125' },
    'AC-agreement-C-2': { id: 'AC-agreement-C-2', value: '2', text: 'Disagree', p: '0.25' },
    'AC-agreement-C-3': { id: 'AC-agreement-C-3', value: '3', text: 'Somewhat disagree', p: '0.375' },
    'AC-agreement-C-4': { id: 'AC-agreement-C-4', value: '4', text: 'Neither agree nor disagree', p: '0.5', },
    'AC-agreement-C-5': { id: 'AC-agreement-C-5', value: '5', text: 'Somewhat agree', p: '0.625' },
    'AC-agreement-C-6': { id: 'AC-agreement-C-6', value: '6', text: 'Agree', p: '0.85' },
    'AC-agreement-C-7': { id: 'AC-agreement-C-7', value: '7', text: 'Strongly agree', p: '0.875' },
  },
};

// prettier-ignore
const frequencyAnswerSet = {
  id: 'AS-frequency',
  isStandard: true,
  isPublished: true,
  name: 'Frequency',
  type: 'likert',
  choices: {
    'AC-frequency-C-1': { id: 'AC-frequency-C-1', value: '1', text: 'Never', p: '0.167' },
    'AC-frequency-C-2': { id: 'AC-frequency-C-2', value: '2', text: 'Rarely', p: '0.333' },
    'AC-frequency-C-3': { id: 'AC-frequency-C-3', value: '3', text: 'Sometimes', p: '0.5' },
    'AC-frequency-C-4': { id: 'AC-frequency-C-4', value: '4', text: 'Often', p: '0.667' },
    'AC-frequency-C-5': { id: 'AC-frequency-C-5', value: '5', text: 'Always', p: '0.833' },
  },
};

const standardAnswerSets = [agreementAnswerSet, frequencyAnswerSet];

export default standardAnswerSets;
