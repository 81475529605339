import React, { useMemo } from 'react';
import styles from '../../Editor.module.scss';
import toolStyles from './AnswerSet.module.scss';

export default function DropIndicator({ bottom, top, isStandard }) {
  const classNames = useMemo(() => {
    const classNames = [styles.dropInd, toolStyles['drop-indicator']];
    if (bottom) classNames.push(toolStyles.bottom);
    if (top) classNames.push(toolStyles.top);
    return classNames.join(' ');
  }, [bottom, top]);

  return <div className={classNames}></div>;
}
