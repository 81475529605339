import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  list as listAssessments,
  create as createAssessment,
} from 'js/actions/assessment-actions';

import AssessmentsComponent from '../components/AssessmentsComponent';

function AssessmentsContainer() {
  return <AssessmentsComponent />;
}

export default withRouter(
  connect(
    (state) => {
      const { entities } = state;

      return {
        assessments: entities.assessments,
      };
    },
    {
      listAssessments,
      createAssessment,
    }
  )(AssessmentsContainer)
);
