import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'js/components';

const RunNavigation = ({ assessment, questionSetIndex }) => {
  const nextQuestionSetId =
    assessment.question_sets.length > questionSetIndex + 1
      ? assessment.question_sets[questionSetIndex + 1].id
      : null;

  const prevQuestionSetId =
    questionSetIndex > 0
      ? assessment.question_sets[questionSetIndex - 1].id
      : null;

  return (
    <div className="survey-run-navigation">
      {prevQuestionSetId !== null ? (
        <Link className="btn btn-secondary" to={'' + prevQuestionSetId}>
          <span>
            <Icon className="btn-icon-left" icon="arrow-left" size="lg" />
            Back
          </span>
        </Link>
      ) : null}
      {nextQuestionSetId !== null ? (
        <Link
          data-test="next-question-set-link"
          className="btn btn-primary"
          to={'' + nextQuestionSetId}
        >
          <span>
            Next
            <Icon className="btn-icon-right" icon="arrow-right" size="lg" />
          </span>
        </Link>
      ) : (
        <Link
          data-test="review-assessment-link"
          className="btn btn-primary"
          to="review"
        >
          Review
        </Link>
      )}
    </div>
  );
};

export default RunNavigation;
