import { isNil } from 'lodash';
import { objectHasChanged, initializeActionTarget } from './utils';
import { positionReducer } from './position';
import { getLookups } from './utils/lookups';

export class EntityReducer {
  constructor(schemaItem, path) {
    this.schemaItem = schemaItem;
    this.key = schemaItem.key;
    this.path = path;

    this.lookups = getLookups(schemaItem);
  }

  reduce(state, entities, action) {
    // TODO: isNil(action.patch) will crap out if we're running an "add" action which has an .entity property instad of a patch
    if (
      isNil(state) &&
      (action.type === initializeActionTarget || isNil(action.patch))
    ) {
      return state;
    }

    let newState = { ...state };

    if (action.type !== initializeActionTarget && action.target === this.key) {
      switch (action.type) {
        case 'add':
          newState = this.add(newState, entities, action);
          break;

        case 'patch':
        case 'addOrPatch':
          newState = this.patch(newState, entities, action);
          break;

        case 'delete':
          newState = this.delete(newState, entities, action);
          break;

        default:
        // do nothing
      }
    }

    Object.entries(newState).forEach(([key, value]) => {
      const itemState = { ...value };

      for (const [key, lookup] of this.lookups) {
        itemState[key] = lookup(entities, itemState, key);
      }

      if (objectHasChanged(value, itemState)) {
        newState[key] = itemState;
      }
    });

    return objectHasChanged(state, newState) ? newState : state;
  }

  refreshLookups(state, entities) {
    let hasChanged = false;
    const newState = { ...state };
    Object.entries(state).forEach(([itemId, itemState]) => {
      let itemHasChanged = false;
      const newItemState = { ...itemState };
      for (const [key, lookup] of this.lookups) {
        const newLookupValue = lookup(entities, itemState, key);

        if (newLookupValue !== itemState[key]) {
          itemHasChanged = true;
          newItemState[key] = newLookupValue;
        }
      }

      if (itemHasChanged) {
        hasChanged = true;
        newState[itemId] = newItemState;
      }
    });

    return hasChanged ? newState : state;
  }

  patch(state, entities, action) {
    const refreshedState = this.refreshLookups(state, entities);

    if (action.target !== this.schemaItem.key) {
      return refreshedState;
    }

    let hasChanged = refreshedState === state;

    const itemState = state[action.id];
    const itemId = action.id;

    let newItemState = { ...itemState };

    const patch = action.patch;

    const schemaItem = this.schemaItem;
    const getPosition = (x) =>
      isNil(x) ? undefined : schemaItem.getPositionState(x);

    const position = positionReducer(getPosition(itemState), action, {
      getPositionPatch: (x) => getPosition(x),
    });

    newItemState = this.schemaItem.updatePosition(newItemState, position);

    const invalidPatchKeys = Object.keys(patch ?? {}).filter((key) =>
      this.lookups.has(key)
    );

    if (invalidPatchKeys > 0) {
      console.warn(
        `Attempting to patch a schema item with data for a child entity. The following patch keys will be disregarded: [${invalidPatchKeys.join(
          ', '
        )}]`
      );

      invalidPatchKeys.forEach((key) => {
        delete patch[key];
      });
    }

    const positionlessPatch = { ...patch };
    delete positionlessPatch[schemaItem.positionAttribute];

    newItemState = { ...newItemState, ...positionlessPatch };
    hasChanged = hasChanged || objectHasChanged(itemState, newItemState);

    return hasChanged
      ? {
          ...refreshedState,
          [itemId]: newItemState,
        }
      : state;
  }

  add(state, entities, action) {
    const refreshedState = this.refreshLookups(state, entities);

    if (action.target !== this.schemaItem.key) {
      return refreshedState;
    }

    const itemId = this.schemaItem.getId(action.entity);

    return { ...state, [itemId]: action.entity };
  }

  delete(state, entities, action) {
    const refreshedState = this.refreshLookups(state, entities);

    if (action.target !== this.schemaItem.key) {
      return refreshedState;
    }

    if (state[action.id] === undefined) {
      return state;
    }

    const newState = { ...state };
    delete newState[action.id];

    return newState;
  }
}
