import MultiSelectChoiceEditor from './MultiSelectChoiceEditor';
import SingleSelectChoiceEditor from './SingleSelectChoiceEditor';
import MultiSelectChoiceSettings from './MultiSelectChoiceSettings';
import ScoredChoiceEditor from './ScoredChoiceEditor';
import ScoredChoiceSettings from './ScoredChoiceSettings';
import NullChoiceEditor from './NullChoiceEditor';
import NullSettings from './NullSettings';

export function getComponents(questionType) {
  switch (questionType) {
    case 'likert':
    case 'unscored-likert':
      return {
        ChoiceEditor: ScoredChoiceEditor,
        Settings: ScoredChoiceSettings,
      };

    case 'long-text':
      return {
        ChoiceEditor: NullChoiceEditor,
        Settings: NullSettings,
      };

    case 'multi-select':
      return {
        ChoiceEditor: MultiSelectChoiceEditor,
        Settings: MultiSelectChoiceSettings,
      };

    case 'single-select':
      return {
        ChoiceEditor: SingleSelectChoiceEditor,
        Settings: MultiSelectChoiceSettings,
      };

    default:
      return {
        ChoiceEditor: NullChoiceEditor,
        Settings: NullSettings,
      };
  }
}
