import React from 'react';

import { Icon } from 'js/components';

import styles from './MetadataAndHistory.module.scss';
import Loading from 'js/components/Loading/Loading';
import Revision from './Revision';

function Revisions({ revisions, isLoading }) {
  return (
    <div className={styles.revisions}>
      {isLoading && (
        <div className={styles['revisions-loading']}>
          <Loading />
        </div>
      )}

      {revisions.length === 0 ? (
        <div className={styles['revisions-empty']}>
          <div className="media text-muted mt-4 mb-4">
            <Icon
              icon={['fal', 'spider-web']}
              size="4x"
              className="mr-3"
              fixedWidth
            />
            <div className="media-body">
              <p>You haven't published any revisions yet.</p>
              <p>Publishing a revision makes it available to your teams.</p>
            </div>
          </div>
        </div>
      ) : (
        revisions.map((revision, i) =>
          i === 0 ? (
            <fieldset key={revision.id} className={styles['revision-fieldset']}>
              <legend>Current</legend>
              <Revision revision={revision} />
            </fieldset>
          ) : (
            <Revision key={revision.id} revision={revision} />
          )
        )
      )}
    </div>
  );
}

export default Revisions;
