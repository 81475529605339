const metaPropSideEffects = {
  key: () => {},
  name: () => {},
};

export function update(yDoc, updates) {
  yDoc.transact(() => {
    const meta = yDoc.getMap('meta');

    for (const [key, value] of Object.entries(updates)) {
      if (metaPropSideEffects[key]) {
        metaPropSideEffects[key](value);
      }

      meta.set(key, value);
    }
  });
}
