import React, { useMemo, useLayoutEffect, useState } from 'react';
import { useStandardCollator } from 'js/utils/string';

import { useParentSize } from '@visx/responsive';
import { useId } from 'js/hooks/useId';
import { Icon } from 'js/components';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';

import AnswerSetSelector from '../AnswerSetSelector';

import styles from '../AnswerSet.module.scss';
import ScoredChoice from './ScoredChoice';
import { isValidAnswerSetTypeForQuestion } from '../../../answer-set-utils';

export function ListChoiceEditor({
  answerSet,
  questionId,
  questionType,
  color,
  onAddChoice = () => {},
  onDeleteChoice = () => {},
  onAnswerSetChange = () => {},
  onChoiceUpdate = () => {},
  choiceTag: ChoiceTag = ScoredChoice,
  choiceTagProps = {},
}) {
  const collator = useStandardCollator();
  const [nthChildOffset, setNthChildOffset] = useState(null);
  const { parentRef, width } = useParentSize({ debounceTime: 0 });
  const id = useId();

  const choices = useMemo(() => {
    return !answerSet?.choices ||
      !isValidAnswerSetTypeForQuestion(questionType, answerSet?.type)
      ? []
      : Object.values(answerSet.choices).sort((a, b) =>
          collator.compare(a.p, b.p)
        );
  }, [answerSet?.choices, answerSet?.type, questionType, collator]);

  useLayoutEffect(() => {
    const parentElement = parentRef.current;
    let previousOffset = null;

    if (parentElement) {
      const flexChildren = Array.from(parentElement.children);
      const newRowIndex = flexChildren.findIndex((flexChild, index) => {
        previousOffset = previousOffset ?? flexChild.offsetTop;
        return flexChild.offsetTop > previousOffset;
      });

      if (newRowIndex > 0) {
        setNthChildOffset(newRowIndex);
      } else {
        setNthChildOffset(null);
      }
    } else {
      setNthChildOffset(null);
    }
  }, [parentRef, width, choices.length]);

  const handleAddChoice = () => onAddChoice(choices.length);

  const handleChoiceUpdate = (choiceId, update) => {
    onChoiceUpdate(answerSet.id, choiceId, update);
  };

  const handleChoiceDelete = (choiceId) => {
    onDeleteChoice(answerSet.id, choiceId);
  };

  return (
    <>
      <style>
        {`#${id} .${styles.choice}:nth-child(${nthChildOffset}n+1)::before { display: none !important; }`}
      </style>
      <div
        className={`${styles['choice-list-null']} ${styles['choice-list']}`}
        ref={parentRef}
        id={id}
      >
        {choices.map((choice, index) => (
          <ChoiceTag
            key={choice.id}
            choice={choice}
            index={index}
            answerSetId={answerSet.id}
            questionId={questionId}
            color={color}
            onUpdate={handleChoiceUpdate}
            onDelete={handleChoiceDelete}
            {...choiceTagProps}
          />
        ))}
        <div className={styles['add-choice']}>
          <button className="btn btn-light" onClick={handleAddChoice}>
            <Icon icon={faCirclePlus} className="icon" size="lg" />
            <span className={styles['add-choice-text']}>Add choice</span>
          </button>
        </div>
        {choices.length === 0 && (
          <>
            <div className={styles['choose-answer-set-or']}>OR</div>
            <div className={styles['choose-answer-set-inline']}>
              <p>Choose an existing answer set</p>
              <div>
                <AnswerSetSelector
                  currentAnswerSet={answerSet}
                  questionType={questionType}
                  onChange={onAnswerSetChange}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ListChoiceEditor;
