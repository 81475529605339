import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { AssessmentEditorComponent } from '../components/AssessmentEditorComponent';
import AssessmentsDashboard from './AssessmentsDashboard';
import AssessmentCreator from './AssessmentCreator';
import PrivateRoute from 'js/containers/Route/PrivateRoute';
import Dashboard from 'js/layouts/Dashboard';
import MinimalLayout from 'js/layouts/Minimal';

function AssessmentManagement() {
  const match = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        path={`${match.path}/new`}
        component={AssessmentCreator}
        layout={Dashboard}
      />
      <PrivateRoute
        path={`${match.path}/:templateId/edit`}
        component={AssessmentEditorComponent}
        layout={MinimalLayout}
      />
      <PrivateRoute
        path={match.path}
        exact
        component={AssessmentsDashboard}
        layout={Dashboard}
      />
    </Switch>
  );
}

export default AssessmentManagement;
