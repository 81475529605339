import _ from 'lodash';

// import { types as teamTypes } from '../../actions/team-actions';
// import { types as companyTypes } from '../../actions/company-actions';
import { types as assessmentTypes } from 'js/actions/assessment-actions';
import { types as liveAssessmentTypes } from 'js/actions/live-assessment-actions';
import { types as AccountTypes } from 'js/actions/account-actions';

const initialState = {
  companies: {},
  goals: {},
  teams: {},
  favoriteTeams: {},
  assessments: {},
  assessmentMetas: {},
  assessmentTemplates: {},
  assessmentDocTemplates: {},
  assessmentDocTemplateSubscriptions: {},
  assessmentDocTemplateSummaries: {},
  liveAssessments: {},
  passphrases: {},
  questionSets: {},
  questions: {},
  answers: {},
  organizations: {},
  responses: {},
  users: {},
  workspaces: {},
  accounts: {},
};

const acks = {};

// Clean up the acks every so often
setInterval(() => {
  const cutoff = Date.now() - 60000;

  for (let i in acks) {
    if (acks[i] < cutoff) {
      delete acks[i];
    }
  }
}, 60000);

function isAcknowledgement(action) {
  if (action.meta && action.meta.ack) {
    if (acks[action.meta.ack] !== undefined) {
      return true;
    }

    acks[action.meta.ack] = Date.now();

    return false;
  }

  return false;
}

function extendEntities(state, overrides) {
  if (!overrides) {
    return state;
  }

  const extended = { ...state };

  for (const key in overrides) {
    extended[key] = { ...state[key], ...overrides[key] };
  }

  return extended;
}

function deleteEntitites(state, entityTree) {
  const mutations = _.chain(entityTree)
    .map((id, entityKey) => {
      const entitySet = state[entityKey];
      if (!entitySet || !entitySet[id]) {
        return null;
      }

      const mutatedEntitySet = { ...entitySet };
      delete mutatedEntitySet[id];

      return {
        [entityKey]: mutatedEntitySet,
      };
    })
    .filter()
    .value()
    .reduce((accumulator, val) => {
      return {
        ...accumulator,
        ...val,
      };
    }, {});

  return {
    ...state,
    ...mutations,
  };
}

function patchEntitites(state, entityTree) {
  const mutations = Object.entries(entityTree).reduce(
    (accumulator, [entityKey, patchSet]) => {
      const entitySet = accumulator[entityKey] ?? state[entityKey];

      if (!entitySet) {
        return accumulator;
      }

      const mutationSet = Object.entries(patchSet).reduce(
        (patchesAcc, [key, patch]) => {
          return {
            ...patchesAcc,
            [key]: { ...patchesAcc[key], ...patch },
          };
        },
        { ...entitySet }
      );

      return {
        ...accumulator,
        [entityKey]: mutationSet,
      };
    },
    {}
  );

  return {
    ...state,
    ...mutations,
  };
}

const entities = (state = initialState, action) => {
  switch (action.type) {
    case assessmentTypes.BULK_UPDATE_REVEAL_RESULTS_ON: {
      const assessment = state.assessments[action.meta.assessmentId];
      const newRevealDate = action.data.reveal_results_on;
      const questions = assessment.question_sets.flatMap(
        (qs) => state.questionSets[qs]?.questions ?? []
      );
      const questionPatch = questions.reduce((acc, q) => {
        const question = state.questions[q];
        if (question) {
          acc[q] = {
            ...state.questions[q],
            reveal_results_on: newRevealDate,
          };
        }

        return acc;
      }, {});

      return {
        ...state,
        questions: { ...state.questions, ...questionPatch },
      };
    }

    case assessmentTypes.RESPOND_TO_ASSESSMENT: {
      const { questions, responses } = state;
      const responseId = action.data.response.id;
      let question = questions[action.data.response.question_id];
      let questionModified = false;

      if (question.responses.indexOf(responseId) === -1) {
        questionModified = true;
        question = {
          ...question,
          responses: [...question.responses, responseId],
        };
      }

      return {
        ...state,
        questions: !questionModified
          ? questions
          : {
              ...questions,
              [question.id]: question,
            },
        responses: {
          ...responses,
          [responseId]: action.data.response,
        },
      };
    }

    case assessmentTypes.RESPOND_TO_ASSESSMENT_SUCCESS:
      {
        const requestId = action.meta.request.response.id;
        if (_.isString(requestId) && _.startsWith(requestId, '_')) {
          const responses = {
            ...state.responses,
          };
          delete responses[requestId];

          const response = _.find(action.data.entities.responses, () => true);
          const question = {
            ...state.questions[response.question_id],
          };
          question.responses = question.responses.map((r) =>
            r === requestId ? response.id : r
          );

          state = {
            ...state,
            responses: responses,
            questions: {
              ...state.questions,
              [question.id]: question,
            },
          };
        }
      }
      break;

    // case teamTypes.CREATE_TEAM_SUCCESS:
    //   return {
    //     ...state,
    //     status: 'created'
    //   };

    // case teamTypes.DELETE_TEAM_SUCCESS:
    //   let teams = { ...state.teams };
    //   delete teams[action.id];

    //   return { ...state, teams };

    // case companyTypes.DELETE_COMPANY_SUCCESS:
    //   let companies = { ...state.companies };
    //   delete companies[action.id];

    //   return { ...state, companies };

    case liveAssessmentTypes.CHANGE_QUESTION_SUCCESS:
    case liveAssessmentTypes.CHANGE_QUESTION: {
      const { assessment_id, current_question_id } = action.data;
      const liveAssessment = state.liveAssessments[assessment_id];

      if (
        !liveAssessment ||
        liveAssessment.current_question_id === current_question_id ||
        isAcknowledgement(action)
      ) {
        return state;
      }

      return {
        ...state,
        liveAssessments: {
          ...state.liveAssessments,
          [assessment_id]: {
            ...liveAssessment,
            current_question_id,
          },
        },
      };
    }

    case liveAssessmentTypes.UPDATE_LIVE_ASSESSMENT: {
      const { assessment_id } = action.data;

      const liveAssessment = state.liveAssessments[assessment_id];

      if (!liveAssessment || isAcknowledgement(action)) {
        return state;
      }

      return {
        ...state,
        liveAssessments: {
          ...state.liveAssessments,
          [assessment_id]: {
            ...liveAssessment,
            ...action.data,
          },
        },
      };
    }

    case AccountTypes.LOGOUT:
    case AccountTypes.LOGOUT_SUCCESS:
    case 'API_ERROR/INVALID_TOKEN':
      return initialState;

    default:
    // noop
  }

  if (action.data && action.data.entities && !isAcknowledgement(action)) {
    return { ...extendEntities(state, action.data.entities) };
  }

  if (action.meta && action.meta.create && !isAcknowledgement(action)) {
    return { ...extendEntities(state, action.meta.create) };
  }

  if (action.meta && action.meta.delete && !isAcknowledgement(action)) {
    return deleteEntitites(state, action.meta.delete);
  }

  if (action.meta && action.meta.patch && !isAcknowledgement(action)) {
    return patchEntitites(state, action.meta.patch);
  }

  return state;
};

export default entities;
