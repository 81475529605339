import React from 'react';
import { AnswerSet } from './AnswerSet';

export default function MultiSelectQuestionDetail({ question, ...props }) {
  const { answerSetConfig, answerSet } = question;

  return (
    <div>
      <AnswerSet
        {...props}
        allowedTypes={['value-list']}
        answerSet={answerSet}
        answerSetConfig={answerSetConfig}
        question={question}
      />
    </div>
  );
}
