import React, { useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import AnswerSetSelector from '../AnswerSetSelector';

import { useEditorInput } from '../../../useEditorInput';

import styles from '../AnswerSet.module.scss';

const NAME_PLACEHOLDER = 'Custom';

function AnswerSetSaver({
  question,
  answerSet,
  onAnswerSetChange,
  onAnswerSetFieldChange,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const saveInputRef = useRef(null);
  const { reset, ...saveAsNameInput } = useEditorInput({
    value: answerSet?.name ?? NAME_PLACEHOLDER,
    placeholder: NAME_PLACEHOLDER,
    onCancel: () => setIsSaving(false),
    onEnter: (name) => {
      onAnswerSetFieldChange(answerSet.id, { name, isPublished: true });
      setIsSaving(false);
    },
    ref: saveInputRef,
  });

  const { value: saveName } = saveAsNameInput;

  const handleSaveAsToggle = () => {
    setIsSaving(!isSaving);
  };

  const handleCancelSave = () => {
    setIsSaving(false);
    reset();
  };

  useLayoutEffect(() => {
    const input = saveInputRef.current;
    if (input) {
      if (isSaving) {
        input.focus();
        input.setSelectionRange(0, input.value.length);
      }
    }
  }, [isSaving]);

  const allowSaving = answerSet?.isPublished === false;

  const handleAnswerSetPublish = () => {
    onAnswerSetFieldChange(answerSet.id, { name: saveName, isPublished: true });
    setIsSaving(false);
  };

  return (
    <div
      className={classNames(styles.header, {
        [styles.saving]: allowSaving && isSaving,
      })}
    >
      <div className={styles['name-grid-item']}>
        <input type="text" {...saveAsNameInput} />
      </div>
      <div className={styles['set-selector-grid-item']}>
        <AnswerSetSelector
          questionType={question.type}
          currentAnswerSet={answerSet}
          onChange={onAnswerSetChange}
        />
      </div>
      {allowSaving && (
        <div className={styles['save-button-grid-item']}>
          {!isSaving ? (
            <button className="btn-inline-action" onClick={handleSaveAsToggle}>
              <i>Save as&hellip;</i>
            </button>
          ) : (
            <>
              <button
                className="btn-inline-action"
                onClick={handleAnswerSetPublish}
              >
                Save
              </button>
              <button className="btn-inline-action" onClick={handleCancelSave}>
                Cancel
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default AnswerSetSaver;
