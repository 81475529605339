import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import * as Y from 'yjs';
import { QuillBinding } from 'y-quill';
import Quill from 'quill';
import QuillCursors from 'quill-cursors';
import { useRouteMatch } from 'react-router-dom';
import { Socket } from 'phoenix';
import { YjsChannelProvider } from 'js/utils/yjs-provider/y-phoenix-channel';

Quill.register('modules/cursors', QuillCursors);

export default function Demo() {
  const store = useStore();
  const [identity, setIdentity] = useState();
  const [socket, setSocket] = useState();
  const name = useRouteMatch().params.name;

  useEffect(() => {
    const identity = store.getState().identity;
    let socket = new Socket('/socket', { params: { token: identity.token } });
    socket.connect();
    setSocket(socket);
    setIdentity(identity);
    return () => {
      socket.disconnect();
    };
  }, [store]);

  useEffect(() => {
    let provider = null;
    let quillBinding = null;
    if (identity) {
      const ydoc = new Y.Doc();
      provider = new YjsChannelProvider(socket, `crdt:demo:${name}`, ydoc);
      provider.connect();
      const type = ydoc.getText('text');

      // Initialize Quill
      const quill = new Quill('#editor-container', {
        modules: {
          cursors: true,
        },
        theme: 'snow',
      });

      quillBinding = new QuillBinding(type, quill, provider.awareness);

      // Define user name and user name
      provider.awareness.setLocalStateField('user', {
        name: identity?.me?.username ?? 'Anonymous',
        color: 'blue',
      });
    }

    return () => {
      for (const e of document.getElementsByClassName('ql-toolbar')) {
        e.remove();
      }
      const container = document.getElementById('editor-container');

      if (container) {
        container.innerHTML = '';
      }

      if (quillBinding) {
        quillBinding.destroy();
      }
      if (provider) {
        provider.disconnect();
      }
    };
  }, [identity, name, socket]);

  return (
    <div>
      <h1>Demo</h1>
      <div id="editor-container"></div>
    </div>
  );
}
