import { mergeKeys } from './keys';

// performs a shallow comparison of two arrays
export function arrayHasChanged(oldState, newState) {
  if ((oldState && !newState) || (newState && !oldState)) {
    return true;
  }

  if (oldState.length !== newState.length) {
    return true;
  }

  return undefined !== oldState.find((o, i) => newState[i] !== o);
}

// performs a shallow comparison of two objects
export function objectHasChanged(oldState, newState) {
  if ((oldState && !newState) || (newState && !oldState)) {
    return true;
  }

  const newStateKeys = Object.keys(newState);
  const oldStateKeys = Object.keys(oldState);
  const hasDifferentKeyLengths = newStateKeys.length !== oldStateKeys.length;

  if (hasDifferentKeyLengths) {
    return true;
  }

  const mergedKeys = mergeKeys(newStateKeys, oldStateKeys);
  const hasDifferentKeys = mergedKeys.length !== newStateKeys.length;

  if (hasDifferentKeys) {
    return true;
  }

  const hasDifferentValue = !!newStateKeys.find(
    (key) => oldState[key] !== newState[key]
  );

  if (hasDifferentValue) {
    return true;
  }

  return false;
}
