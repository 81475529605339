import * as encoding from 'lib0/encoding';
import * as decoding from 'lib0/decoding';
import * as syncProtocol from 'y-protocols/sync';
import * as awarenessProtocol from 'y-protocols/awareness';

export const messageSync = 0;
export const messageQueryAwareness = 3;
export const messageAwareness = 1;
export const messageAuth = 2;

/**
 *                       encoder,          decoder,          provider,          emitSynced, messageType
 * @type {Array<function(encoding.Encoder, decoding.Decoder, PhoenixChannelProvider, boolean,    number):void>}
 */
const messageHandlers = [];

messageHandlers[messageSync] = (encoder, decoder, provider) => {
  encoding.writeVarUint(encoder, messageSync);
  syncProtocol.readSyncMessage(decoder, encoder, provider.ydoc, provider);
  // console.log('doc after update from socket:', provider.ydoc.toJSON());
};

messageHandlers[messageQueryAwareness] = (
  encoder,
  _decoder,
  provider,
  _emitSynced,
  _messageType
) => {
  encoding.writeVarUint(encoder, messageAwareness);
  encoding.writeVarUint8Array(
    encoder,
    awarenessProtocol.encodeAwarenessUpdate(
      provider.awareness,
      Array.from(provider.awareness.getStates().keys())
    )
  );
};

messageHandlers[messageAwareness] = (
  _encoder,
  decoder,
  provider,
  _emitSynced,
  _messageType
) => {
  awarenessProtocol.applyAwarenessUpdate(
    provider.awareness,
    decoding.readVarUint8Array(decoder),
    provider
  );
};

export class YjsChannelProvider {
  constructor(socket, topic, ydoc, awareness) {
    this.socket = socket;
    this.topic = topic;
    this.ydoc = ydoc;
    this.channel = null;
    this.syncRef = null;
    this.awareness = awareness || new awarenessProtocol.Awareness(ydoc);
  }

  connect() {
    if (!this.socket || this.channel !== null) {
      return;
    }
    this.channel = this.socket.channel(this.topic, {});

    this.channel.on('sync', (payload) => this._handleMessage(payload));

    this._handleDocUpdate = (update, origin) => {
      if (origin !== this) {
        const encoder = encoding.createEncoder();
        encoding.writeVarUint(encoder, messageSync);
        syncProtocol.writeUpdate(encoder, update);

        this.channel
          .push('sync', [...encoding.toUint8Array(encoder).values()])
          .receive('ok', (p) => this._handleMessage(p))
          .receive('error', (err) => console.log('phoenix errored', err));
      }
    };

    this._awarenessUpdateHandler = ({ added, updated, removed }, _origin) => {
      const changedClients = added.concat(updated).concat(removed);
      const encoder = encoding.createEncoder();

      encoding.writeVarUint(encoder, messageAwareness);
      encoding.writeVarUint8Array(
        encoder,
        awarenessProtocol.encodeAwarenessUpdate(this.awareness, changedClients)
      );
      this.channel
        .push('sync', [...encoding.toUint8Array(encoder).values()])
        .receive('ok', (p) => this._handleMessage(p))
        .receive('error', (err) => console.log('phoenix errored', err));
    };

    this.channel
      .join()
      .receive('ok', (resp) => {
        // console.log('Joined CRDT channel successfully', resp);
        this.ydoc.on('update', this._handleDocUpdate);
        this.awareness.on('update', this._awarenessUpdateHandler);
        this._sendSyncStep1();
      })
      .receive('error', (resp) => {
        console.log('Unable to join CRDT channel', resp);
      });
  }

  destroy() {
    this.disconnect();
  }

  disconnect() {
    if (this.channel !== null) {
      this.ydoc.off('update', this._handleDocUpdate);

      awarenessProtocol.removeAwarenessStates(
        this.awareness,
        Array.from(this.awareness.getStates().keys()).filter(
          (client) => client !== this.ydoc.clientID
        ),
        this
      );

      this.awareness.off('update', this._awarenessUpdateHandler);
      this.channel.leave();
      this.channel = null;
    }
  }

  _sendLocalAwareness() {
    // broadcast local awareness state
    if (this.awareness.getLocalState() !== null) {
      const encoderAwarenessState = encoding.createEncoder();
      encoding.writeVarUint(encoderAwarenessState, messageAwareness);
      encoding.writeVarUint8Array(
        encoderAwarenessState,
        awarenessProtocol.encodeAwarenessUpdate(this.awareness, [
          this.ydoc.clientID,
        ])
      );

      this.channel
        .push('sync', [
          ...encoding.toUint8Array(encoderAwarenessState).values(),
        ])
        .receive('ok', (p) => this._handleMessage({ response: p }))
        .receive('error', (err) => console.log('phoenix errored', err));
    }
  }

  _sendSyncStep1() {
    const encoder = encoding.createEncoder();
    encoding.writeVarUint(encoder, messageSync);
    syncProtocol.writeSyncStep1(encoder, this.ydoc);
    this.channel
      .push('sync', [...encoding.toUint8Array(encoder).values()])
      .receive('ok', (p) => this._handleMessage({ response: p }))
      .receive('error', (err) => console.log('phoenix errored', err));
  }

  _handleMessage({ response: payload }) {
    if (!Array.isArray(payload)) {
      console.warn('Received unexpected message from server:', payload);
      return;
    }

    const buf = new Uint8Array(payload);
    const decoder = decoding.createDecoder(buf);
    const encoder = encoding.createEncoder();
    const messageType = decoding.readVarUint(decoder);
    const messageHandler = messageHandlers[messageType];
    if (messageHandler) {
      messageHandler(encoder, decoder, this);
    } else {
      console.error('Unable to compute message');
    }
    return encoder;
  }

  async forcePersist() {
    return new Promise((resolve, reject) => {
      this.channel
        .push('force_persist', {})
        .receive('ok', () => resolve())
        .receive('error', (err) => reject(err));
    });
  }
}
