import keymirror from 'keymirror';
import { request } from 'js/api/axios';
import { SCHEMAS } from '../api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';
import { callApi } from '../api';

export const types = keymirror({
  ...spreadApiKeys('LOAD_ASSESSMENT_DOC_TEMPLATE_SUMMARIES'),
});

export function list(workspaceId, queryId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(
          types.LOAD_ASSESSMENT_DOC_TEMPLATE_SUMMARIES,
          types
        ),
        request: (_data, token) =>
          request.get(
            `/api/workspaces/${workspaceId}/assessment-doc-template-summaries`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.ASSESSMENT_DOC_TEMPLATE_SUMMARY_ARRAY,
        meta: { queryId },
      },
      dispatch
    );
  };
}
