import styles from './CollaboratorList.module.scss';
import Collaborator from 'js/components/CollaboratorList/Collaborator';
import _ from 'lodash';
import { useMemo } from 'react';

export default function CollaboratorList({ collaborators }) {
  const distinctCollabs = useMemo(() => {
    return _.uniqWith(collaborators, (a, b) => a.username === b.username);
  }, [collaborators]);

  return (
    <div className={styles.CollaboratorList}>
      {distinctCollabs
        .filter((c) => !!c.username)
        .map((c) => (
          <Collaborator collaborator={c} key={c.key} />
        ))}
    </div>
  );
}
