import { SchemaItem } from './SchemaItem';

export class SchemaPickedSet extends SchemaItem {
  constructor(key, entity) {
    super();

    this.key = key;
    this.entity = entity;
  }

  getPositionKey(state) {
    throw new Error('Not implemented');
  }
}
