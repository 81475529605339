import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Icon } from 'js/components';
import AsyncButton from 'js/components/Forms/AsyncButton';

import styles from './AssessmentDashboard.module.scss';
import AssessmentType from 'js/components/Assessment/AssessmentType';

const AssessmentDocTemplateSummaryCard = ({
  summary,
  onArchive = () => {},
}) => {
  const [showDescription, setShowDescription] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) {
      return <div className={styles['no-date']}>&mdash;</div>;
    }
    return format(new Date(dateString), 'MMM d, yyyy');
  };

  const isUnpublished =
    summary.latest_revision_published_at === null ||
    summary.latest_revision_published_at < summary.doc_updated_at;

  const handleArchive = useCallback(
    () => onArchive(summary.id),
    [onArchive, summary.id]
  );

  return (
    <div className={styles['assessment-template']}>
      <div className={styles['template-type']}>
        <AssessmentType type={summary.type} />
      </div>
      <div className={styles['template-info']}>
        <div className={styles['template-name']}>
          <Link to={`assessments/${summary.id}/edit`}>{summary.name}</Link>
        </div>
        <div className={styles['template-author']}>
          Created by Waypointr {summary.author}
        </div>
        <div className={styles['template-dates']}>
          <div
            className={classNames(styles['template-changes-state'], {
              [styles.unpublished]: isUnpublished,
            })}
          >
            {isUnpublished ? 'Unpublished changes' : ''}
          </div>
          <div className={styles['template-dates-table']}>
            <div className={styles['template-dates-table-header']}>
              Last updated
            </div>
            <div className={styles['template-dates-table-header']}>
              Last published
            </div>
            <div className={styles['template-dates-table-body']}>
              {formatDate(summary.doc_updated_at)}
            </div>
            <div className={styles['template-dates-table-body']}>
              {formatDate(summary.latest_revision_published_at)}
            </div>
          </div>
        </div>
        <div className={styles['template-description']}>
          {showDescription ? (
            <>
              <button
                className="btn btn-link"
                onClick={() => setShowDescription(false)}
              >
                Hide description
              </button>
              <Markdown
                className={styles['template-description-markdown']}
                linkTarget="_blank"
              >
                {summary.description || 'No description provided'}
              </Markdown>
            </>
          ) : (
            <button
              className="btn btn-link"
              onClick={() => setShowDescription(true)}
            >
              Show description
            </button>
          )}
        </div>
      </div>
      <div className={styles['template-actions']}>
        <Link
          className="btn btn-icon btn-secondary"
          to={`assessments/${summary.id}/edit`}
        >
          <Icon icon="pencil" />
        </Link>
        <AsyncButton className="btn-icon" onClick={handleArchive}>
          <Icon icon="archive" />
        </AsyncButton>
      </div>
    </div>
  );
};

export default AssessmentDocTemplateSummaryCard;
