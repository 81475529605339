import { Map as YMap } from 'yjs';
import { randomBase32String } from 'js/utils/string';
import { calcPositionBetween } from './utils';
import Big from 'big.js';
import { standardCollator } from 'js/utils/string';
import { getDefaultAnswerSetTypeForQuestionType } from '../answer-set-utils';

const RANDOM_ID_STRING_LENGTH = 10;

// Structure of question reference to answer set: { answerSetId: ID, reverseScale: false}
// function SchemaEntityMapArray() {}
// function SchemaLookup() {}

// const questionDoc = {
//   // other props
//   answerSetId: 'AS-29FJ39LSX3',
//   answerSetConfig: {
//     reverseScale: false,
//     includeNA: null,
//     naText: 'N/A',
//   },
// };

const collator = new Intl.Collator(undefined, { sensitivity: 'base' });

// const answerSetDoc = {
//   id: 'AS-29FJ39LSX3',
//   isStandard: true,
//   name: 'Answer Set',
//   type: 'likert',
//   choices: {
//     id1: { id: 'id1', value: 1, text: 'Strongly Disagree', p: '' },
//     id2: { id: 'id2', value: 2, text: 'Disagree', p: '' },
//     id3: { id: 'id3', value: 3, text: 'Neutral', p: '' },
//     id4: { id: 'id4', value: 4, text: 'Agree', p: '' },
//     id5: { id: 'id5', value: 5, text: 'Strongly Agree', p: '' },
//   },
//   includeNA: false,
//   naText: 'N/A',
// };

// const schemaDefinitionForQuestion = {
//   answerSet: SchemaLookup('answerSetId', 'answerSets'),
//   answerSetId: 'AS-29FJ39LSX3',
//   answerSetConfig: {
//     reverseScale: false,
//     includeNA: null,
//   },
// };

// const schemaDefinitionForAnswerSet = {
//   choices: SchemaEntityMapArray(),
// };

export function setAnswerSetForQuestion(yDoc, questionId, answerSetProps) {
  yDoc.transact(() => {
    const question = yDoc.getMap('elements').get(questionId);
    question.set('answerSet', answerSetProps);
  });
}

export function setAnswerSetPropForQuestion(
  yDoc,
  questionId,
  propName,
  propValue
) {
  yDoc.transact(() => {
    try {
      const answerSetProps = yDoc
        .getMap('elements')
        .getMap(questionId)
        .getMap('answerSet');
      answerSetProps.set(propName, propValue);
    } catch {
      console.warn('Could not set answer set prop', propName, propValue);
    }
  });
}

export function deleteAnswerSet(yDoc, answerSetId) {
  // TODO: clean up references to answer set
  yDoc.getMap('answerSets').delete(answerSetId);
}

export function cloneAnswerSet(yDoc, answerSet, options = {}) {
  const { js: jsAnswerSet, ymap: yMapAnswerSet } = constructNewAnswerSet(
    answerSet,
    options
  );
  const newAnswerSetId = jsAnswerSet.id;

  yDoc.transact(() => {
    const answerSets = yDoc.getMap('answerSets');
    if (answerSets.has(newAnswerSetId)) {
      // TODO: handle duplicate ID
    }
    answerSets.set(newAnswerSetId, yMapAnswerSet);
  });

  return jsAnswerSet;
}

// NOTE: We need to pass both the object and YMap representations because the
//       YMap is not fully readable until the changes are committed to a YDoc
function constructNewAnswerSet(answerSet, options) {
  options = {
    retainStandardFields: false,
    preserveChoiceIds: false,
    name: null,
    ...options,
  };

  const overrides = options.retainStandardFields
    ? {}
    : {
        id: `AS-${randomBase32String(RANDOM_ID_STRING_LENGTH)}`,
        isStandard: false,
        name: options.name ?? answerSet.name + ' (Copy)',
      };

  const newAnswerSet = {
    ...answerSet,
    ...overrides,
  };

  const newYMapAnswerSet = new YMap(
    Object.entries({
      ...newAnswerSet,
      choices: new YMap(
        Object.entries(newAnswerSet.choices).map(([_key, choice]) => {
          const newId = options.preserveChoiceIds
            ? choice.id
            : `AC-${randomBase32String(RANDOM_ID_STRING_LENGTH)}`;
          return [
            newId,
            new YMap(
              Object.entries({
                ...choice,
                id: newId,
              })
            ),
          ];
        })
      ),
    })
  );

  return { js: newAnswerSet, ymap: newYMapAnswerSet };
}

export function createCustomAnswerSet(yDoc, questionId, answerSetProps) {
  const defaultProps = {
    name: null,
    isStandard: false,
    isPublished: false,
    choices: {},
  };
  const answerSetId = `AS-${randomBase32String(RANDOM_ID_STRING_LENGTH)}`;
  const questionType = yDoc.getMap('elements').get(questionId)?.get('type');
  const type = getDefaultAnswerSetTypeForQuestionType(questionType);

  if (type == null) return;

  answerSetProps = {
    ...defaultProps,
    ...answerSetProps,
    id: answerSetId,
    type,
    choices: new YMap(
      Object.entries(answerSetProps.choices ?? defaultProps.choices).map(
        ([_key, choice], i, arr) => {
          const newId = `AC-${randomBase32String(RANDOM_ID_STRING_LENGTH)}`;
          const position = choice.p ?? '' + (i + 1) / (arr.length + 1);
          return [
            newId,
            new YMap(
              Object.entries({
                ...choice,
                id: newId,
                p: position,
              })
            ),
          ];
        }
      )
    ),
  };

  yDoc.transact(() => {
    const questions = yDoc.getMap('elements');
    const question = questions.get(questionId);
    if (!(question instanceof YMap)) {
      console.warn('Question not found');
      return;
    }

    const answerSets = yDoc.getMap('answerSets');
    answerSets.set(answerSetId, new YMap(Object.entries(answerSetProps)));
    question.set('answerSetId', answerSetId);
  });

  return answerSetId;
}

export function updateAnswerSet(yDoc, answerSetId, updates) {
  if (updates.hasOwnProperty('choices')) {
    // use createAnswerSetChoice and updateAnswerSetChoice instead
    console.warn('Cannot set choice properties directly');
    return;
  }

  yDoc.transact(() => {
    const answerSet = yDoc.getMap('answerSets').get(answerSetId);
    for (const [key, value] of Object.entries(updates)) {
      answerSet.set(key, value);
    }
  });
}

export function createAnswerSetChoice(yDoc, answerSetId, props) {
  const choiceId = `AC-${randomBase32String(RANDOM_ID_STRING_LENGTH)}`;

  yDoc.transact(() => {
    const answerSet = yDoc.getMap('answerSets').get(answerSetId);
    const choices = answerSet.get('choices');
    const choiceArray = getChoiceArray(choices.toJSON());
    const newChoiceFields = {
      ...getNewChoiceFields(choiceArray),
      ...props,
      id: choiceId,
    };

    const newChoice = new YMap(Object.entries(newChoiceFields));

    choices.set(choiceId, newChoice);
  });
}

function getChoiceArray(choices) {
  return Array.from(Object.values(choices)).sort(
    (a, b) =>
      collator.compare(a.p, b.p) ||
      collator.compare(a.text, b.text) ||
      collator.compare(a.id, b.id)
  );
}

function getNewChoiceFields(currentChoices) {
  // Choices are assumed to be assorted by position
  const regex = /^Option (\d+)\b/;

  const { maxChoiceNumber, maxScore, minScore } = currentChoices.reduce(
    (acc, { text, value }) => {
      const matches = regex.test(text);
      return {
        maxChoiceNumber:
          matches.length === 2
            ? Math.max(acc.choiceNumber, parseInt(matches[1]))
            : acc.maxChoiceNumber,
        minScore: !isNaN(value) ? Math.min(acc.minScore, +value) : acc.minScore,
        maxScore: !isNaN(value) ? Math.max(acc.maxScore, +value) : acc.maxScore,
      };
    },
    { maxChoiceNumber: null, maxScore: null, minScore: null }
  );

  const scoreIncrement =
    currentChoices.length > 1 &&
    +currentChoices[currentChoices.length - 2]?.value >
      +currentChoices[currentChoices.length - 1]?.value
      ? -1
      : 1;

  const score =
    '' +
    (scoreIncrement < 0
      ? Math.floor(minScore + scoreIncrement)
      : Math.ceil(maxScore + scoreIncrement));

  return {
    p: calcPositionBetween(
      Big(currentChoices[currentChoices.length - 1]?.p ?? 0),
      Big(1)
    ).toString(),
    value: score,
    text:
      'Choice ' + (Math.max(maxChoiceNumber ?? 0, currentChoices.length) + 1),
  };
}

export function updateAnswerSetChoice(yDoc, answerSetId, choiceId, updates) {
  yDoc.transact(() => {
    const answerSet = yDoc.getMap('answerSets').get(answerSetId);
    const choice = answerSet.get('choices').get(choiceId);

    for (const [key, value] of Object.entries(updates)) {
      choice.set(key, value);
    }
  });
}

export function deleteAnswerSetChoice(yDoc, answerSetId, choiceId) {
  yDoc.transact(() => {
    const answerSet = yDoc.getMap('answerSets').get(answerSetId);
    const choices = answerSet?.get('choices');
    choices?.delete(choiceId);
  });
}

function findChoiceWithNeighbors(choices, targetChoiceId) {
  const orderedChoices = Object.values(choices).sort((a, b) =>
    standardCollator.compare(a.p, b.p)
  );
  const result = {
    before: null,
    target: null,
    after: null,
  };

  for (let i = 0; i < orderedChoices.length; i++) {
    const choice = orderedChoices[i];

    if (choice.id === targetChoiceId) {
      result.target = choice;
    } else if (result.target === null) {
      result.before = choice;
    } else {
      result.after = choice;
      break;
    }

    // never found the target
    if (i === orderedChoices.length - 1 && result.target === null) {
      result.before = null;
    }
  }

  return result;
}

export function moveAnswerSetChoiceBefore(
  yDoc,
  answerSetId,
  sourceChoiceId,
  targetChoiceId
) {
  const choices = yDoc.get('answerSets')?.get(answerSetId)?.get('choices');
  const choice = choices.get(sourceChoiceId);

  // didn't find the choice or trying to move it before itself
  if (choices == null || choice == null || sourceChoiceId === targetChoiceId) {
    return;
  }

  const { before, target } = findChoiceWithNeighbors(
    choices.toJSON(),
    targetChoiceId
  );

  // target not found || it already is immediately before the target
  if (target === null || sourceChoiceId === before?.id) {
    return;
  }

  const newPosition = calcPositionBetween(Big(before?.p ?? 0), Big(target.p));
  choice.set('p', newPosition.toString());
}

export function moveAnswerSetChoiceAfter(
  yDoc,
  answerSetId,
  sourceChoiceId,
  targetChoiceId
) {
  const choices = yDoc.get('answerSets')?.get(answerSetId)?.get('choices');
  const choice = choices.get(sourceChoiceId);

  // didn't find the choice or trying to move it after itself
  if (choices == null || choice == null || sourceChoiceId === targetChoiceId) {
    return;
  }

  const { target, after } = findChoiceWithNeighbors(
    choices.toJSON(),
    targetChoiceId
  );

  // target not found || it already is immediately after the target
  if (target === null || sourceChoiceId === after?.id) {
    return;
  }

  const newPosition = calcPositionBetween(Big(after?.p ?? 1), Big(target.p));
  choice.set('p', newPosition.toString());
}

export function clearAnswerSet(yDoc, questionId) {
  const question = yDoc.getMap('elements').get(questionId);
  if (question) {
    question.set('answerSetId', null);
  }
}

export function assignAnswerSetToQuestion(yDoc, questionId, answerSet) {
  if (answerSet === null) {
    clearAnswerSet(yDoc, questionId);
    return;
  }

  yDoc.transact(() => {
    const answerSets = yDoc.getMap('answerSets');
    const foundAnswerSet = answerSets.get(answerSet.id);

    if (!foundAnswerSet) {
      // need to clone the answer set to this document
      const { js: jsAnswerSet, ymap: yMapAnswerSet } = constructNewAnswerSet(
        answerSet,
        {
          retainStandardFields: true,
        }
      );

      if (answerSets.has(jsAnswerSet.id)) {
        // TODO: handle duplicate ID
      }

      answerSets.set(jsAnswerSet.id, yMapAnswerSet);
      answerSet = jsAnswerSet;
    }

    const question = yDoc.getMap('elements').get(questionId);
    question.set('answerSetId', answerSet.id);
  });
}
