import React from 'react';
import ListChoiceEditor from './ListChoiceEditor';
import { standardColor } from 'js/utils/color';
import ValueChoice from './ValueChoice';

export function MultiSelectChoiceEditor({ onAddChoice, ...props }) {
  const handleAddChoice = (choiceLength) => {
    const nextNumber = choiceLength + 1;
    const text = `Choice ${nextNumber}`;
    const newChoice = {
      value: text,
      text,
    };
    onAddChoice(newChoice);
  };

  return (
    <ListChoiceEditor
      color={standardColor}
      {...props}
      choiceTag={ValueChoice}
      onAddChoice={handleAddChoice}
    />
  );
}

export default MultiSelectChoiceEditor;
