// WARN: This is still a work in progress and will not work with arbitrary schema structures. It currently support:
// - Singleton entities with a single level of properties
// - Maps of entities keyed by the entity ID

import { createMutex } from 'lib0/mutex';
import { ObservableV2 } from 'lib0/observable';
import { debounce } from 'lodash';
import { Map as YMap, UndoManager as YUndoManager } from 'yjs';

import {
  Schema,
  SchemaEntity,
  SchemaExclusiveArray,
  SchemaSingletonEntity,
  SchemaSet,
} from './Schema';
import { SchemaReducer } from './Schema/reducers/SchemaReducer';

export const CURRENT_DOC_FORMAT_VERSION = '0.1';

function getOrInitializeMap(map, key) {
  let nestedMap = map.get(key);
  if (!nestedMap) {
    nestedMap = new YMap();
    map.set(key, nestedMap);
  }
  return nestedMap;
}

function updateMap(ymap, newState, customPropHandlers = {}) {
  Object.entries(newState).forEach(([key, value]) => {
    const customHandler = customPropHandlers[key];
    if (customHandler) {
      customHandler(ymap, key, value);
    } else if (value != null && typeof value === 'object') {
      updateMap(getOrInitializeMap(ymap, key), value ?? '');
    } else {
      ymap.set(key, value);
    }
  });
}

export function initializeYDoc(doc, initialState, origin) {
  const meta = doc.getMap('meta');
  const config = doc.getMap('config');
  const assessment = doc.getMap('assessment');
  const pages = doc.getMap('pages');
  const elements = doc.getMap('elements');
  const answerSets = doc.getMap('answerSets');
  const answerChoices = doc.getMap('answerChoices');
  const views = doc.getMap('views');

  doc.transact(() => {
    Object.entries(initialState).forEach(([key, value]) => {
      switch (key) {
        case 'pages':
          value.forEach((p, pIndex) => {
            const pMap = getOrInitializeMap(pages, p.guid);
            updateMap(pMap, p, {
              elements: (_map, _elementsKey, elementsArray) => {
                elementsArray.forEach((e, eIndex) => {
                  const eMap = getOrInitializeMap(elements, e.guid);
                  updateMap(eMap, e, {
                    // position: () => {},
                  });
                  // qMap.set('position', { t: qs.guid, p: qIndex });
                });
              },
              // position: () => {},
            });
            // qsMap.set('position', qsIndex);
          });
          break;

        case 'answerSets':
          updateMap(answerSets, value);
          break;

        case 'answerChoices':
          updateMap(answerChoices, value);
          break;

        case 'assessment':
          updateMap(assessment, value);
          break;

        case 'meta':
          updateMap(meta, value);
          break;

        case 'config':
          updateMap(config, value, {
            categories: (_doc, _key, value) => {
              config.set('categories', value);
            },
          });
          break;

        case 'views':
          updateMap(views, value);
          break;

        default:
          meta.set(key, value);
          break;
      }
    });
  }, origin);
}

export function clearUnusedCategories(doc) {
  const catEntries = Object.entries(doc.getMap('config').get('categories'));
  const newCats = {};
  const questionSets = [...doc.getMap('questionSets').values()];

  let isDiff = false;
  catEntries.forEach(([key, cat]) => {
    if (
      cat.explicitlyCreated ||
      questionSets.some((qs) => qs.get('category') === key)
    ) {
      newCats[key] = cat;
    } else {
      isDiff = true;
    }
  });

  if (isDiff) {
    doc.getMap('config').set('categories', newCats);
  }
}

export function createBinding(doc) {
  return new Binding(doc);
}

export class Binding extends ObservableV2 {
  constructor(doc) {
    super();

    this.doc = doc;
    this._mux = createMutex();

    const answerSetsEntity = new SchemaEntity('answerSets', {});
    const answerSetsSet = new SchemaSet(answerSetsEntity);

    const elementsEntity = new SchemaEntity(
      'elements',
      { answerSet: answerSetsEntity },
      { idAttribute: 'guid' }
    );
    const elementsArray = new SchemaExclusiveArray(
      'pageElements',
      elementsEntity
    );
    const pagesEntity = new SchemaEntity(
      'pages',
      { elements: elementsArray },
      { idAttribute: 'guid' }
    );
    const pagesArray = new SchemaExclusiveArray('assessmentPages', pagesEntity);
    const metaSingleton = new SchemaSingletonEntity('meta');
    const configSingleton = new SchemaSingletonEntity('config', {});
    const assessmentSingleton = new SchemaSingletonEntity('assessment');

    const assessmentTemplateSingleton = new SchemaSingletonEntity(
      'assessmentTemplate',
      {
        assessment: assessmentSingleton,
        meta: metaSingleton,
        config: configSingleton,
        pages: pagesArray,
        answerSets: answerSetsSet,
      }
    );

    this._schema = new Schema(assessmentTemplateSingleton, this.doc);

    this._schemaReducer = new SchemaReducer(this._schema, {});

    this._debouncedEmit = debounce((event, args) => this.emit(event, args), 10);

    this._types = {
      meta: doc.getMap('meta'),
      config: doc.getMap('config'),
      assessment: doc.getMap('assessment'),
      pages: doc.getMap('pages'),
      elements: doc.getMap('elements'),
      answerSets: doc.getMap('answerSets'),
    };

    this.undoManager = new YUndoManager(Object.values(this._types));

    this._types.elements.observeDeep(entityUpdateHandler(elementsEntity, this));
    this._types.pages.observeDeep(entityUpdateHandler(pagesEntity, this));
    this._types.meta.observeDeep(singletonUpdateHandler(metaSingleton, this));
    this._types.assessment.observeDeep(
      singletonUpdateHandler(assessmentSingleton, this)
    );
    this._types.config.observeDeep(
      singletonUpdateHandler(configSingleton, this)
    );

    this._types.answerSets.observeDeep(
      entityUpdateHandler(answerSetsEntity, this)
    );

    this._state = {};
    this._entities = {};

    // TODO: hook up initial reducer state loading
  }

  getState() {
    return this._schemaReducer.getState();
  }

  // beforeAllTransactions() {}
  // afterAllTransactions() {}
}

// TODO: clean this up

function entityUpdateHandler(schemaEntity, $this) {
  return (events, _transaction) => {
    events.forEach((e) => {
      if (e.path.length === 0) {
        // TODO: look at the changes to make sure it's not a delete
        // console.log({
        //   e,
        //   json: e.target.toJSON(),
        //   c: e.changes,
        //   p: e.path,
        // });

        e.changes.keys.forEach((value, key) => {
          switch (value.action) {
            case 'add':
              {
                const patch = e.target.get(key).toJSON();
                $this._schemaReducer.dispatch({
                  type: 'patch',
                  target: schemaEntity.key,
                  id: key,
                  patch,
                });
              }

              break;

            case 'delete':
              $this._schemaReducer.dispatch({
                type: 'delete',
                target: schemaEntity.key,
                id: key,
              });

              break;

            default:
              break;
          }
        });
        // TODO: handle possible removal
      } else if (e.path.length === 1) {
        const [id] = e.path;

        const patch = {};
        for (const [key, _value] of e.changes.keys) {
          // TODO: handle deleted keys
          patch[key] = e.target.get(key);
        }

        $this._schemaReducer.dispatch({
          type: 'patch',
          target: schemaEntity.key,
          id,
          patch,
        });
      } else if (e.path.length > 1) {
        const [id, prop, ..._deepProps] = e.path;
        const patch = { [prop]: e.currentTarget.get(id).get(prop).toJSON() };
        $this._schemaReducer.dispatch({
          type: 'patch',
          target: schemaEntity.key,
          id,
          patch,
        });
      }
    });

    $this._debouncedEmit('update', [$this._schemaReducer.getState()]);
  };
}

function singletonUpdateHandler(schemaEntity, $this) {
  return (events, _transaction) => {
    events.forEach((e) => {
      if (e.path.length === 0) {
        const id = schemaEntity.key;

        const patch = {};
        const modifiedPatch = {};
        for (const [key, { action }] of e.changes.keys) {
          // TODO: handle deleted keys
          patch[key] = e.target.get(key);
          modifiedPatch[key] = { action, value: e.target.get(key) };
        }

        // console.log({
        //   e,
        //   json: e.target.toJSON(),
        //   c: e.changes,
        //   p: e.path,
        //   op: patch,
        //   mp: modifiedPatch,
        // });

        $this._schemaReducer.dispatch({
          type: 'patch',
          target: schemaEntity.key,
          id,
          patch,
        });
      } else {
        const [prop, ..._deepProps] = e.path;
        const patch = { [prop]: e.currentTarget.get(prop).toJSON() };
        $this._schemaReducer.dispatch({
          type: 'patch',
          target: schemaEntity.key,
          id: schemaEntity.key,
          patch,
        });
      }
    });

    $this._debouncedEmit('update', [$this._schemaReducer.getState()]);
  };
}

// function setUpdateHandler(schemaEntity, $this) {
//   return (events, _transaction) => {
//     events.forEach((e) => {
//       if (e.path.length === 0) {
//         const id = schemaEntity.key;

//         const patch = {};
//         const modifiedPatch = {};
//         for (const [key, { action }] of e.changes.keys) {
//           // TODO: handle deleted keys
//           patch[key] = e.target.get(key);
//           modifiedPatch[key] = { action, value: e.target.get(key) };
//         }

//         // console.log({
//         //   e,
//         //   json: e.target.toJSON(),
//         //   c: e.changes,
//         //   p: e.path,
//         //   op: patch,
//         //   mp: modifiedPatch,
//         // });

//         $this._schemaReducer.dispatch({
//           type: 'patch',
//           target: schemaEntity.key,
//           id,
//           patch,
//         });
//       }
//     });
//   };
// }
