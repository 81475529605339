import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  assignWith,
  cloneDeep,
  forEach,
  fromPairs,
  isString,
  map,
  mapValues,
  pick,
  uniqWith,
} from 'lodash';
import classnames from 'classnames';

import { titleCase } from 'js/utils/string';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CustomInput,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { PanelHeader, Icon } from 'js/components';
import ConfigureQuestionSet from './ConfigureQuestionSet';
import ConfigurationOverview from './ConfigurationOverview';

const generateCategoryReference = (template) => {
  const baseCategoryReference = cloneDeep(template.config?.categories ?? {});

  const extractedCategoryReference = fromPairs(
    uniqWith(
      template.assessment.question_sets,
      (a, b) => a.category === b.category
    ).map((qs) => {
      const uncategorized = !isString(qs.category);

      if (uncategorized) {
        return ['uncategorized', { display_name: 'Uncategorized' }];
      }

      return [qs.category, { display_name: titleCase(qs.category) }];
    })
  );

  let paletteIndex = 0;

  // This mess preserves the palette based on the ordering from the categories,
  // but only shows the categories that are actually used in the template
  const fullCategoryReference = assignWith(
    baseCategoryReference,
    extractedCategoryReference,
    (obj, src) => {
      return obj === undefined ? src : obj;
    }
  );

  forEach(fullCategoryReference, (val) => {
    val.paletteClass = `palette-cat-${++paletteIndex}`;
  });

  const categoryReference = pick(
    fullCategoryReference,
    Object.keys(extractedCategoryReference)
  );

  return categoryReference;
};

const isRemoved = (questionSet, categoryFilters) => {
  const category = questionSet.category || 'uncategorized';

  return categoryFilters[category] === false;
};

function baseGenerateAssessmentForCreate(template, categoryFilters) {
  return {
    ...template.assessment,
    question_sets: template.assessment.question_sets.filter(
      (qs) => !isRemoved(qs, categoryFilters)
    ),
    config: template.config || null,
    type: template.type,
  };
}

const ConfigureSurveyAssessment = ({
  template,
  team,
  workspaceId,
  creating,
  generateAssessmentForCreate = baseGenerateAssessmentForCreate,
  onCreateAssessment,
}) => {
  const categoryReference = generateCategoryReference(template);

  const [categoryFilters, setCategoryFilters] = useState(
    mapValues(categoryReference, () => true)
  );

  const toggleCategoryFilter = (categoryKey) => {
    setCategoryFilters({
      ...categoryFilters,
      [categoryKey]: !categoryFilters[categoryKey],
    });
  };

  const handleCreateAssessment = (e) => {
    e.preventDefault();

    const assessment = generateAssessmentForCreate(template, categoryFilters);

    onCreateAssessment(assessment);
  };

  const questionCount = template.assessment.question_sets.reduce(
    (count, questionSet) => {
      const category = questionSet.category || 'uncategorized';

      if (categoryFilters[category] === false) {
        return count;
      }

      return count + questionSet.questions.length;
    },
    0
  );

  return (
    <div id="configure-survey-assessment">
      <PanelHeader size="sm" />
      <div className="content">
        <div className="row">
          <div className="col-9">
            <Card className="configure-survey-assessment-header">
              <CardHeader>
                <label>Configure</label>
                <CardTitle tag="h5">
                  {template.name}
                  <small>
                    for{' '}
                    <Link to={`/w/${workspaceId}/teams/${team.id}/dashboard`}>
                      {team.name}
                    </Link>
                  </small>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <p>
                  Which questions you would like to include in this assessment?
                  You will still have tracking across multiple assessments for a
                  team even if you don't ask the same questions every time.
                </p>

                <label>Question Sets</label>
                <ol className="question-sets">
                  {template.assessment.question_sets.map((qs) => {
                    const removed = isRemoved(qs, categoryFilters);

                    return (
                      <ConfigureQuestionSet
                        key={qs.key}
                        tag="li"
                        questionSet={qs}
                        removed={isRemoved(qs, categoryFilters)}
                        categoryReference={categoryReference}
                        className={classnames({ removed })}
                      />
                    );
                  })}
                </ol>
                <div>
                  <form onSubmit={handleCreateAssessment}>
                    <div className="form-actions">
                      <Link
                        disabled={creating}
                        className="btn btn-secondary"
                        to={`/w/${workspaceId}/teams/${team.id}/assessments/new`}
                      >
                        <Icon
                          icon={['fas', 'arrow-left']}
                          className="btn-icon-left"
                        />
                        Back
                      </Link>
                      <Button
                        type="submit"
                        disabled={creating || questionCount === 0}
                        color="primary"
                      >
                        {creating ? (
                          <Icon
                            icon={['far', 'circle-notch']}
                            className="btn-icon-left"
                            spin
                          />
                        ) : (
                          <Icon
                            icon={['far', 'poll']}
                            className="btn-icon-left"
                          />
                        )}
                        Start
                      </Button>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-3">
            <div className="sticky">
              <ConfigurationOverview questionCount={questionCount} />
              <Card>
                <CardHeader>
                  <CardTitle tag="h6">Topics</CardTitle>
                </CardHeader>
                <CardBody>
                  {map(categoryReference, (value, key) => {
                    const checked = categoryFilters[key] === true;

                    return (
                      <div
                        key={key}
                        className={classnames({
                          'category-selector': true,
                          muted: !checked,
                        })}
                      >
                        <CustomInput
                          id={`category-filter-${key}`}
                          type="switch"
                          checked={checked}
                          name={key}
                          onChange={toggleCategoryFilter.bind(null, key)}
                        >
                          <div
                            className={`${value.paletteClass} category-pallete`}
                          />
                          {value.display_name}
                        </CustomInput>
                      </div>
                    );
                  })}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfigureSurveyAssessment.defaultProps = {
  onCreateAssessment: () => {},
  creating: false,
};

ConfigureSurveyAssessment.propTypes = {
  template: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  workspaceId: PropTypes.number.isRequired,
  onCreateAssessment: PropTypes.func,
  creating: PropTypes.bool,
};

export default ConfigureSurveyAssessment;
