import React from 'react';
import BaseAnswerSet from '../BaseAnswerSet';
import ValueListSparkline from './ValueListSparkline';
import ValueListChoiceList from './ValueListChoiceList';

export function ValueListAnswerSet({ answerSet, onChoiceCreate, ...props }) {
  const handleAddChoice = () => {
    const choiceCount = Object.keys(answerSet?.choices ?? {}).length;
    const text = 'Choice ' + (choiceCount + 1);

    onChoiceCreate(answerSet.id, {
      text,
      value: text,
    });
  };

  return (
    <BaseAnswerSet
      {...props}
      answerSet={answerSet}
      allowNaOption={false}
      onChoiceCreate={handleAddChoice}
      choiceListComponent={ValueListChoiceList}
      sparklineComponent={ValueListSparkline}
    />
  );
}

export default ValueListAnswerSet;
