import { useMemo } from 'react';
import { isValidAnswerSetTypeForQuestion } from './answer-set-utils';

export function useAnswerSetStats(assessment) {
  return useMemo(() => {
    if (!assessment) return {};

    const { answerSets = {} } = assessment;
    const initialAccumulator = Object.fromEntries(
      Object.entries(answerSets).map(([id, _]) => [id, { usedBy: [] }])
    );

    const results =
      assessment?.pages.reduce(
        (pAcc, { elements }) =>
          elements.reduce((eAcc, e) => {
            if (e.elementType !== 'question') {
              return eAcc;
            }

            if (
              e.answerSet &&
              isValidAnswerSetTypeForQuestion(e.type, e.answerSet.type)
            ) {
              eAcc[e.answerSet.id].usedBy.push(e.guid);
            }
            return eAcc;
          }, pAcc),
        initialAccumulator
      ) ?? initialAccumulator;
    return results;
  }, [assessment]);
}

export default useAnswerSetStats;
