import React, { useCallback, useEffect, useState } from 'react';
import { noop } from 'lodash';
import { connect, useSelector, useStore } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SCHEMAS } from 'js/api/schemas';
import { denormalize } from 'normalizr';

import {
  list as listAssessmentTemplates,
  listSubscriptions,
} from 'js/actions/assessment-template-actions';
import { getEngineStrategies } from 'js/engines';
import {
  QueryBase,
  selectAssessmentTemplatesQuery,
} from 'js/store/reducers/queries';

import CreateAssessmentsComponent from '../components/CreateAssessmentsComponent';

import templates from 'js/data/assessment-templates';

function CreateAssessmentsContainer({
  match,
  history,
  createAssessment,
  listAssessmentTemplates,
  listSubscriptions,
  templates,
  queryId,
  subscriptionsQueryId,
}) {
  const { workspaceId, teamId } = match.params;
  const store = useStore();

  const fetchAssessmentTemplates = useCallback(() => {
    listAssessmentTemplates(queryId).catch(noop);
  }, [queryId, listAssessmentTemplates]);

  useEffect(() => fetchAssessmentTemplates(), [fetchAssessmentTemplates]);

  // fetch subscriptions
  const subscriptionsQuery = useSelector(
    (state) => state.queries[subscriptionsQueryId]
  );

  const refreshSubscriptions = useCallback(
    () => listSubscriptions(subscriptionsQueryId),
    [listSubscriptions, subscriptionsQueryId]
  );

  useEffect(() => {
    refreshSubscriptions();
  }, [refreshSubscriptions]);

  const [subscriptions, setSubscriptions] = useState(null);
  useEffect(() => {
    if (subscriptionsQuery?.isLoading === false) {
      setSubscriptions(
        denormalize(
          subscriptionsQuery?.result || [],
          SCHEMAS.ASSESSMENT_DOC_TEMPLATE_SUBSCRIPTION_ARRAY,
          store.getState().entities
        )
      );
    }
  }, [
    store,
    subscriptionsQuery,
    subscriptionsQuery?.isLoading,
    subscriptionsQuery?.result,
  ]);

  const handleCreateAssessment = useCallback(
    (template) => {
      // const { match, createAssessment, history } = this.props;

      createAssessment({
        type: template.type,
        ...template.assessment,
        team_id: teamId,
      }).then((assessment) => {
        const strategies = getEngineStrategies(assessment);
        history.replace(strategies.buildPostCreateRedirectPath(assessment));
      });
    },
    [teamId, history, createAssessment]
  );

  templates = [...(subscriptions ?? []), ...templates];

  return (
    <CreateAssessmentsComponent
      templates={templates}
      onCreateAssessment={handleCreateAssessment}
    />
  );
}

export default withRouter(
  connect(
    (
      store,
      {
        match: {
          params: { workspaceId },
        },
      }
    ) => {
      const localIds = selectAssessmentTemplatesQuery(
        store,
        workspaceId
      ).data.map((at) => at.local_id);
      return {
        queryId: `${QueryBase.AssessmentTemplates}-${workspaceId}`,
        subscriptionsQueryId: `${QueryBase.AssessmentTemplates}-subscriptions-${workspaceId}`,
        templates: templates.filter(
          (x) => x.is_public || localIds.indexOf(x.id) > -1
        ),
      };
    },
    {
      createAssessment: (assessment) => {
        const { create } = getEngineStrategies(assessment);

        return create(assessment);
      },
      listAssessmentTemplates,
      listSubscriptions,
    }
  )(CreateAssessmentsContainer)
);
