import React from 'react';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

import { Icon } from 'js/components';

import styles from './EditorErrors.module.scss';

function ErrorGroup({ title, errors }) {
  return (
    <div className={styles['error-group']}>
      {title}
      <ul className={styles['error-group-list']}>
        {errors.map((error, i) => (
          <li key={i}>
            <Icon
              size="sm"
              icon={faExclamationTriangle}
              className="icon-left text-danger"
            />
            {error.message}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ErrorGroup;
