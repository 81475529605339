import { useRef, useState } from 'react';

import { useDoc } from 'js/ydoc';

export function useAssessment(createBinding) {
  const doc = useDoc();
  const binding = useRef(null);
  const [assessment, setAssessment] = useState(null);
  if (!binding.current) {
    binding.current = createBinding(doc);
    binding.current.on('update', (newState) => {
      setAssessment(binding.current.getState());
    });
  }

  // const [assessment, dispatch] = useReducer(
  //   Binding.reducer,
  //   Binding.initialize(doc)
  // );

  return { assessment, binding: binding.current };
}

export default useAssessment;
