export function isImmutable(object) {
  return !!(
    object &&
    typeof object.hasOwnProperty === 'function' &&
    (object.hasOwnProperty('__ownerID') || // Immutable.Map
      (object._map && object._map.hasOwnProperty('__ownerID')))
  ); // Immutable.Record
}

export function getAttributeGetter(idAttribute) {
  return (input) =>
    isImmutable(input) ? input.get(idAttribute) : input[idAttribute];
}
