import React, { useMemo } from 'react';
import { trimEnd } from 'lodash';

import { Link, useRouteMatch } from 'react-router-dom';
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import { Icon, ReplaceLink, LoadingMessage, PanelHeader } from 'js/components';

import ArchivedTemplateSummaryList from './ArchivedTemplateSummaryList';
import ActiveTemplateSummaryList from './ActiveTemplateSummaryList';

const AssessmentsDashboardComponent = ({
  summaries,
  canModifyAssessmentTemplates = true,
  workspace,
  summariesQuery,
  onRestoreTemplate = () => {},
  onArchiveTemplate = () => {},
}) => {
  const match = useRouteMatch();

  const searchParams = new URLSearchParams(window.location.search);
  const showArchived = searchParams.has('archived');

  const { activeSummaries, archivedSummaries } = useMemo(
    () =>
      (summaries ?? []).reduce(
        (acc, summary) => {
          if (summary.archived_at !== null) {
            acc.archivedSummaries.push(summary);
          } else {
            acc.activeSummaries.push(summary);
          }
          return acc;
        },
        { activeSummaries: [], archivedSummaries: [] }
      ),
    [summaries]
  );

  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-body-no-label px-4">
          <div className="content-fluid">
            <div className="row">
              <div className="col-12 col-md-9">
                <h1>Your Assessment Templates</h1>
              </div>
              <div className="panel-actions col-12 col-md-3">
                <Link
                  className="btn btn-primary create-link"
                  to={`assessments/new`}
                >
                  <Icon icon="plus" className="btn-icon-left" /> Create
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Nav className="tab-nav nav-underline">
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={!searchParams.has('archived')}
                data-view="active"
                to={`${trimEnd(match.url, '/')}`}
              >
                Active
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={searchParams.has('archived')}
                data-view="results"
                to={`${trimEnd(match.url, '/')}?archived`}
              >
                Archived
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </PanelHeader>
      <div className="content">
        <Card>
          <CardBody>
            {!!summariesQuery?.isLoading && (
              <>
                {summaries === null ? (
                  <LoadingMessage iconSize="2x">
                    Loading data&hellip;
                  </LoadingMessage>
                ) : (
                  <div className="loading"></div>
                )}
              </>
            )}
            {!!summariesQuery?.error && (
              <div className="danger">
                Uh oh, looks like there was a problem loading the data
              </div>
            )}
            {showArchived ? (
              <>
                {archivedSummaries.length > 0 ? (
                  <ArchivedTemplateSummaryList
                    summaries={archivedSummaries}
                    onRestoreTemplate={onRestoreTemplate}
                  />
                ) : (
                  <div className="media text-muted mt-4 mb-4">
                    <Icon
                      icon={['fal', 'spider-web']}
                      size="4x"
                      className="mr-3"
                      fixedWidth
                    />
                    <div className="media-body">
                      <p>You don't have any archived templates.</p>
                      <p>
                        Archiving a template makes it unavailable for creating
                        new assessments. You can restore it at any time.
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {activeSummaries.length > 0 ? (
                  <ActiveTemplateSummaryList
                    templateSummaries={activeSummaries}
                    onArchiveTemplate={onArchiveTemplate}
                  />
                ) : (
                  <div className="media text-muted mt-4 mb-4">
                    <Icon
                      icon={['far', 'star-exclamation']}
                      size="4x"
                      className="mr-3"
                      fixedWidth
                    />
                    <div className="media-body">
                      <p>
                        You don't have any{' '}
                        {archivedSummaries.length > 0
                          ? 'active assessment templates'
                          : 'assessment templates yet'}
                        .
                      </p>
                      <p>
                        Creating an assessment template allows you run custom
                        assessments for your teams.
                      </p>
                      {canModifyAssessmentTemplates && (
                        <div>
                          <Link
                            data-test="create-team-link"
                            className="btn btn-primary create-link"
                            to={`/w/${workspace.id}/assessments/new`}
                          >
                            <Icon icon="plus" className="btn-icon-left" />{' '}
                            Create an Assessment Template
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            {/* {filteredSummaries.map((summary) => (
              <AssessmentDocTemplateSummaryCard
                key={summary.id}
                summary={summary}
              />
            ))} */}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AssessmentsDashboardComponent;
