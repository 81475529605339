import AssessmentTypeGlyph from 'js/components/Assessment/AssessmentTypeGlyph';

const AssessmentTypeName = ({ type }) => {
  switch (type) {
    case 'live-assessment':
    case 'live':
      return 'Live';

    case 'survey':
      return 'Survey';

    default:
      return type;
  }
};

const AssessmentType = ({ type }) => {
  return (
    <div className="assessment-type">
      <AssessmentTypeGlyph type={type} />
      <div className="assessment-type-name">
        <AssessmentTypeName type={type} />
      </div>
    </div>
  );
};

export default AssessmentType;
