import React, { useMemo } from 'react';
import styles from '../Editor.module.scss';

export default function DropIndicator({ bottom, top }) {
  const classNames = useMemo(() => {
    const classNames = [styles.dropInd];
    if (bottom) classNames.push(styles.bottom);
    if (top) classNames.push(styles.top);
    return classNames;
  }, [bottom, top]);

  return <div className={classNames.join(' ')}></div>;
}
