import * as React from 'react';
import PropTypes from 'prop-types';

import { Route, Switch } from 'react-router-dom';

import { FullPageLoading } from 'js/components';
import { AssessmentRun, AssessmentReview, AssessmentComplete } from './Run';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';

const SurveyAssessmentRunComponent = ({
  participant,
  assessment,
  onRespond,
  onUnrespond,
  onClearSession,
  match,
  isPreview,
}) => {
  const firstId = assessment?.question_sets[0]?.id;
  return assessment ? (
    <Switch>
      <Route exact path={match.path}>
        <Redirect to={`${_.trimEnd(match.url, '/')}/${firstId}`} />
      </Route>
      <Route
        path={`${match.path}/review`}
        render={(routeProps) => (
          <AssessmentReview
            {...routeProps}
            participant={participant}
            assessment={assessment}
          />
        )}
      />
      <Route
        path={`${match.path}/complete`}
        render={(routeProps) => (
          <AssessmentComplete
            {...routeProps}
            participant={participant}
            assessment={assessment}
            onClearSession={onClearSession}
            isPreview={isPreview}
          />
        )}
      />
      <Route
        path={`${match.path}/:questionSetId?`}
        render={(routeProps) => (
          <AssessmentRun
            {...routeProps}
            participant={participant}
            id={assessment.id}
            assessment={assessment}
            onRespond={onRespond}
            onUnrespond={onUnrespond}
          />
        )}
      />
    </Switch>
  ) : (
    <FullPageLoading />
  );
};

SurveyAssessmentRunComponent.propTypes = {
  onRespond: PropTypes.func,
  onClearSession: PropTypes.func,
};

SurveyAssessmentRunComponent.defaultProps = {
  onRespond: () => {},
  onClearSession: () => {},
};

export default SurveyAssessmentRunComponent;
