import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Icon } from 'js/components';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import styles from './EditorTool.module.scss';

export function EditorTool({
  className,
  title,
  children,
  collapsable = false,
  defaultOpen = true,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toolClassName = useMemo(
    () =>
      classNames(styles.tool, className, {
        [styles['is-open']]: !collapsable || isOpen,
      }),
    [className, isOpen, collapsable]
  );

  return (
    <Card className={toolClassName}>
      <div className={styles['tool-header']}>
        <CardTitle>{title}</CardTitle>
        {collapsable && (
          <div
            className={`${styles['tool-collapse-toggle']} ${
              isOpen ? styles['is-open'] : ''
            }`}
          >
            <button
              className="btn-inline-action btn-primary"
              aria-pressed={isOpen}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icon icon={faChevronRight} rotation={isOpen ? 90 : 0} />
            </button>
          </div>
        )}
      </div>
      {(!collapsable || isOpen) && <CardBody>{children}</CardBody>}
    </Card>
  );
}
export default EditorTool;
