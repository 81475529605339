import React, {
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
  useEffect,
  useRef,
} from 'react';
import { useCombobox } from 'downshift';
import classNames from 'classnames';
import editorStyles from '../Editor.module.scss';
import selectorStyles from './CategorySelector.module.scss';
import { isNumber } from 'lodash';
import { localeIndexOf } from 'js/utils/locale-indexof';

const collator = new Intl.Collator(undefined, { sensitivity: 'base' });
const indexOf = (str, substr) => localeIndexOf(str, substr, collator);

function extendCategories(
  categories,
  currentValue,
  filter = (item, cv) => indexOf(item.display_name, cv) > -1
) {
  const { matchedItems, hasExactMatch } = categories.reduce(
    (acc, item) => {
      if (filter(item, currentValue)) {
        acc.matchedItems.push(item);
      }

      acc.hasExactMatch =
        acc.hasExactMatch ||
        collator.compare(item.display_name, currentValue) === 0;
      return acc;
    },
    { matchedItems: [], hasExactMatch: false }
  );

  const isEmpty = (currentValue?.trim() ?? '') === '';
  const isUncategorized = collator.compare(currentValue, 'uncategorized') === 0;

  return hasExactMatch || isEmpty || isUncategorized
    ? [...matchedItems, { display_name: 'Uncategorized' }]
    : [
        { display_name: currentValue.trim() },
        ...matchedItems,
        { display_name: 'Uncategorized' },
      ];
}

export function CategorySelector({
  category = null,
  categories = [],
  onChange = () => {},
}) {
  const [currentCategories, setCurrentCategories] = useState(categories);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [internalVal, setInternalVal] = useState(
    category?.display_name ?? 'Uncategorized'
  );

  const allCategories = useCallback(() => {
    return extendCategories(categories, internalVal, () => true);
  }, [categories, internalVal]);

  const [items, setItems] = useState(allCategories());

  useEffect(() => {
    setInternalVal(category?.display_name ?? 'Uncategorized');
  }, [category]);

  useEffect(() => {
    if (categories !== currentCategories) {
      setItems(
        extendCategories(
          categories,
          internalVal,
          isFiltering
            ? (item) => indexOf(item.display_name, internalVal) > -1
            : () => true
        )
      );
      setCurrentCategories(categories);
    }
  }, [categories, currentCategories, isFiltering, internalVal]);

  const catClass = useMemo(() => {
    const trimmedInternalVal = internalVal.trim();
    const isUncategorized =
      trimmedInternalVal === '' || internalVal === 'Uncategorized';

    let paletteIndex =
      !isUncategorized &&
      collator.compare(internalVal, category?.display_name) === 0 &&
      isNumber(category.colorIndex)
        ? category.colorIndex + 1
        : undefined;

    const claimedColorIndices = [];
    if (!isUncategorized && paletteIndex === undefined) {
      // find a matching category
      categories.forEach(({ colorIndex, display_name }) => {
        if (!isNumber(colorIndex)) {
          return;
        }

        claimedColorIndices[colorIndex] = true;

        if (collator.compare(display_name?.trim(), trimmedInternalVal) === 0) {
          paletteIndex =
            paletteIndex === undefined ? colorIndex + 1 : paletteIndex;
        }
      });
    }

    // find the next available color index
    if (!isUncategorized && paletteIndex === undefined) {
      paletteIndex = categories.length + 1;
      for (let i = 0; i < paletteIndex; i++) {
        if (claimedColorIndices[i] === undefined) {
          paletteIndex = i + 1;
          break;
        }
      }
    }

    return classNames(editorStyles.badge, {
      [editorStyles.uncategorizedBadge]: isUncategorized,
      [`palette-cat-${paletteIndex}`]: !isUncategorized,
    });
  }, [category, categories, internalVal]);

  const handleCommitValue = useCallback(
    (v) => {
      let newValue = (v ?? internalVal ?? '').trim() || 'Uncategorized';

      setInternalVal(newValue);

      newValue =
        collator.compare(newValue, 'uncategorized') === 0 ? null : newValue;

      setIsEditing(false);
      setIsFiltering(false);
      setItems(allCategories());
      onChange(newValue);
    },
    [internalVal, onChange, setIsEditing, allCategories]
  );

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  const cursorRef = useRef(null);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    onInputValueChange({ inputValue, type, selectedItem, ...others }) {
      if (type === useCombobox.stateChangeTypes.ItemClick) {
        inputValue = selectedItem.display_name;
      }

      const extendedItems = extendCategories(
        categories,
        inputValue,
        (item) => indexOf(item.display_name, inputValue) > -1
      );

      setItems(extendedItems);
      setInternalVal(inputValue);
      setIsFiltering(true);
    },
    items,
    itemToString(item) {
      return item.length ? item : '';
    },
    inputValue: internalVal,
    onStateChange(changes) {
      const { type } = changes;

      if (type === useCombobox.stateChangeTypes.InputKeyDownEnter) {
        if (changes.selectedItem !== undefined) {
          handleCommitValue(changes.selectedItem.display_name);
        } else {
          handleCommitValue();
        }
        setIsFiltering(false);
      } else if (type === useCombobox.stateChangeTypes.InputBlur) {
        handleCommitValue();
        setIsFiltering(false);
      } else if (type === useCombobox.stateChangeTypes.ItemClick) {
        setInternalVal(changes.selectedItem.display_name);
        handleCommitValue(changes.selectedItem.display_name);
        setIsFiltering(false);
      } else if (type === useCombobox.stateChangeTypes.InputKeyDownEscape) {
        const resetVal = category?.display_name ?? 'Uncategorized';
        setInternalVal(resetVal);
        cursorRef.current?.blur();
        setIsFiltering(false);
        setItems(extendCategories(categories, resetVal, () => true));
      }
    },
  });

  const [cursor, setCursor] = useState(null);

  useLayoutEffect(() => {
    const input = cursorRef.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [cursorRef, cursor, internalVal]);

  const handleChange = useCallback(
    (e) => {
      setCursor(e.target.selectionStart);
    },
    [setCursor]
  );

  const inputProps = getInputProps({ onChange: handleChange, ref: cursorRef });

  useLayoutEffect(() => {
    const current = cursorRef.current;
    if (isEditing && current) {
      current.focus();
      current.setSelectionRange(0, current?.value?.length ?? 0);
    }
  }, [isEditing, cursorRef]);

  return (
    <div className={`combobox ${selectorStyles['category-selector']}`}>
      <div style={{ display: isEditing ? 'none' : 'block' }}>
        <button className={`btn ${catClass}`} onClick={handleEdit}>
          {internalVal}
        </button>
      </div>
      <div style={{ display: !isEditing ? 'none' : 'block' }}>
        <div className={classNames(catClass, selectorStyles.label)}>
          <div className={selectorStyles.sizing}>
            {inputProps.value || <i>&nbsp;</i>}
          </div>
          <input type="text" {...inputProps} />
        </div>
        <ul
          {...getMenuProps()}
          className="combobox-items"
          style={!isOpen ? { display: 'none' } : {}}
        >
          {isOpen &&
            items.map((item, index) => {
              const highlightBegins = localeIndexOf(
                item?.display_name,
                internalVal
              );
              const higlightEnds = highlightBegins + internalVal.length;
              const { pre, highlight, post } =
                highlightBegins === -1
                  ? {
                      pre: item?.display_name,
                      post: '',
                      highlight: '',
                    }
                  : {
                      pre: item?.display_name.slice(0, highlightBegins),
                      highlight: item?.display_name.slice(
                        highlightBegins,
                        higlightEnds
                      ),
                      post: item?.display_name.slice(higlightEnds),
                    };

              return (
                <li
                  className={classNames({
                    highlighted: highlightedIndex === index,
                    selected: selectedItem === item,
                    'combobox-item': true,
                  })}
                  key={`${item.value}${index}`}
                  {...getItemProps({ item, index })}
                >
                  <span className={selectorStyles['pre-match']}>{pre}</span>
                  <span className={selectorStyles['match']}>{highlight}</span>
                  <span className={selectorStyles['post-match']}>{post}</span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}

export default CategorySelector;
