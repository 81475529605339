import {
  SchemaSet,
  SchemaPickedSet,
  SchemaSingletonEntity,
  SchemaExclusiveArray,
  SchemaEntity,
  SchemaItem,
} from './entities';

/*
  new Schema.Entity('assessmentTemplate', {
    questionSets: new ExclusiveArray('questionSets', new Entity('questionSet', {
      questions: new ExclusiveArray('questions', new Entity('question', {}))
    },
    answerSets: new SchemaSet(new Schema.Entity)
    ))
  })
*/

// ArrayObserver - rebuilds itself when a child is updated
//       - needs a way to identify children
// Entity observer - rebuilds itself when any of it's properties are updated

// function entitySetReducer(state, { id, path, changes }) {}

// function entityReducer(state, getEntities, { changes, target }) {
//   let newState = { ...state };

//   // event.changes.added

//   // event.changes.deleted

//   changes.keys.forEach(({ action, oldValue }, key) => {
//     switch (action) {
//       case 'delete':
//         delete newState[key];
//         break;

//       case 'update':
//       case 'add':
//         state[key] = target.get(key);

//         break;
//     }
//   });
// }

// function setupObservers(ydoc, schema, path = []) {
//   const routes = [
//     ['assessmentTemplate'],
//     ['meta'],
//     ['questionSets', (x) => x.guid],
//     ['questions', (x) => x.guid],
//     ['answerSets', (x) => x.id],
//     ['choices', (x) => x.id],
//   ];

//   switch (true) {
//     case schema instanceof SchemaEntity:
//       ydoc.get(schema.key).observeDeep(deepObserver(schema, path));
//       schema.definition.entries().forEach();
//       break;

//     default:
//       throw new Error('schema must start with an entity');
//       break;
//   }
// }

// function deepObserver(definition, path) {
//   return (events) => {
//     events.forEach((e) => {
//       const { path, changes } = e;

//       // if(definition)
//     });
//   };
// }

function buildGraph(jsonSchema) {
  const root = getNode(jsonSchema);

  return root;
}

function getNode(schemaItem, path = [], inStack = undefined) {
  if (inStack?.has(schemaItem)) {
    throw new Error('Cycle detected in dependency graph');
  }

  // Using a new map for each layer is more expensive than other approaches but
  // works fine for small dependency graphs like we expect to have here
  const visited = new Map(inStack);
  visited.set(schemaItem, null);

  let children = [];

  switch (true) {
    case schemaItem instanceof SchemaSingletonEntity:
    case schemaItem instanceof SchemaEntity:
      children =
        Object.entries(schemaItem.definition).map(([key, child]) =>
          getNode(child, [...path, key], visited)
        ) ?? [];
      break;

    case schemaItem instanceof SchemaPickedSet:
    case schemaItem instanceof SchemaSet:
    case schemaItem instanceof SchemaExclusiveArray:
      // HACK: THIS IS NOT THE RIGHT PATH
      children = [getNode(schemaItem.entity, [...path, null], visited)];
      break;

    default:
      children = [];
      break;
  }

  return { entity: schemaItem, children, path };
}

function DFS(node, stack = [], visited = new Map()) {
  visited.set(node.entity, true);
  node.children.forEach((childNode) => {
    if (!visited.has(childNode.entity)) {
      DFS(childNode, stack, visited);
    }
  });
  stack.push(node);

  return stack;
}

export class Schema {
  constructor(jsonSchema, ydoc) {
    this.rootEntity = jsonSchema;
    this.graph = buildGraph(jsonSchema);
    this.updateSequence = DFS(this.graph);
  }

  findItemByKey(key) {
    return this.updateSequence.find((x) => x.entity.key === key)?.entity;
  }
}

export {
  SchemaEntity,
  SchemaExclusiveArray,
  SchemaItem,
  SchemaSet,
  SchemaPickedSet,
  SchemaSingletonEntity,
};
