import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHasValue } from '../useHasValue';

import { faTrashAlt, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import {
  faGripVertical,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';

import { Button, UncontrolledTooltip } from 'reactstrap';
import { Icon } from 'js/components';
import Question from '../Questions/Question';
import DropIndicator from '../Questions/DropIndicator';
import EditorInput from '../EditorInput/EditorInput';
import { CategorySelector } from './CategorySelector';
import ErrorMessage from '../EditorErrors/ErrorMessage';
import { useCollaboratorForField } from 'js/components/Assessment/Editor/collaborator-hooks';

// Drag and drop
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';

import styles from '../Editor.module.scss';

export function QuestionSet({
  questionSet: qs,
  categories,
  onFieldChange,
  onDelete,
  onQuestionFieldChange,
  onFieldFocus,
  onDeleteQuestion,
  onAddQuestion,
  onAssignToNewCategory,
  onAssignToCategory,
  onCopyQuestion,
  onAnswerSetChoiceAdd,
  onAnswerSetChoiceUpdate,
  onAnswerSetChoiceDelete,
  onAnswerSetChange,
  onAnswerSetFieldChange,
  index,
}) {
  const ref = useRef(null);
  const dragHandleRef = useRef(null);
  const previewRef = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [dragSource, setDragSource] = useState();
  const [expanded, setExpanded] = useState(true);
  const [autoExpandTimeout, setAutoExpandTimeout] = useState(null);
  const [closestEdge, setClosestEdge] = useState(null);

  const questionKeyCounts = useMemo(() => {
    return qs.elements.reduce((acc, q) => {
      return { ...acc, [q.key]: (acc[q.key] || 0) + 1 };
    }, {});
  }, [qs.elements]);

  const handleFieldChange = useCallback(
    (e) => {
      onFieldChange(qs.guid, e.target.name, e.target.value);
    },
    [qs.guid, onFieldChange]
  );

  const handleDelete = useCallback(() => {
    onDelete(qs);
  }, [qs, onDelete]);

  useEffect(() => {
    const el = ref.current;
    if (!el) throw new Error('No element found');

    return dropTargetForElements({
      element: el,
      canDrop: ({ source }) => {
        if (source.data.question) {
          return qs.elements.length === 0 || !expanded;
        } else if (source.data.questionSet) {
          return source.data.questionSet.guid !== qs.guid;
        }
      },
      getData: (args) => {
        return attachClosestEdge(
          { questionSet: qs, index },
          {
            input: args.input,
            element: args.element,
            allowedEdges: ['top', 'bottom'],
          }
        );
      },
      onDragEnter: ({ source }) => {
        setIsDraggedOver(true);
        setDragSource(source.data);
        if (!autoExpandTimeout && source.data.question) {
          const t = setTimeout(() => {
            setExpanded(true);
            setAutoExpandTimeout(null);
          }, 1000);
          setAutoExpandTimeout(t);
        }
      },
      onDragLeave: () => {
        setClosestEdge(null);
        setIsDraggedOver(false);
        setDragSource(null);
        if (autoExpandTimeout) {
          clearTimeout(autoExpandTimeout);
          setAutoExpandTimeout(null);
        }
      },
      onDrop: ({ source }) => {
        setIsDraggedOver(false);
        setDragSource(null);
        if (autoExpandTimeout) {
          clearTimeout(autoExpandTimeout);
          setAutoExpandTimeout(null);
        }
        if (!expanded && source.data.question) {
          setExpanded(true);
        }
      },
      onDrag({ self, source }) {
        const isSource = source.element === el;
        if (isSource) {
          setClosestEdge(null);
          return;
        }

        const closestEdge = extractClosestEdge(self.data);

        const sourceIndex = source.data.index;

        const isItemBeforeSource = index === sourceIndex - 1;
        const isItemAfterSource = index === sourceIndex + 1;

        const isDropIndicatorHidden =
          (isItemBeforeSource && closestEdge === 'bottom') ||
          (isItemAfterSource && closestEdge === 'top');

        if (isDropIndicatorHidden) {
          setClosestEdge(null);
          return;
        }

        setClosestEdge(closestEdge);
      },
    });
  }, [index, qs, expanded, autoExpandTimeout]);

  const category = useMemo(() => {
    return (categories ?? {})[qs.category];
  }, [categories, qs.category]);

  const categoryList = useMemo(() => {
    return Object.entries(categories ?? {}).map(([key, c]) => ({ ...c, key }));
  }, [categories]);

  const handleCategoryChanged = useCallback(
    (val) => {
      const collator = new Intl.Collator(undefined, { sensitivity: 'base' });

      const [key] = Object.entries(categories ?? {}).find(
        ([_key, c]) => collator.compare(c.display_name, val) === 0
      ) ?? [null, null];

      if (key) {
        if (key !== qs.category) {
          onAssignToCategory(qs.guid, key);
        }
      } else if ((val === '' || val === null) && qs.category) {
        console.log('category cleared:', val);
        onAssignToCategory(qs.guid, null);
      } else if (val !== '' && val !== null) {
        console.log('category changed (val not null):', val);
        onAssignToNewCategory(qs.guid, val);
      }
      onFieldFocus('questionSet', qs.guid, 'category', false);
    },
    [
      qs.category,
      categories,
      onAssignToNewCategory,
      onAssignToCategory,
      qs.guid,
      onFieldFocus,
    ]
  );

  const handleFieldFocus = useCallback(
    (e) => {
      onFieldFocus('questionSet', qs.guid, e.target.name, true);
    },
    [qs.guid, onFieldFocus]
  );

  const handleFieldBlur = useCallback(
    (e) => {
      onFieldFocus('questionSet', qs.guid, e.target.name, false);
    },
    [qs.guid, onFieldFocus]
  );

  const collaboratorForKey = useCollaboratorForField(
    'questionSet',
    qs.guid,
    'key'
  );
  const collaboratorForName = useCollaboratorForField(
    'questionSet',
    qs.guid,
    'name'
  );
  const collaboratorForDescription = useCollaboratorForField(
    'questionSet',
    qs.guid,
    'description'
  );

  useEffect(() => {
    const el = ref.current;
    const dragHandle = dragHandleRef.current;

    if (!el) throw new Error('No element found');
    if (!dragHandle) throw new Error('No element found');

    return draggable({
      element: el,
      dragHandle,
      getInitialData: () => ({ questionSet: qs, index }),
      onGenerateDragPreview: ({ nativeSetDragImage }) => {
        nativeSetDragImage(previewRef.current, 0, 0);
      },
    });
  }, [qs, index]);

  const hasName = useHasValue(qs.name);
  const hasKey = useHasValue(qs.key);

  return (
    <div
      key={qs.guid}
      className={`${styles['question-set']} ${
        isDraggedOver ? styles.draggedOver : ''
      }`}
      ref={ref}
    >
      {isDraggedOver &&
        (dragSource?.questionSet || qs.elements.length > 0) &&
        closestEdge === 'top' && <DropIndicator top />}
      <div className={styles['question-set-header']}>
        <div className={styles['panel-header']}>
          <button className="drag-handle" ref={dragHandleRef}>
            <Icon icon={faGripVertical} className="icon" />
          </button>
          <div className={styles.key}>
            <EditorInput
              name="key"
              value={qs.key}
              onChange={handleFieldChange}
              onFocus={handleFieldFocus}
              onBlur={handleFieldBlur}
              collaborator={collaboratorForKey}
              placeholder="question-set-key"
            />
          </div>
          {!hasKey && (
            <div className={styles.keyError} id={`q-${qs.guid}-key`}>
              <Icon icon={faExclamationTriangle} className="icon" />
              <UncontrolledTooltip
                color="danger"
                placement="top"
                target={`#q-${qs.guid}-key`}
                className={styles.tooltip}
                trigger="click hover"
                autohide={false}
              >
                <ErrorMessage>A unique key is required</ErrorMessage>
              </UncontrolledTooltip>
            </div>
          )}
          <div className={styles.categorySelector}>
            <CategorySelector
              category={category}
              categories={categoryList}
              onChange={handleCategoryChanged}
            />
          </div>
        </div>
      </div>
      <div className={styles['question-set-details']}>
        <div ref={previewRef}>
          <h4 className={styles['question-set-name']}>
            <EditorInput
              name="name"
              value={qs.name}
              placeholder="Give it a name"
              onChange={handleFieldChange}
              onFocus={handleFieldFocus}
              onBlur={handleFieldBlur}
              collaborator={collaboratorForName}
              error={
                hasName ? null : <ErrorMessage>Name is required</ErrorMessage>
              }
            />
          </h4>
          <div>
            <EditorInput
              multiLine
              name="description"
              value={qs.description || ''}
              placeholder="Add a description"
              onChange={handleFieldChange}
              onFocus={handleFieldFocus}
              onBlur={handleFieldBlur}
              collaborator={collaboratorForDescription}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div>
            <button
              className={`${styles.expander} ${
                expanded ? '' : styles.collapsed
              }`}
              onClick={() => setExpanded(!expanded)}
            >
              <Icon icon={faChevronDown} className="" />
            </button>
            {!expanded && (
              <span className={styles.questionCount}>
                {`${qs.elements.length} ${
                  qs.elements.length === 1 ? 'element' : 'elements'
                }`}
              </span>
            )}
          </div>
          <div className={styles.actions}>
            <Button
              className="btn-action-alt"
              color="primary"
              onClick={handleDelete}
            >
              <Icon icon={faTrashAlt} className="icon btn-icon-left" /> Delete
            </Button>
          </div>
        </div>
      </div>
      {expanded && (
        <React.Fragment>
          <div className={`question-set-droppable-area ${styles.questions}`}>
            {qs.elements.length === 0 && (
              <div style={{ position: 'relative', height: 40 }}>
                {isDraggedOver && dragSource?.question && <DropIndicator />}
              </div>
            )}
            {qs.elements?.map((q, i) => (
              <Question
                question={q}
                index={i}
                key={q.guid}
                onFieldChange={onQuestionFieldChange}
                onDelete={onDeleteQuestion}
                onCopy={onCopyQuestion}
                keyError={questionKeyCounts[q.key] > 1 ? 'Duplicate key' : null}
                onFieldFocus={onFieldFocus}
                onAnswerSetChoiceAdd={onAnswerSetChoiceAdd}
                onAnswerSetChoiceDelete={onAnswerSetChoiceDelete}
                onAnswerSetChoiceUpdate={onAnswerSetChoiceUpdate}
                onAnswerSetChange={onAnswerSetChange}
                onAnswerSetFieldChange={onAnswerSetFieldChange}
              />
            ))}
          </div>
          <div className={styles.actionList}>
            <Button
              outline
              color="primary"
              onClick={() => onAddQuestion(qs.guid)}
            >
              <Icon icon="plus" className="btn-icon-left" />
              Add Question
            </Button>
          </div>
        </React.Fragment>
      )}
      {isDraggedOver &&
        (dragSource?.questionSet || qs.elements.length > 0) &&
        closestEdge === 'bottom' && <DropIndicator bottom />}
    </div>
  );
}
