import { scaleLinear } from '@visx/scale';

export const generateDomain = (suggestedDomain, range) => {
  if (suggestedDomain.length === range.length) {
    return suggestedDomain;
  }

  // TODO: if they aren't the same length, interpolate the difference
  // HACK: for now, the likely difference is a domain of 2 and range of 3, so we'll only handle that interpolation
  const [min, max] = suggestedDomain;
  const incrementCount = range.length - 1;
  const increment = (max - min) / incrementCount;
  return new Array(range.length).fill(0).map((_, i) => min + i * increment);
};

export const standardColor = () => '#28b3d1';

export const scaleColorTraffic = scaleLinear({
  domain: [0, 0.5, 1],
  range: ['#ed2228', '#f0dd2b', '#4c9745'],
});
