import React, { useMemo } from 'react';
import AssessmentTypeGlyph from '../../../AssessmentTypeGlyph';

export function AssessmentType({ type }) {
  const name = useMemo(() => {
    switch (type) {
      case 'live-assessment':
      case 'live':
        return 'Live';
      case 'survey':
        return 'Survey';
      default:
        return 'Unknown';
    }
  }, [type]);

  return type == null ? null : (
    <div style={{ whiteSpace: 'nowrap' }}>
      <AssessmentTypeGlyph type={type} /> {name}
    </div>
  );
}

export default AssessmentType;
