import React from 'react';

import DraggableChoice from '../DraggableChoice';

import useEditorInput from '../../../useEditorInput';

import styles from '../AnswerSet.module.scss';

export function LikertChoiceCustom({
  choice,
  color,
  onDelete = () => {},
  onUpdate = () => {},
  index,
  answerSetId,
}) {
  const handleTextChange = (newText) => {
    onUpdate(choice, { text: newText });
  };

  const handleScoreChange = (newScore) => {
    onUpdate(choice, { value: newScore });
  };

  const { reset: resetText, ...choiceTextInputProps } = useEditorInput({
    value: choice.text,
    onCommit: handleTextChange,
  });

  const { reset: resetScore, ...choiceScoreInputProps } = useEditorInput({
    value: choice.value,
    onCommit: handleScoreChange,
    convertToNumber: true,
    defaultNumber: 0,
  });

  return (
    <DraggableChoice
      choice={choice}
      index={index}
      answerSetId={answerSetId}
      onDelete={onDelete}
    >
      <td className={styles['score-cell']}>
        <span
          className={styles['score-bar']}
          style={{ backgroundColor: color(choice.value) }}
        ></span>
        <input
          type="text"
          className={styles['answer-score']}
          {...choiceScoreInputProps}
        />
      </td>
      <td>
        <input
          type="text"
          className={styles['answer-text']}
          {...choiceTextInputProps}
        />
      </td>
    </DraggableChoice>
  );
}

export default LikertChoiceCustom;
