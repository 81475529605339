import React from 'react';
import { FormGroup } from 'reactstrap';
import AnswerSetSaver from './AnswerSetSaver';

export function ScoredChoiceSettings({
  question,
  onAnswerSetChange,
  onAnswerSetFieldChange,
}) {
  const { answerSet } = question ?? {};

  return (
    <div>
      <FormGroup>
        <div>
          <label>Answer Set</label>
        </div>
        <AnswerSetSaver
          answerSet={answerSet}
          question={question}
          onAnswerSetChange={onAnswerSetChange}
          onAnswerSetFieldChange={onAnswerSetFieldChange}
        />
      </FormGroup>
    </div>
  );
}

export default ScoredChoiceSettings;
